import React, { useMemo, useState } from "react";
import { Button } from "@mui/material";
import dayjs from "dayjs";
import ModalBody from "@components/ModalBody";
import DayPicker from "@components/DatePicker";
import LabelInput from "@components/LabelInput";
import { userPermAxios } from "@/utils/customAxios";
import { create_alert_fn } from "@/utils/createAlert";

const AbsenceModal = ({
  open,
  onClose = () => {},
  refresh = () => {},
  user_id,
}) => {
  const [date, setDate] = useState(dayjs());
  const [remark, setRemark] = useState("");
  const [dubleClick, setDubleClick] = useState(true);

  const button_disabled = useMemo(() => {
    return false;
  }, []);

  const createAbsence = async () => {
    setDubleClick(false);

    let msgStatus = {
      status: "success",
      message: "",
    };
    try {
      const requestCreateAbsence = await userPermAxios.post(
        `/dayoff/create_admin_absence`,
        {
          user_id,
          remark: remark.length === 0 ? null : remark,
          absenceYear: date.format("YYYY"),
          absenceMonth: date.format("MM"),
          absenceDay: date.format("DD"),
        }
      );

      const { success, message } = requestCreateAbsence;

      if (success) {
        msgStatus.status = "success";
      } else {
        msgStatus.status = "warning";
      }

      msgStatus.message = message;
    } catch (err) {
      msgStatus.status = "warning";
      if (err.response && err?.response?.data?.message) {
        msgStatus.message = err?.response?.data?.message;
      }
    } finally {
      setDubleClick(true);

      create_alert_fn(msgStatus.status, msgStatus.message);

      if (msgStatus.status === "success") {
        refresh();
        onClose();
      }
    }
  };

  const confirmAbsence = () => {
    if (!dubleClick) {
      return;
    }

    return create_alert_fn(
      "info",
      "Are you sure you want to create absence?",
      createAbsence,
      true
    );
  };

  return (
    <ModalBody
      open={open}
      onClose={onClose}
      modalWidth={400}
      title="Add Absence"
      disableEscapeKeyDown={!dubleClick}
    >
      <div className="work-policy-modal-contents-box">
        <div className="work-policy-modal-detail-contents-box">
          <div
            className="work-policy-modal-time-picker-detail"
            style={{ width: "100%" }}
          >
            <div className="work-policy-modal-detail-contents-title">
              Absebce Date
            </div>
            <DayPicker width="100%" value={date} onChange={(e) => setDate(e)} />
          </div>

          <div
            className="work-policy-modal-time-picker-detail"
            style={{ width: "100%" }}
          >
            <div className="work-policy-modal-detail-contents-title">
              Remark
            </div>
            <LabelInput value={remark} setValue={setRemark} />
          </div>
        </div>
      </div>
      <div className="work-policy-modal-btn-box">
        <Button
          role={undefined}
          variant="outlined"
          tabIndex={-1}
          onClick={onClose}
          className="work-policy-modal-cancel-btn"
        >
          Cancel
        </Button>
        <Button
          className="work-policy-modal-update-btn"
          onClick={confirmAbsence}
          disabled={button_disabled}
        >
          Save
        </Button>
      </div>
    </ModalBody>
  );
};

export default AbsenceModal;
