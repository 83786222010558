import { getRecoil } from "recoil-nexus";
import { UserTargetProject } from "@atoms/userInfo";
import { userPermAxios } from "@utils/customAxios";

export const updateCellData = async (
  id,
  field,
  value,
  table_name,
  dataType,
  parent_id
) => {
  const userTargetProject = getRecoil(UserTargetProject);

  const lineitemBool = table_name.includes("lineitems");

  if (!userTargetProject) {
    return { success: false, message: "" };
  }

  if (!lineitemBool) {
    try {
      const requestUpdateCellData = await userPermAxios.post(
        `/${table_name}/${userTargetProject?.project_code}/update`,
        {
          id,
          field,
          value,
          dataType,
        }
      );

      return requestUpdateCellData;
    } catch (err) {
      let message = "";

      if (err.response && err?.response?.data?.message) {
        message = err?.response?.data?.message;
      }

      return { success: false, message };
    }
  }

  if (lineitemBool) {
    try {
      const requestUpdateCellData = await userPermAxios.post(
        `/${table_name}/${userTargetProject?.project_code}/update`,
        {
          id,
          field,
          value,
          dataType,
          parent_id,
        }
      );

      return requestUpdateCellData;
    } catch (err) {
      let message = "";

      if (err.response && err?.response?.data?.message) {
        message = err?.response?.data?.message;
      }

      return { success: false, message };
    }
  }

  return { success: false };
};

export const addCellData = async (
  field,
  value,
  table_name,
  group_name,
  parent_id
) => {
  const userTargetProject = getRecoil(UserTargetProject);

  const lineitemBool = table_name.includes("lineitems");

  if (!userTargetProject) {
    return { success: false };
  }

  if (!lineitemBool) {
    try {
      const requestAddCellData = await userPermAxios.post(
        `${table_name}/${userTargetProject?.project_code}/new-row`,
        { keyName: field, keyValue: value, group_name }
      );

      return requestAddCellData;
    } catch (err) {
      let message = "";

      if (err.response && err?.response?.data?.message) {
        message = err?.response?.data?.message;
      }

      return { success: false, message };
    }
  }

  if (lineitemBool) {
    try {
      const requestAddCellData = await userPermAxios.post(
        `${table_name}/${userTargetProject?.project_code}/new-row`,
        { keyName: field, keyValue: value, parent_id, group_name }
      );

      return requestAddCellData;
    } catch (err) {
      let message = "";

      if (err.response && err?.response?.data?.message) {
        message = err?.response?.data?.message;
      }

      return { success: false, message };
    }
  }

  return { success: false };
};

export const deleteCellData = async (id, field, value, table_name) => {
  console.log("del", id, field, value, table_name);
};

export const deleteRowData = async (table_name, parent_id, id) => {
  const userTargetProject = getRecoil(UserTargetProject);

  if (!userTargetProject) {
    return { success: false, message: "" };
  }

  try {
    const requestDeleteRow = await userPermAxios.delete(
      `${table_name}/${userTargetProject?.project_code}/delete-row/${parent_id}/${id}`
    );

    return requestDeleteRow;
  } catch (err) {
    let message = "";

    if (err.response && err?.response?.data?.message) {
      message = err?.response?.data?.message;
    }

    return { success: false, message };
  }
};

export const permDeleteRowData = async (
  table_name,
  line_table_name,
  parent_id,
  id
) => {
  try {
    const requestDeleteRow = await userPermAxios.delete(
      `${table_name}/${line_table_name}/delete-perm-row/${parent_id}/${id}`
    );

    return requestDeleteRow;
  } catch (err) {
    let message = "";

    if (err.response && err?.response?.data?.message) {
      message = err?.response?.data?.message;
    }

    return { success: false, message };
  }
};

export const deleteMultiRowData = async (table_name, ids) => {
  const userTargetProject = getRecoil(UserTargetProject);

  if (!userTargetProject) {
    return { success: false };
  }

  try {
    const requestDeleteRow = await userPermAxios.post(
      `${table_name}/${userTargetProject?.project_code}/multi-delete-row`,
      {
        ids,
      }
    );

    return requestDeleteRow;
  } catch (err) {
    return { success: false };
  }
};

export const copyMultiRowData = async (table_name, ids) => {
  const userTargetProject = getRecoil(UserTargetProject);

  if (!userTargetProject) {
    return { success: false };
  }

  try {
    const requestCopyRow = await userPermAxios.post(
      `${table_name}/${userTargetProject?.project_code}/multi-copy-row`,
      {
        ids,
      }
    );

    return requestCopyRow;
  } catch (err) {
    return { success: false };
  }
};

export const advanceMultiRowData = async (table_name, ids) => {
  const userTargetProject = getRecoil(UserTargetProject);

  if (!userTargetProject) {
    return { success: false };
  }

  try {
    const requestCopyRow = await userPermAxios.post(
      `${table_name}/${userTargetProject?.project_code}/multi-advance-row`,
      {
        ids,
      }
    );

    return requestCopyRow;
  } catch (err) {
    return { success: false };
  }
};

export const updatePickerCellData = async (
  table_name,
  id,
  fk_key,
  picker_update_column_list,
  value,
  parent_id
) => {
  const userTargetProject = getRecoil(UserTargetProject);

  if (!userTargetProject) {
    return { success: false, message: "" };
  }

  try {
    const requestUpdateCellData = await userPermAxios.post(
      `/${table_name}/${userTargetProject?.project_code}/picker_update`,
      {
        id,
        fk_key,
        picker_update_column_list,
        value,
        parent_id,
      }
    );

    return requestUpdateCellData;
  } catch (err) {
    console.log({ err });
    let message = "";

    if (err.response && err?.response?.data?.message) {
      message = err?.response?.data?.message;
    }

    return { success: false, message };
  }
};

export const exportMatExcelFile = async (table_name, ids = [], invValue) => {
  const userTargetProject = getRecoil(UserTargetProject);
  if (!userTargetProject) {
    return { success: false };
  }

  try {
    const requestExportExcel = await userPermAxios.post(
      `${table_name}/${userTargetProject?.project_code}/create_material_excel`,
      {
        ids,
        invValue,
      }
    );

    return requestExportExcel;
  } catch (err) {
    return { success: false };
  }
};
