import React, { useMemo } from "react";
import { useParams, Navigate } from "react-router-dom";
import Layout from "@/layout/Layout";
import { useQuery } from "react-query";
import { useRecoilValue } from "recoil";
import { UserSetting } from "@/atoms/userInfo";
import {
  DataConsolidationModalStatus,
  ReleaseDataConsolidationModalStatus,
  RequestDataConsolidationModalStatus,
  QtyControlDataConsolidationModalStatus,
  ContactPointStatus,
  JobNumberStatus,
  PackingListDataConsolidationModalStatus,
} from "@atoms/modalStatus";
import MaterialMenu from "./menu/Material/MaterialMenu";
import MaterialInven from "./menu/Material/MaterialInven";
import MaterialMaster from "./menu/Material/MaterialMaster";
import MaterialJobNum from "./menu/Material/MaterialJobNum";
import CostCodeSettingPage from "../admin/menu/setting/CostCodeSettingPage";
import BudgetSheetPage from "./menu/Cost/BudgetSheetPage";
import InitialBudgetPage from "./menu/Cost/InitialBudgetPage";
import BudgetTransferPage from "./menu/Cost/BudgetTransferPage";
import BudgetChangePage from "./menu/Cost/BudgetChangePage";
import ChangeOrderPage from "./menu/Cost/ChangeOrderPage";
import ContractAwardPage from "./menu/Cost/ContractAwardPage";
import ExpensesPage from "./menu/Cost/ExpensesPage";
import OrgChartMenu from "./menu/HR/OrgChartMenu";
import HRWork from "./menu/HR/HRWork";
import MyInformation from "./menu/HR/MyInformation";
import ApprovalManagementPage from "./menu/Aprrove/ApprovalManagement";
import ManpowerRequestPage from "./menu/HR/ManpowerRequestPage";
import DashboardPage from "./menu/Dashboard/DashboardPage";
import RequestDataConsolidationModal from "@/components/requestDataConsolidationModal/RequestDataConsolidationModal";
import DataConsolidationModal from "@components/dataConsolidationModal/DataConsolidationModal";
import ReleaseDataConsolidationModal from "@/components/releaseDataConsolidationModal/ReleaseDataConsolidationModal";
import ContactPointModal from "@/components/ContactPointModal/ContactPointModal";
import JobNumberSelectModal from "@/components/jobNumberSelectModal/JobNumberSelectModal";
import QtyControlDataConsolidationModal from "@/components/qtyControlDataConsolidationModal/QtyControlDataConsolidationModal";
import PackingListDataConsolidationModal from "@/components/packingListDataConsolidationModal/PackingListDataConsolidationModal";
import { isLoginCheck } from "@/utils/isLoginCheck";

const HomePage = () => {
  const userSetting = useRecoilValue(UserSetting);

  const [
    dataConsolidationModalStatus,
    releaseDataConsolidationModalStatus,
    requestDataConsolidationModalStatus,
    qtyControlDataConsolidationModalStatus,
    contactPointStatus,
    jobNumberStatus,
    packingListDataConsolidationModalStatus,
  ] = [
    useRecoilValue(DataConsolidationModalStatus),
    useRecoilValue(ReleaseDataConsolidationModalStatus),
    useRecoilValue(RequestDataConsolidationModalStatus),
    useRecoilValue(QtyControlDataConsolidationModalStatus),
    useRecoilValue(ContactPointStatus),
    useRecoilValue(JobNumberStatus),
    useRecoilValue(PackingListDataConsolidationModalStatus),
  ];

  const { menu } = useParams();

  const query_login_check = useQuery(
    ["user_login_check", menu],
    async () => await isLoginCheck()
  );

  const { data, isLoading } = query_login_check;

  const renderComponents = useMemo(() => {
    if (isLoading) {
      return null;
    }

    if (menu === "material_inventory") {
      return <MaterialInven />;
    }

    if (menu === "material_master") {
      return <MaterialMaster />;
    }

    if (menu === "job_number") {
      return <MaterialJobNum />;
    }

    if (material_menu_list.includes(menu)) {
      return <MaterialMenu />;
    }

    if (menu === "organization_chart") {
      return <OrgChartMenu />;
    }

    if (menu === "hr_work") {
      return <HRWork />;
    }

    if (menu === "my_information") {
      return <MyInformation />;
    }

    if (menu === "approve") {
      return <ApprovalManagementPage />;
    }

    if (menu === "cost_code") {
      return <CostCodeSettingPage add_button_use={false} />;
    }

    if (menu === "budget_sheet") {
      return <BudgetSheetPage />;
    }

    if (menu === "initial_budget") {
      return <InitialBudgetPage />;
    }

    if (menu === "budget_transfer") {
      return <BudgetTransferPage />;
    }

    if (menu === "budget_change") {
      return <BudgetChangePage />;
    }

    if (menu === "change_order") {
      return <ChangeOrderPage />;
    }

    if (menu === "contract_award") {
      return <ContractAwardPage />;
    }

    if (menu === "expenses") {
      return <ExpensesPage />;
    }

    if (menu === "manpower_request") {
      return <ManpowerRequestPage />;
    }

    if (menu === "dashboard") {
      return <DashboardPage />;
    }

    return null;
  }, [menu, isLoading]);

  if (!isLoading) {
    const { isLoggedIn } = data;

    if (!isLoggedIn) {
      return <Navigate to="/login" />;
    }

    const adminUser = userSetting?.admin_user;
    const userMenu = userSetting?.menu ?? [];

    if (!adminUser) {
      if (!userMenu.includes(menu)) {
        return <Navigate to="/home" />;
      }
    }
  }

  return (
    <>
      <Layout top_menu="home" menu={menu}>
        {renderComponents}
      </Layout>
      {dataConsolidationModalStatus.open && <DataConsolidationModal />}
      {releaseDataConsolidationModalStatus.open && (
        <ReleaseDataConsolidationModal />
      )}
      {requestDataConsolidationModalStatus.open && (
        <RequestDataConsolidationModal />
      )}
      {qtyControlDataConsolidationModalStatus.open && (
        <QtyControlDataConsolidationModal />
      )}
      {contactPointStatus.open && <ContactPointModal />}
      {jobNumberStatus.open && <JobNumberSelectModal />}
      {packingListDataConsolidationModalStatus.open && (
        <PackingListDataConsolidationModal />
      )}
    </>
  );
};

export default HomePage;

const material_menu_list = [
  "sales",
  "job_number",
  "material_master",
  "customer",
  "vendor",
  "purchase_request",
  "request_for_quotation",
  "purchase_order",
  "packing_list",
  "material_receive",
  "material_release",
  "product_export",
  "inventory_quantity_control",
];
