import React from "react";
import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  useGridApiContext,
} from "@mui/x-data-grid-pro";
import { IconButton } from "@mui/material";
import { useResetRecoilState, useSetRecoilState } from "recoil";
import {
  SalaryAdditionalModalStatus,
  SalaryDeductionModalStatus,
} from "@/atoms/modalStatus";
import {
  CustomNormalTextTypeCell,
  CustomNumberTypeCell,
} from "@components/Cells";

export const createPayrollManagementColums = (year, month) => {
  return [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      width: 40,
      renderCell: (params) => {
        return <CustomDetailCheckRow params={params} />;
      },
    },
    {
      field: "user_id",
      headerName: "User Number",
      data_type: "string",
      width: 100,
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "username",
      headerName: "User Name",
      data_type: "string",
      flex: 1,
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "email",
      headerName: "Email",
      data_type: "string",
      flex: 1,
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "bank_name",
      headerName: "Bank Name",
      data_type: "string",
      flex: 1,
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "bank_account_number",
      headerName: "Account Number",
      data_type: "string",
      flex: 1,
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "payment_origin",
      headerName: "Base Salary",
      data_type: "string",
      flex: 1,
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNumberTypeCell params={params} />;
      },
    },
    {
      field: "payment_addition_sum",
      headerName: "Additional Allowance",
      data_type: "string",
      flex: 1,
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNumberTypeCell params={params} />;
      },
    },
    {
      field: "detail_payment_addition_sum",
      headerName: "",
      width: 40,
      renderCell: (params) => {
        return (
          <AdditionalCell
            params={params}
            type="additional"
            modal_type="users"
            year={year}
            month={month}
          />
        );
      },
    },
    {
      field: "payment_total_sum",
      headerName: "Total Payment",
      data_type: "string",
      flex: 1,
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNumberTypeCell params={params} />;
      },
    },
    {
      field: "payment_deduction_sum",
      headerName: "Total Deductions",
      data_type: "string",
      flex: 1,
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNumberTypeCell params={params} />;
      },
    },
    {
      field: "detail_payment_deduction_sum",
      headerName: "",
      width: 40,
      renderCell: (params) => {
        return (
          <AdditionalCell
            params={params}
            type="deduction"
            modal_type="users"
            year={year}
            month={month}
          />
        );
      },
    },
    {
      field: "payment_final",
      headerName: "Net Payment",
      data_type: "string",
      flex: 1,
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNumberTypeCell params={params} />;
      },
    },
  ];
};

export const createDailyWageManagementColums = (year, month) => {
  return [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      width: 40,
      renderCell: (params) => {
        return <CustomDetailCheckRow params={params} />;
      },
    },
    {
      field: "username",
      headerName: "User Name",
      data_type: "string",
      flex: 1,
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "bank_name",
      headerName: "Bank Name",
      data_type: "string",
      flex: 1,
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "bank_account_number",
      headerName: "Account Number",
      data_type: "string",
      flex: 1,
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "payroll_day",
      headerName: "Payment Date",
      data_type: "string",
      flex: 1,
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "payment_origin",
      headerName: "Daily Wage",
      data_type: "string",
      flex: 1,
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNumberTypeCell params={params} />;
      },
    },
    {
      field: "payment_addition_sum",
      headerName: "Additional Allowance",
      data_type: "string",
      flex: 1,
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNumberTypeCell params={params} />;
      },
    },
    {
      field: "detail_payment_addition_sum",
      headerName: "",
      width: 40,
      renderCell: (params) => {
        return (
          <AdditionalCell
            params={params}
            type="additional"
            modal_type="daily_wage"
            year={year}
            month={month}
          />
        );
      },
    },
    {
      field: "payment_total_sum",
      headerName: "Total Payment",
      data_type: "string",
      flex: 1,
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNumberTypeCell params={params} />;
      },
    },
    {
      field: "payment_deduction_sum",
      headerName: "Total Deductions",
      data_type: "string",
      flex: 1,
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNumberTypeCell params={params} />;
      },
    },
    {
      field: "detail_payment_deduction_sum",
      headerName: "",
      width: 40,
      renderCell: (params) => {
        return (
          <AdditionalCell
            params={params}
            type="deduction"
            modal_type="daily_wage"
            year={year}
            month={month}
          />
        );
      },
    },
    {
      field: "payment_final",
      headerName: "Net Payment",
      data_type: "string",
      flex: 1,
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNumberTypeCell params={params} />;
      },
    },
  ];
};

export const createDeductionColumns = () => {
  return [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      width: 40,
      renderCell: (params) => {
        return <CustomDetailCheckRow params={params} />;
      },
    },
    {
      field: "name",
      headerName: "Deduction Item",
      data_type: "string",
      flex: 1,
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "deduction_price",
      headerName: "Amount",
      data_type: "string",
      flex: 1,
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNumberTypeCell params={params} />;
      },
    },
  ];
};

export const createAdditionalColumns = () => {
  return [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      width: 40,
      renderCell: (params) => {
        return <CustomDetailCheckRow params={params} />;
      },
    },
    {
      field: "name",
      headerName: "Additional Item",
      data_type: "string",
      flex: 1,
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "addition_price",
      headerName: "Amount",
      data_type: "string",
      flex: 1,
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNumberTypeCell params={params} />;
      },
    },
  ];
};

const CustomDetailCheckRow = ({ params }) => {
  const { id, api } = params;

  if (id === Infinity) {
    return null;
  }

  return (
    <input
      type="checkbox"
      className="custom-check-box"
      checked={params.api.isRowSelected(params.id)}
      onChange={(event) => {
        if (event.target.checked) {
          api.selectRow(params.id, true);
        } else {
          api.selectRow(params.id, false);
        }
      }}
    />
  );
};

const AdditionalCell = ({
  params,
  query_key,
  type,
  year,
  month,
  modal_type,
}) => {
  const closeDeductionModal = useResetRecoilState(SalaryDeductionModalStatus);
  const closeAdditionalModal = useResetRecoilState(SalaryAdditionalModalStatus);
  const setSalaryDeductionModalStatus = useSetRecoilState(
    SalaryDeductionModalStatus
  );
  const setSalaryAdditionalModalStatus = useSetRecoilState(
    SalaryAdditionalModalStatus
  );

  const { id, row = {} } = params;
  const { payment_confirm = 1, user_id } = row;
  const apiRef = useGridApiContext();

  if (id === Infinity) {
    return null;
  }

  const openModal = async () => {
    const status = {
      open: true,
      query_key,
      user_id: modal_type === "users" ? user_id : id,
      year,
      month,
      confirm: payment_confirm,
      type: modal_type,
    };

    if (type === "deduction") {
      setSalaryDeductionModalStatus((prev) => {
        return { ...prev, onClose: closeDeductionModal, ...status };
      });
    }
    if (type === "additional") {
      setSalaryAdditionalModalStatus((prev) => {
        return { ...prev, onClose: closeAdditionalModal, ...status };
      });
    }
  };

  return (
    <IconButton
      className="line-delete-cell-btn"
      onClick={openModal}
      sx={{
        paddingRight: 0,
        paddingLeft: 0,
        cursor: "pointer",
      }}
    >
      <DetailIcon />
    </IconButton>
  );
};

const DetailIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M5.68238 7.17865C5.44304 7.17865 5.24902 7.37267 5.24902 7.612V8.26203C5.24902 8.50137 5.44304 8.69539 5.68238 8.69539H6.33241C6.57174 8.69539 6.76576 8.50137 6.76576 8.26203V7.612C6.76576 7.37267 6.57174 7.17865 6.33241 7.17865H5.68238Z"
        fill="#344054"
      />
      <path
        d="M8.79557 7.29384C8.50625 7.29384 8.27171 7.52419 8.27171 7.80834V8.0656C8.27171 8.34975 8.50625 8.5801 8.79557 8.5801H18.2252C18.5145 8.5801 18.749 8.34975 18.749 8.0656V7.80834C18.749 7.52419 18.5145 7.29384 18.2252 7.29384H8.79557Z"
        fill="#344054"
      />
      <path
        d="M8.79557 11.3093C8.50625 11.3093 8.27171 11.5397 8.27171 11.8238V12.0811C8.27171 12.3652 8.50625 12.5956 8.79557 12.5956H18.2252C18.5145 12.5956 18.749 12.3652 18.749 12.0811V11.8238C18.749 11.5397 18.5145 11.3093 18.2252 11.3093H8.79557Z"
        fill="#344054"
      />
      <path
        d="M8.27171 15.8868C8.27171 15.6026 8.50625 15.3723 8.79557 15.3723H18.2252C18.5145 15.3723 18.749 15.6026 18.749 15.8868V16.144C18.749 16.4282 18.5145 16.6585 18.2252 16.6585H8.79557C8.50625 16.6585 8.27171 16.4282 8.27171 16.144V15.8868Z"
        fill="#344054"
      />
      <path
        d="M5.24902 11.675C5.24902 11.4356 5.44304 11.2416 5.68238 11.2416H6.33241C6.57174 11.2416 6.76576 11.4356 6.76576 11.675V12.325C6.76576 12.5643 6.57174 12.7583 6.33241 12.7583H5.68238C5.44304 12.7583 5.24902 12.5643 5.24902 12.325V11.675Z"
        fill="#344054"
      />
      <path
        d="M5.68238 15.3046C5.44304 15.3046 5.24902 15.4986 5.24902 15.7379V16.3879C5.24902 16.6273 5.44304 16.8213 5.68238 16.8213H6.33241C6.57174 16.8213 6.76576 16.6273 6.76576 16.3879V15.7379C6.76576 15.4986 6.57174 15.3046 6.33241 15.3046H5.68238Z"
        fill="#344054"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.99902 2.7C2.99902 2.03726 3.53628 1.5 4.19902 1.5H19.799C20.4618 1.5 20.999 2.03726 20.999 2.7V21.3C20.999 21.9627 20.4618 22.5 19.799 22.5H3.69902C3.31242 22.5 2.99902 22.1866 2.99902 21.8V2.7ZM4.12402 2.925C4.12402 2.75931 4.25834 2.625 4.42402 2.625H19.574C19.7397 2.625 19.874 2.75931 19.874 2.925V21.075C19.874 21.2407 19.7397 21.375 19.574 21.375H4.24069C4.17626 21.375 4.12402 21.3228 4.12402 21.2583V2.925Z"
        fill="#344054"
      />
    </svg>
  );
};
