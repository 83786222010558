import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useRecoilValue } from "recoil";
import {
  HrWorkPolicyData,
  HrCalendarUsingModalStatus,
} from "@atoms/modalStatus";
import dayjs from "dayjs";
import DayPicker from "@components/DatePicker";
import SwicthBtn from "@components/SwicthBtn";
import ApprovalLineBtn from "./ApprovalLineBtn";
import HorizontalSolidLine from "../HorizontalSolidLine";
import { userPermAxios } from "@utils/customAxios";
import { create_alert_fn } from "@utils/createAlert";

const AnnualLeaveMenu = ({
  annualLeaveData,
  setAnnualLeaveData,
  approvalLineData,
  confirmApprovalLineData,
  approval_user_list,
  target_user_id = null,
}) => {
  const {
    day_off_weekday_list,
    annual_leave_approve_use,
    half_dayoff_use,
    annual_policy_setting,
  } = useRecoilValue(HrWorkPolicyData);

  const { startYear, startMonth, startDay, endYear, endMonth, endDay } =
    useRecoilValue(HrCalendarUsingModalStatus);

  const [startDate, setStartDate] = useState(
    dayjs(`${startYear}-${startMonth}-${startDay}`)
  );

  const [endDate, setEndDate] = useState(
    dayjs(`${endYear}-${endMonth}-${endDay}`)
  );

  const query_annual_leave_sub_data = useQuery(
    [
      "annual_leave_sub_data",
      startDate.format("YYYY-MM-DD"),
      endDate.format("YYYY-MM-DD"),
      target_user_id,
    ],
    () => {
      return query_fetch_data(startDate, endDate, target_user_id);
    },
    {
      onSuccess: (data) => {
        setAnnualLeaveData((prev) => {
          return {
            ...prev,
            remaing_count: data?.remaining_annal_leave_count ?? 0,
          };
        });
      },
    }
  );

  const { data: annualSubData = {}, isLoading } = query_annual_leave_sub_data;

  const { remaining_annal_leave_count = 0, hoily_day_list = [] } =
    annualSubData;

  useEffect(() => {
    const dateDiff = endDate.diff(startDate, "day");

    const annualDataArr = [];

    if (dateDiff >= 0) {
      for (let i = 0; i <= dateDiff; i++) {
        const targetDateDayjs = dayjs(startDate).add(i, "days");

        const targetDay = targetDateDayjs.day();
        const targetDate = targetDateDayjs.format("YYYY-MM-DD");

        if (
          !day_off_weekday_list.includes(targetDay) &&
          !hoily_day_list.includes(targetDate)
        ) {
          annualDataArr.push({
            id: i,
            date: targetDate,
            year: targetDateDayjs.format("YYYY"),
            month: targetDateDayjs.format("MM"),
            day: targetDateDayjs.format("DD"),
            morning_off: false,
            afternoon_off: false,
          });
        }
      }
    }

    setAnnualLeaveData((prev) => {
      return { ...prev, list: annualDataArr };
    });
    // eslint-disable-next-line
  }, [startDate, endDate, day_off_weekday_list, hoily_day_list]);

  const handleStartDate = (e) => {
    if (e.diff(endDate, "days") > 0) {
      return create_alert_fn(
        "warning",
        "The start date cannot be greater than the end date."
      );
    }

    return setStartDate(e);
  };

  const handleEndDate = (e) => {
    if (e.diff(startDate, "days") < 0) {
      return create_alert_fn(
        "warning",
        "The start date cannot be greater than the end date."
      );
    }
    return setEndDate(e);
  };

  const handleHalfUse = (data, type) => {
    const { id: eventId } = data;

    const updateAnnualLeaveData = annualLeaveData.list.map((c) => {
      const {
        id,
        morning_off: prev_morning_off,
        afternoon_off: prev_afternoon_off,
      } = c;

      let new_morning_off = prev_morning_off;
      let new_afternoon_off = prev_afternoon_off;

      if (id === eventId) {
        if (type === "morning") {
          new_morning_off = !prev_morning_off;
          if (new_morning_off) {
            new_afternoon_off = false; // 오전이 선택되면 오후는 해제
          }
        } else if (type === "afternoon") {
          new_afternoon_off = !prev_afternoon_off;
          if (new_afternoon_off) {
            new_morning_off = false; // 오후가 선택되면 오전은 해제
          }
        }

        // 둘 다 해제될 수 있는 경우를 처리
        if (!new_morning_off && !new_afternoon_off) {
          new_morning_off = false;
          new_afternoon_off = false;
        }

        return {
          ...c,
          morning_off: new_morning_off,
          afternoon_off: new_afternoon_off,
        };
      }

      return c;
    });

    setAnnualLeaveData((prev) => {
      return {
        ...prev,
        list: updateAnnualLeaveData,
      };
    });
  };

  if (isLoading) {
    return <div className="using-menu-body" />;
  }

  if (!annual_policy_setting) {
    return (
      <div className="using-menu-body">
        <div>
          The Annual Leave Policy is not set, please contact your administrator.
        </div>
      </div>
    );
  }

  return (
    <div className="using-menu-body">
      <div className="using-menu-flex-box">
        <div className="using-menu-flex-1-box">
          <div className="using-menu-default-text">{`Remaining Annual Leave Count: ${remaining_annal_leave_count}`}</div>
        </div>
      </div>
      <div className="using-menu-date-range-box">
        <div className="using-menu-date-range">
          <div style={{ width: 90 }} className="using-menu-default-text">
            Start Date:
          </div>
          <DayPicker
            value={startDate}
            onChange={handleStartDate}
            width="calc(100% - 90px)"
          />
        </div>
        <div className="using-menu-date-range">
          <div style={{ width: 90 }} className="using-menu-default-text">
            End Date:
          </div>
          <DayPicker
            value={endDate}
            onChange={handleEndDate}
            width="calc(100% - 90px)"
          />
        </div>
      </div>

      <div className="using-menu-column-box">
        <div className="using-menu-flex-1-box">Edit Detailed Schedule</div>

        {(annualLeaveData?.list ?? []).map((c, i) => {
          const { id, date, morning_off, afternoon_off, year, month, day } = c;

          const week = dayjs(`${year}-${month}-${day}`).format("ddd");
          return (
            <div key={id} className="using-menu-work-list-card align-center">
              <div className="using-menu-default-text">{`${
                i + 1
              }. ${date} (${week})`}</div>
              {half_dayoff_use && (
                <div className="using-menu-work-list-range-box">
                  <div style={{ width: 180 }}>
                    <SwicthBtn
                      checked={morning_off}
                      label={`Morning ${morning_off ? "ON" : "OFF"}`}
                      onClick={() => handleHalfUse(c, "morning")}
                    />
                  </div>
                  <div style={{ width: 180 }}>
                    <SwicthBtn
                      checked={afternoon_off}
                      label={`Afternoon ${afternoon_off ? "ON" : "OFF"}`}
                      onClick={() => handleHalfUse(c, "afternoon")}
                    />
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
      {annual_leave_approve_use && (
        <>
          <HorizontalSolidLine />
          <ApprovalLineBtn
            approvalLineData={approvalLineData}
            confirmApprovalLineData={confirmApprovalLineData}
            approval_user_list={approval_user_list}
          />
        </>
      )}
    </div>
  );
};

export default AnnualLeaveMenu;

const query_fetch_data = async (startDate, endDate, target_user_id) => {
  let result = {
    remaining_annal_leave_count: 0,
    hoily_day_list: [],
  };

  try {
    const startYear = startDate.format("YYYY");
    const startMonth = startDate.format("MM");
    const startDay = startDate.format("DD");
    const endYear = endDate.format("YYYY");
    const endMonth = endDate.format("MM");
    const endDay = endDate.format("DD");

    const requestAnnualSubData = await userPermAxios.get(
      `/hr/get_annual_sub_data?startYear=${startYear}&startMonth=${startMonth}&startDay=${startDay}&endYear=${endYear}&endMonth=${endMonth}&endDay=${endDay}&target_user_id=${target_user_id}`
    );

    const { success, data } = requestAnnualSubData;

    if (success) {
      result = { ...data };
    }
  } catch (err) {
  } finally {
    return result;
  }
};
