import { styled } from "@mui/material/styles";
import { treeItemClasses } from "@mui/x-tree-view/TreeItem";
import { TreeItem2Content, TreeItem2Root } from "@mui/x-tree-view/TreeItem2";
import Typography from "@mui/material/Typography";

export const StyledTreeItemRoot = styled(TreeItem2Root)(({ theme }) => ({
  color:
    theme.palette.mode === "light"
      ? theme.palette.grey[800]
      : theme.palette.grey[400],
  position: "relative",
  [`& .${treeItemClasses.groupTransition}`]: {
    marginLeft: theme.spacing(3.5),
  },
}));

export const CustomTreeItemContent = styled(TreeItem2Content)(({ theme }) => ({
  flexDirection: "row-reverse",
  borderRadius: theme.spacing(0.7),
  // marginBottom: theme.spacing(0.5),
  // marginTop: theme.spacing(0.5),
  padding: "10px 16px",
  paddingRight: theme.spacing(1),
  fontWeight: 500,
  [`&.Mui-expanded `]: {
    "&:not(.Mui-focused, .Mui-selected, .Mui-selected.Mui-focused) .labelIcon":
      {
        color:
          theme.palette.mode === "light"
            ? theme.palette.primary.main
            : theme.palette.primary.dark,
      },
    "&::before": {
      content: '""',
      display: "block",
      position: "absolute",
      left: "16px",
      top: "44px",
      height: "calc(100% - 48px)",
      width: "1.5px",
      backgroundColor:
        theme.palette.mode === "light"
          ? theme.palette.grey[300]
          : theme.palette.grey[700],
    },
  },
  "&:hover": {
    backgroundColor: "#F4FEFF",
    color: "#0080DB",
  },
  [`&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused`]: {
    background: "#F4FEFF",
    color: "#0080DB",
    fontSize: "16px",
    "&.MuiTypography-body2": {
      fontSize: "16px",
    },
  },
}));

export const StyledTreeItemLabelText = styled(Typography)({
  fontFamily: "Inter",
  fontSize: "14px",
  lineHeight: "24px",
  fontStyle: "normal",
  fontWeight: 500,
});
