import React, { useState } from "react";
import { useQuery } from "react-query";
import _ from "lodash";
import { useRecoilState, useResetRecoilState } from "recoil";
import {
  HrWorkPolicyData,
  HrCalendarUsingModalStatus,
} from "@/atoms/modalStatus";
import dayjs from "dayjs";
import Btn from "@components/CustomIconBtn";
import PlusIcon from "@components/Icons/PlusIcon";
import HRCalendarUsingModal from "@components/hrBigCalendar/HRCalendarUsingModal";
import AnnualLeaveTable from "./AnnualLeaveTable";
import AdditionalModal from "./AdditionalModal";
import AbsenceModal from "./AbsenceModal";
import {
  annualLeaveAdditionalColumn,
  annualLeaveUsageColumn,
  workTypeUsageColumn,
  dayOffUsageColumn,
  absenceColumn,
} from "./Column";
import useRefreshReactQuery from "@/hooks/useRefreshReactQuery";
import { userPermAxios } from "@/utils/customAxios";

const AnnaulLeaveLine = ({ params, startMonth, endMonth, apiRef }) => {
  const [{ day_off_weekday_list }, setHrWorkPolicyData] =
    useRecoilState(HrWorkPolicyData);
  const [hrCalendarUsingModalStatus, setHrCalendarUsingModalStatus] =
    useRecoilState(HrCalendarUsingModalStatus);
  const [additionalModalToggle, setAdditionalModalToggle] = useState(false);
  const [absenceModalToggle, setAbsenceModalToggle] = useState(false);

  const [annualTabValue, setAnnualTabValue] = useState(0);

  const onCloseUsingModal = useResetRecoilState(HrCalendarUsingModalStatus);

  const { id, row = {} } = params;

  const { remain_annal_leave_count = 0 } = row;

  const query_annual_leave_line_log_data = useQuery(
    [
      "annual_leave_line_log_data",
      id,
      startMonth.format("YYYY-MM"),
      endMonth.format("YYYY-MM"),
    ],
    () => {
      return query_fetch_data(id, startMonth, endMonth);
    },
    {
      onSuccess: (result) => {
        const { additional_list, using_annual_leave_list } = result;

        const additional_list_sum = _.sumBy(
          additional_list,
          "additional_count"
        );
        const using_annual_leave_list_sum = _.sumBy(
          using_annual_leave_list,
          "using_count"
        );

        const remainAnnualLeave =
          additional_list_sum - using_annual_leave_list_sum;

        if (apiRef?.current) {
          apiRef.current.updateRows([
            {
              id,
              annual_leave_count: additional_list_sum,
              using_annual_leave_count: using_annual_leave_list_sum,
              remain_annal_leave_count: remainAnnualLeave,
            },
          ]);
        }
      },
    }
  );

  const query_hr_policy_data = useQuery(
    ["hr_policy_data", id],
    () => {
      return query_fetch_annal_policy_data(id);
    },
    {
      onSuccess: (data) => {
        setHrWorkPolicyData((prev) => {
          return {
            ...prev,
            ...data,
          };
        });
      },
    }
  );

  const query_hr_work_data = useQuery(
    ["hr_work_data", dayjs().format("YYYY-MM"), id],
    () => {
      return query_fetch_work_data(new Date(), id);
    },
    {
      initialData: {
        hoily_day_list: [],
        events_list: [],
      },
    }
  );

  const { data = {}, isLoading } = query_annual_leave_line_log_data;

  const { data: hrWorkData = {}, isLoading: hrWorkDataLoading } =
    query_hr_work_data;
  const { hoily_day_list = [], events_list = [] } = hrWorkData;

  const { isLoading: hrPolicyDataLoading } = query_hr_policy_data;

  const {
    additional_list = [],
    using_annual_leave_list = [],
    using_work_type_list = [],
    using_dayoff_list = [],
    absence_list = [],
    policy_table_id = null,
    half_dayoff_use = null,
  } = data;

  const [refresh] = useRefreshReactQuery([
    "annual_leave_line_log_data",
    id,
    startMonth.format("YYYY-MM"),
    endMonth.format("YYYY-MM"),
  ]);

  const handleToggle = (setValue) => {
    setValue((prev) => !prev);
  };

  const handleSelectSlot = (tab_value) => {
    const slotStartYear = dayjs().format("YYYY");
    const slotStartMonth = dayjs().format("MM");
    const slotStartDay = dayjs().format("DD");

    const slotEndYear = dayjs().format("YYYY");
    const slotEndMonth = dayjs().format("MM");
    const slotEndDay = dayjs().format("DD");

    const slotEventsDays = [
      {
        slotDate: dayjs().format("YYYY-MM-DD"),
        slotDay: dayjs().day(),
      },
    ];

    const workingDays = slotEventsDays.filter(
      (c) =>
        !day_off_weekday_list.includes(c.slotDay) &&
        !hoily_day_list.includes(c.slotDate)
    );

    setAnnualTabValue(tab_value);
    setHrCalendarUsingModalStatus((prev) => {
      return {
        ...prev,
        open: true,
        onClose: onCloseUsingModal,
        title: `${slotStartYear}-${slotStartMonth}-${slotStartDay} ~ ${slotEndYear}-${slotEndMonth}-${slotEndDay}`,
        startYear: slotStartYear,
        startMonth: slotStartMonth,
        startDay: slotStartDay,
        endYear: slotEndYear,
        endMonth: slotEndMonth,
        endDay: slotEndDay,
        slotDuration: slotEventsDays,
        workingDayDuration: workingDays,
      };
    });
  };

  if (isLoading || hrPolicyDataLoading || hrWorkDataLoading) {
    return null;
  }

  return (
    <div className="annual-leave-line-body">
      <div className="annual-leave-line-box">
        <div className="annual-leave-line-title-box">
          <div className="annual-leave-line-title">
            Granted Annual Leave Log
          </div>
          <div className="annual-leave-line-btn-box">
            <Btn
              icon={<PlusIcon />}
              onClick={() => handleToggle(setAdditionalModalToggle)}
            />
          </div>
        </div>
        <div className="annual-leave-line-table-box">
          <AnnualLeaveTable
            rows={additional_list}
            column={annualLeaveAdditionalColumn}
            rowHeight={28}
            lineUse={false}
          />
        </div>
      </div>
      <div className="annual-leave-line-box">
        <div className="annual-leave-line-title-box">
          <div className="annual-leave-line-title">Annual Leave Log</div>
          <div className="annual-leave-line-btn-box">
            <Btn icon={<PlusIcon />} onClick={() => handleSelectSlot(1)} />
          </div>
        </div>
        <div className="annual-leave-line-table-box">
          <AnnualLeaveTable
            rows={using_annual_leave_list}
            column={annualLeaveUsageColumn}
            rowHeight={28}
            lineUse={false}
          />
        </div>
      </div>
      <div className="annual-leave-line-box">
        <div className="annual-leave-line-title-box">
          <div className="annual-leave-line-title">Work Type Log</div>
          <div className="annual-leave-line-btn-box">
            <Btn icon={<PlusIcon />} onClick={() => handleSelectSlot(0)} />
          </div>
        </div>
        <div className="annual-leave-line-table-box">
          <AnnualLeaveTable
            rows={using_work_type_list}
            column={workTypeUsageColumn}
            rowHeight={28}
            lineUse={false}
          />
        </div>
      </div>

      <div className="annual-leave-line-box">
        <div className="annual-leave-line-title-box">
          <div className="annual-leave-line-title">DayOff Log</div>
          <div className="annual-leave-line-btn-box">
            <Btn icon={<PlusIcon />} onClick={() => handleSelectSlot(2)} />
          </div>
        </div>
        <div className="annual-leave-line-table-box">
          <AnnualLeaveTable
            rows={using_dayoff_list}
            column={dayOffUsageColumn}
            rowHeight={28}
            lineUse={false}
          />
        </div>
      </div>
      <div className="annual-leave-line-box">
        <div className="annual-leave-line-title-box">
          <div className="annual-leave-line-title">Absence Log</div>
          <div className="annual-leave-line-btn-box">
            <Btn
              icon={<PlusIcon />}
              onClick={() => handleToggle(setAbsenceModalToggle)}
            />
          </div>
        </div>
        <div className="annual-leave-line-table-box">
          <AnnualLeaveTable
            rows={absence_list}
            column={absenceColumn}
            rowHeight={28}
            lineUse={false}
          />
        </div>
      </div>
      {additionalModalToggle && (
        <AdditionalModal
          open={additionalModalToggle}
          onClose={() => handleToggle(setAdditionalModalToggle)}
          refresh={refresh}
          user_id={id}
        />
      )}

      {absenceModalToggle && (
        <AbsenceModal
          open={absenceModalToggle}
          onClose={() => handleToggle(setAbsenceModalToggle)}
          refresh={refresh}
          user_id={id}
        />
      )}
      {hrCalendarUsingModalStatus.open && (
        <HRCalendarUsingModal
          target_user_id={id}
          initial_tab_value={annualTabValue}
          log_data_refresh_fn={refresh}
        />
      )}
    </div>
  );
};

export default AnnaulLeaveLine;

const query_fetch_data = async (id, startDate, endDate) => {
  let result = {
    additional_list: [],
    using_annual_leave_list: [],
    using_work_type_list: [],
    using_dayoff_list: [],
    absence_list: [],
    policy_table_id: null,
    half_dayoff_use: null,
  };

  try {
    const startYear = startDate.format("YYYY");
    const startMonth = startDate.format("MM");
    const endYear = endDate.format("YYYY");
    const endMonth = endDate.format("MM");

    const requestAnnualLeaveLineLog = await userPermAxios.get(
      `/dayoff/get_annual_leave_line_log_data?user_id=${id}&start_year=${startYear}&start_month=${startMonth}&end_year=${endYear}&end_month=${endMonth}`
    );

    const { success, data } = requestAnnualLeaveLineLog;

    if (success) {
      result = { ...data };
    }
  } catch (err) {
  } finally {
    return result;
  }
};

const query_fetch_annal_policy_data = async (id) => {
  let result = {
    work_policy_name: "Policy Name",
    working_time: "08:00:00",
    attendance_time: "09:00:00",
    break_time_start: "12:00:00",
    break_time_end: "13:00:00",
    day_off_weekday_list: [0, 6],
    annual_leave_approve_use: true,
    half_dayoff_use: true,
    work_type_policy_list: [],
    dayoff_policy_list: [],
    approval_user_list: [],
    annual_leave_policy_id: 0,
  };

  try {
    const requestUserPolicy = await userPermAxios.get(
      `/hr/hr_work?target_user_id=${id}`
    );

    const { success, data } = requestUserPolicy;

    if (success) {
      result = {
        ...data,
        day_off_weekday_list: JSON.parse(data.day_off_weekday_list ?? `[]`),
      };
    }
  } catch (err) {
  } finally {
    return result;
  }
};

const query_fetch_work_data = async (targetDate, id) => {
  let result = {
    hoily_day_list: [],
    events_list: [],
  };

  try {
    const targetDateYear = dayjs(targetDate).format("YYYY");
    const targetDateMonth = dayjs(targetDate).format("MM");
    const targetDateDay = dayjs(targetDate).format("DD");

    const requestUserPolicy = await userPermAxios.get(
      `/hr/get_calendar_data?year=${targetDateYear}&month=${targetDateMonth}&day=${targetDateDay}&target_user_id=${id}`
    );

    const { success, data } = requestUserPolicy;

    if (success) {
      result = {
        ...data,
        day_off_weekday_list: JSON.parse(data.day_off_weekday_list ?? `[]`),
      };
    }
  } catch (err) {
  } finally {
    return result;
  }
};
