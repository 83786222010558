import React, {
  useEffect,
  forwardRef,
  useImperativeHandle,
  useState,
  useMemo,
  useRef,
  useCallback,
} from "react";
import {
  DataGridPro,
  GridLogicOperator,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IconButton } from "@mui/material";
import LineItemTable from "./LineItemTable";
import GroupLineItemTable from "./GroupLineItemTable";
import useScrollCheck from "@/hooks/useScrollCheck";
import { userPermAxios } from "@utils/customAxios";
import { create_alert_fn } from "@utils/createAlert";
import {
  // tableStatusField,
  tableLockStatusField,
} from "@utils/tableInitialData";
// import useTableLazyLoading from "@hooks/useTableLazyLoading";
import { materialTableStyle, TableScrollbarStyle } from "@styles/muiThemes";

const MaterialTable = forwardRef(
  (
    {
      admin_user,
      user_perm,
      table_name,
      line_table_name,
      rows,
      columns,
      lineColumns,
      selectionModel,
      setSelectionModel,
      soketData,
      isLoading,
      lineDataUse,
      group_name,
      group_color,
      group_list,
      upperTablePinnedColumns = [],
      filter_items = [],
    },
    ref
  ) => {
    const gridRef = useRef(null);
    const [filterModel, setFilterModel] = useState({
      items: [],
      logicOperator: GridLogicOperator.Or,
    });
    const [groupNameEditMode, setGroupNameEditMode] = useState(false);
    const [groupNameValue, setGroupNameValue] = useState(group_name);
    const [tableToggle, setTableToggle] = useState(true);

    const [detailPanelIds, setDetailPanelIds] = useState([]);
    const tableNameInputRef = useRef(null);

    useEffect(() => {
      setFilterModel((prev) => {
        return {
          ...prev,
          items: filter_items,
          logicOperator: GridLogicOperator.Or,
        };
      });
    }, [filter_items]);

    const handleSelectionChange = (selection) => {
      setSelectionModel((prev) => ({ ...prev, [group_name]: selection }));
    };

    const apiRef = useGridApiRef();

    // const [initialRows, rowCount, debouncedHandleFetchRows] =
    //   useTableLazyLoading(rows, apiRef, columns);

    // useImperativeHandle을 사용하여 부모 컴포넌트에 apiRef를 노출
    useImperativeHandle(ref, () => {
      return {
        apiRef: apiRef.current,
      };
    });

    useEffect(() => {
      const bodyElement = window.document.querySelector("body");

      const bodyClickEvent = (e) => {
        if (tableNameInputRef) {
          if (
            !tableNameInputRef?.current?.contains(e.target) ||
            !bodyElement?.contains(e.target)
          ) {
            setGroupNameEditMode(false);
            setGroupNameValue(group_name);
          }
        }

        return;
      };

      bodyElement.addEventListener("click", bodyClickEvent);

      return () => {
        bodyElement.removeEventListener("click", bodyClickEvent);
      };
      // eslint-disable-next-line
    }, [tableNameInputRef]);

    const isScrollable = useScrollCheck(
      gridRef.current?.querySelector(
        ".MuiDataGrid-main" // DataGrid의 스크롤 영역
      ),
      apiRef?.current
    );

    const { status_field, lock_status_list } =
      tableLockStatusField(table_name) ?? {};

    const lineDataRef = useMemo(() => {
      if (table_name === "customer" || table_name === "vendor") {
        const row_grouping_model = ["sales_lead"];
        const column_visibility_model = {
          sales_lead: false,
        };

        if (table_name === "vendor") {
          row_grouping_model.unshift("bid_sortation");
          column_visibility_model.bid_sortation = false;
        }

        return {
          getDetailPanelContent: (props) => {
            const { id } = props;
            return (
              <GroupLineItemTable
                line_table_name={line_table_name}
                lineColumns={lineColumns}
                parent_id={id}
                row_grouping_model={row_grouping_model}
                column_visibility_model={column_visibility_model}
              />
            );
          },

          getDetailPanelHeight: ({ row }) => {
            // const { lineData } = row;

            // const height = (lineData ?? []).length * 50 + 58;

            // if (height < 408) {
            //   return "auto";
            // }

            return 408;
          },
          onDetailPanelExpandedRowIdsChange: setDetailPanelIds,
          detailPanelExpandedRowIds: detailPanelIds,
        };
      }

      return {
        getDetailPanelContent: (props) => {
          const { id, row } = props;

          let edit_mode = true;

          if (!status_field) {
            edit_mode = false;
          } else {
            if (user_perm === "read") {
              edit_mode = false;
            }

            if (admin_user) {
              edit_mode = true;
            }

            const row_status = row?.[status_field];

            if (lock_status_list.includes(row_status)) {
              edit_mode = false;
            }
          }

          return (
            <LineItemTable
              user_perm={user_perm}
              line_table_name={line_table_name}
              lineColumns={lineColumns}
              parent_id={id}
              parent_table_name={table_name}
              parent_api_ref={apiRef}
              soketData={soketData}
              sales_id={row?.sales_id}
              edit_mode={edit_mode}
            />
          );
        },
        getDetailPanelHeight: ({ row }) => {
          const { lineData } = row;

          // const height = (lineData ?? []).length * 50 + 58;

          // if (height < 408) {
          //   return "auto";
          // }

          return 408;
        },
        onDetailPanelExpandedRowIdsChange: setDetailPanelIds,
        detailPanelExpandedRowIds: detailPanelIds,
      };
      // eslint-disable-next-line
    }, [lineDataUse, soketData, status_field, lock_status_list, apiRef]);

    const addRowRef = useMemo(() => {
      if (table_name === "material_master") {
        return {};
      }
      if (!tableToggle) {
        return {};
      }

      if (admin_user) {
        return {
          pinnedRows: {
            bottom: [{ index: Infinity, id: Infinity, col1: "+ Lead Add" }],
          },
        };
      }

      if (user_perm === "read") {
        return {};
      }

      return {
        pinnedRows: {
          bottom: [{ index: Infinity, id: Infinity, col1: "+ Lead Add" }],
        },
      };
    }, [table_name, admin_user, user_perm, tableToggle]);

    const closeEditMode = async (e) => {
      if (e.key === "Enter" || e.key === "Tab") {
        const targetRowIds = apiRef?.current?.getAllRowIds() ?? [];

        if (
          targetRowIds.length > 0 &&
          groupNameValue.replace(" ", "").length > 0
        ) {
          if (group_list.includes(groupNameValue)) {
            e.preventDefault();

            return create_alert_fn(
              "warning",
              "A group that already exists.",
              () => {
                setGroupNameEditMode(false);
                setGroupNameValue(group_name);
              }
            );
          }

          const requestUpdateGroupName = await userPermAxios.post(
            `/${table_name}/update-row-group`,
            {
              new_group_name: groupNameValue,
              ids: targetRowIds,
            }
          );

          const { success } = requestUpdateGroupName;

          if (success) {
          }
        }

        setGroupNameEditMode(false);
      }
    };

    const tableGroupEditorOpen = () => {
      const getRows = apiRef.current.getAllRowIds();

      if (table_name === "material_master") {
        return;
      }

      if (user_perm === "read") {
        return;
      }

      if (table_name === "sales" && !admin_user) {
        return;
      }

      if (!getRows || getRows.length === 0) {
        return create_alert_fn(
          "warning",
          "The group name can only be changed after adding a row.",
          () => {}
        );
      }

      return setGroupNameEditMode(true);
    };

    const handleCellEditStart = (params, event) => {
      const { field, row, id } = params;
      if (event.type === "keydown") {
        event.defaultMuiPrevented = true;
      }

      if (
        (lock_status_list &&
          lock_status_list.includes(params?.row?.[status_field])) ||
        (table_name === "sales" &&
          field === "sales_stage" &&
          (row[field] === "Won" || row[field] === "Lost"))
      ) {
        event.defaultMuiPrevented = true;
        event.stopPropagation(); // 기본 동작 차단
        event.preventDefault(); // 브라우저 기본 동작도 차단
        return;
      }

      if (
        table_name === "sales" &&
        (field === "sales_stage" || field === "amount")
      ) {
        event.defaultMuiPrevented = false;

        return;
      }

      if (
        table_name === "customer" &&
        field === "customer_code" &&
        (row.status === "Active" || row.status === "In Active")
      ) {
        event.defaultMuiPrevented = false;

        return;
      }
    };

    // const handleCellEditStop = (params, event) => {
    //   if (table_name === "sales" || table_name === "vendor") {
    //     const { id, reason, field } = params;

    //     if (id !== Infinity) {
    //       if (reason === "enterKeyDown" || reason === "tabKeyDown") {
    //         const columnIndex = columns.length;
    //         const targetColumnIndex = columns.findIndex(
    //           (col) => col.field === field
    //         );
    //         if (targetColumnIndex > 0) {
    //           const nextColumnIndex = targetColumnIndex + 1;
    //           if (columnIndex !== nextColumnIndex) {
    //             apiRef.current.startCellEditMode({
    //               id,
    //               field: columns[nextColumnIndex].field,
    //             });
    //           }
    //         }
    //       }
    //     }
    //   }
    // };

    const handleCellKeyDown = (params, event) => {
      const { id, field, row, isEditable, cellMode } = params;

      if (event.key === "Enter" && field !== "lead" && cellMode === "view") {
        if (id !== Infinity && isEditable) {
          if (
            (lock_status_list &&
              lock_status_list.includes(params?.row?.[status_field])) ||
            (table_name === "sales" &&
              field === "sales_stage" &&
              (row[field] === "Won" || row[field] === "Lost"))
          ) {
            return;
          }

          if (
            table_name === "sales" &&
            (field === "sales_stage" || field === "amount")
          ) {
            apiRef.current.startCellEditMode({ id, field });

            event.defaultMuiPrevented = false;
            return;
          }

          apiRef.current.startCellEditMode({ id, field });

          event.defaultMuiPrevented = false;
          return;
        }

        // Edit mode 활성화

        // 기본 Enter 동작 방지
      }

      if (event.key === "Tab") {
        event.preventDefault();
      }
    };

    const tableMaxHeight = useMemo(() => {
      // if (table_name === "material_master") {
      //   return "calc(100vh - 104px - 48px - 8px - 40px - 32px)";
      // }

      if (!tableToggle) {
        if (isScrollable) {
          return 60;
        } else {
          return 40;
        }
      }

      return 500;
    }, [table_name, tableToggle, isScrollable]);

    const handleCellClick = useCallback(
      (params) => {
        const { id, row, field, colDef = {} } = params;
        const { editable } = colDef;

        const cellMode = apiRef.current.getCellMode(id, field);

        if (
          table_name === "customer" &&
          (field === "customer_code" || field === "customer") &&
          (row.status === "Active" || row.status === "In Active")
        ) {
          return;
        }

        if (
          table_name === "sales" &&
          field === "sales_stage" &&
          (row[field] === "Won" || row[field] === "Lost")
        ) {
          return;
        }

        if (
          !(
            table_name === "sales" &&
            (field === "sales_stage" || field === "amount")
          )
        ) {
          if (
            lock_status_list &&
            lock_status_list.includes(row?.[status_field])
          ) {
            return;
          }
        }

        if (cellMode === "view" && editable) {
          apiRef.current.startCellEditMode({
            id: params.id,
            field: params.field,
          });
        }
      },
      [apiRef, lock_status_list, status_field, table_name]
    );

    return (
      <div className="admin-table-body">
        <div className="admin-table-title">
          {lineDataUse && (
            <IconButton
              tabIndex={-1}
              onClick={() => setTableToggle((prev) => !prev)}
            >
              <ExpandMoreIcon
                sx={{
                  transform: `rotateZ(${tableToggle ? 360 : 180}deg)`,
                  transition: (theme) =>
                    theme.transitions.create("transform", {
                      duration: theme.transitions.duration.shortest,
                    }),
                  width: "24px",
                  height: "24px",
                  color: group_color,
                }}
              />
            </IconButton>
          )}

          {groupNameEditMode ? (
            <div>
              <input
                value={groupNameValue}
                onChange={(e) => setGroupNameValue(e.target.value)}
                onKeyDown={closeEditMode}
                ref={tableNameInputRef}
                className="admin-table-title-input"
                type="text"
              />
            </div>
          ) : (
            <div
              onDoubleClick={tableGroupEditorOpen}
              style={{ color: group_color, cursor: "pointer" }}
              className="admin-table-title"
            >
              {group_name}
            </div>
          )}
        </div>
        <div className="admin-table-box">
          <div
            className="admin-table-color-line"
            style={{ backgroundColor: group_color }}
          />

          <div
            ref={gridRef}
            style={{
              width: "calc(100% - 2px)",
              maxHeight: tableMaxHeight,
            }}
          >
            <DataGridPro
              rows={rows}
              columns={columns}
              checkboxSelection={
                admin_user ? true : user_perm === "read" ? false : true
              }
              onRowSelectionModelChange={handleSelectionChange}
              rowSelectionModel={selectionModel}
              hideFooter
              disableRowSelectionOnClick
              {...(table_name !== "sales" ? lineDataRef : {})}
              // {...lineDataRef}
              {...addRowRef}
              apiRef={apiRef}
              loading={isLoading}
              rowHeight={40}
              columnHeaderHeight={40}
              sx={{
                ...materialTableStyle,
                ...TableScrollbarStyle,
                "& .MuiDataGrid-root": {
                  overflow: "hidden", // 스크롤바 숨기기
                },
              }}
              //lazy loading start
              // rowCount={rowCount}
              // sortingMode="server"
              // filterMode="server"
              // rowsLoadingMode="server"
              // onFetchRows={debouncedHandleFetchRows}
              // onSortModelChange={(newSortModel) => {
              //   debouncedHandleFetchRows({
              //     sortModel: newSortModel,
              //   });
              // }}
              //lazy loading end

              onCellEditStart={handleCellEditStart}
              onCellKeyDown={handleCellKeyDown}
              // onCellEditStop={handleCellEditStop}
              disableColumnPinning
              disableColumnSelector
              disableDensitySelector
              filterModel={filterModel}
              onFilterModelChange={(e) => setFilterModel(e)}
              initialState={{
                pinnedColumns: { left: upperTablePinnedColumns },
              }}
              onCellClick={handleCellClick}
              isCellEditable={(params) => {
                const { row, field } = params;

                if (
                  table_name === "customer" &&
                  (field === "customer_code" || field === "customer") &&
                  (row.status === "Active" || row.status === "In Active")
                ) {
                  return false;
                }

                if (
                  !status_field ||
                  !lock_status_list ||
                  (table_name === "sales" &&
                    field === "sales_stage" &&
                    (row[field] === "Won" || row[field] === "Lost"))
                ) {
                  return false;
                }

                if (
                  table_name === "sales" &&
                  (field === "sales_stage" || field === "amount")
                ) {
                  return true;
                }

                if (lock_status_list.includes(row?.[status_field])) {
                  return field === status_field; // status 필드만 편집 가능
                } else {
                  return true; // 모든 필드 편집 가능
                }
              }}
            />
          </div>
        </div>
      </div>
    );
  }
);

export default MaterialTable;
