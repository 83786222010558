import React, { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useRecoilState } from "recoil";
import { ProjectAddUserModalStatus } from "@/atoms/modalStatus";
import { Modal, IconButton, Button } from "@mui/material";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import SelectDataTable from "@components/SelectDataTable";
import { userPermAxios } from "@utils/customAxios";
import { create_alert_fn } from "@utils/createAlert";
import { createTableColumn } from "./Columns";

import "./projectAddUserModal.scss";

const ProjectAddUserModal = () => {
  const [modalStatus, setModalStatus] = useRecoilState(
    ProjectAddUserModalStatus
  );

  const { open, sales_id, sales_lead } = modalStatus;

  const [dubleClick, setDubleClick] = useState(true);
  const [selectionModel, setSelectionModel] = useState([]);

  const closeModal = () => {
    setModalStatus((prev) => ({
      ...prev,
      open: false,
      sales_id: null,
    }));
  };

  const query_project_perm_add_user_data = useQuery(
    ["project_perm_add_user_data", sales_id],
    () => {
      return query_fetch_data(sales_id);
    },
    {
      initialData: { table_data: [] },
    }
  );

  const { table_data } = query_project_perm_add_user_data.data;

  const button_disabled = useMemo(() => {
    if (selectionModel.length === 0) {
      return true;
    }

    return false;
  }, [selectionModel]);

  const addUsertoProject = async () => {
    try {
      const requestAddUser = await userPermAxios.post(
        `/${sales_id}/add-user-to-project`,
        {
          lineData: selectionModel,
        }
      );

      const { success } = requestAddUser;

      if (success) {
        create_alert_fn(
          "success",
          "Successfully Assign User Permission.",
          () => {
            closeModal();
          }
        );
      }
    } catch (err) {
      create_alert_fn("warning", "Assign User to Project", () => {});
    } finally {
      setDubleClick(true);
    }
  };

  const openSubmitModal = () => {
    if (!dubleClick) return;
    setDubleClick(false);

    create_alert_fn(
      "info",
      "Are you sure you want to create a line item?",
      async () => {
        await addUsertoProject();
      },
      true
    );
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="project-add-user-modal-total-body">
        <div className="project-add-user-modal-header-box">
          <div className="project-add-user-modal-header-title">
            Assign User to Project
          </div>
          <IconButton
            onClick={closeModal}
            className="project-add-user-modal-close-btn"
          >
            <CloseOutlined fontSize="small" />
          </IconButton>
        </div>

        <div className="project-add-user-modal-main-box">
          <SelectDataTable
            title={sales_lead}
            rows={table_data}
            columns={createTableColumn()}
            selectModel={selectionModel}
            setSelectModel={setSelectionModel}
            table_height={500}
          />
        </div>

        <div className="project-add-user-modal-btn-box">
          <Button
            role={undefined}
            variant="outlined"
            tabIndex={-1}
            onClick={closeModal}
            className="project-add-user-modal-cancel-btn"
          >
            Cancel
          </Button>
          <Button
            onClick={openSubmitModal}
            className="project-add-user-modal-update-btn"
            disabled={button_disabled}
          >
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ProjectAddUserModal;

const query_fetch_data = async (sales_id) => {
  const result = { table_data: [] };

  try {
    if (!sales_id) {
      return;
    }

    const requestUserList = await userPermAxios.get(
      `/get-project-perm-add-user-list/${sales_id}`
    );

    const { success, data } = requestUserList;

    if (success) {
      result.table_data = data;
    }
  } catch (err) {
  } finally {
    return result;
  }
};
