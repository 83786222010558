import React, { useMemo } from "react";
import { Navigate } from "react-router-dom";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import {
  ProjectAddUserModalStatus,
  MenuAddUserModalStatus,
  UserAddMenuModalStatus,
} from "@/atoms/modalStatus";
import ProjectAddUserModal from "@/components/projectAddUserModal/ProjectAddUserModal";
import MenuAdduserModal from "@/components/menuAdduserModal/MenuAdduserModal";
import UserAddPermModal from "@/components/userAddPermModal/UserAddPermModal";
import Layout from "@/layout/Layout";
import UserPermPage from "./menu/userAdmin/UserPermPage";
import LogPage from "./menu/logs/LogPage";
import TaxReportPage from "./menu/report/TaxReportPage";
import OrganizationSettingPage from "./menu/setting/OrganizationSettingPage";
import DayoffPolicySettingPage from "./menu/setting/DayoffPolicySettingPage";
import CompanySettingPage from "./menu/setting/CompanySettingPage";
import PayrollSettingPage from "./menu/setting/PayrollSettingPage";
import PayrollManagementPage from "./menu/payroll/PayrollManagementPage";
import DailyWageManagementPage from "./menu/payroll/DailyWageManagementPage";
import CostCodeSettingPage from "./menu/setting/CostCodeSettingPage";
import AnnualLeaveLogPage from "./menu/logs/AnnualLeaveLogPage";
import { userPermAxios } from "@/utils/customAxios";
import { isLoginCheck } from "@/utils/isLoginCheck";
import { create_alert_fn } from "@/utils/createAlert";
import allResetStates from "@/utils/allResetStates";

const AdminRouter = () => {
  const [
    projectAddUserModalStatus,
    menuAddUserModalStatus,
    userAddMenuModalStatus,
  ] = [
    useRecoilValue(ProjectAddUserModalStatus),
    useRecoilValue(MenuAddUserModalStatus),
    useRecoilValue(UserAddMenuModalStatus),
  ];

  const { menu } = useParams();

  const query_login_check = useQuery(["user_login_check", menu], async () => {
    await isLoginCheck();

    const requserCheckAdminUser = await userPermAxios.get("/check-admin-user");

    const { data } = requserCheckAdminUser;

    return data;
  });

  const { data, isLoading } = query_login_check;

  const renderComponents = useMemo(() => {
    if (isLoading) {
      return null;
    }

    if (user_permission_menu_list.includes(menu)) {
      return <UserPermPage />;
    }

    if (logs_menu_list.includes(menu)) {
      return <LogPage />;
    }

    if (menu === "tax_report") {
      return <TaxReportPage />;
    }

    if (menu === "organization_setting") {
      return <OrganizationSettingPage />;
    }

    if (menu === "dayoff_policy_setting") {
      return <DayoffPolicySettingPage />;
    }

    if (menu === "company_setting") {
      return <CompanySettingPage />;
    }

    if (menu === "payroll_setting") {
      return <PayrollSettingPage />;
    }

    if (menu === "payroll_management") {
      return <PayrollManagementPage />;
    }

    if (menu === "daily_wage_management") {
      return <DailyWageManagementPage />;
    }

    if (menu === "cost_code_setting") {
      return <CostCodeSettingPage />;
    }

    if (menu === "dayoff_log") {
      return <AnnualLeaveLogPage />;
    }

    return null;
  }, [menu, isLoading]);

  if (!data && !isLoading) {
    create_alert_fn(
      "warning",
      "You do not have access to the Administrator page.",
      () => {}
    );

    allResetStates();
    return <Navigate to="/home" />;
  }

  if (isLoading) {
    return null;
  }

  return (
    <>
      <Layout top_menu="admin" menu={menu}>
        {renderComponents}
      </Layout>
      {projectAddUserModalStatus.open && <ProjectAddUserModal />}
      {menuAddUserModalStatus.open && <MenuAdduserModal />}
      {userAddMenuModalStatus.open && <UserAddPermModal />}
    </>
  );
};

export default AdminRouter;

const user_permission_menu_list = [
  "user_registration",
  "assign_user_to_bps",
  "assign_user_to_project",
];

const logs_menu_list = ["login_log", "record_log"];
