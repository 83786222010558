import React, { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useRecoilValue } from "recoil";
import {
  HrWorkPolicyData,
  HrCalendarUsingModalStatus,
} from "@atoms/modalStatus";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import Button from "@mui/material/Button";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import DayPicker from "@components/DatePicker";
import LabelSelect from "@components/LabelSelect";
import CustomTimePicker from "@components/CustomTimePicker";
import ApprovalLineBtn from "./ApprovalLineBtn";
import HorizontalSolidLine from "../HorizontalSolidLine";
import { userPermAxios } from "@utils/customAxios";
import { create_alert_fn } from "@utils/createAlert";
import PolicyModalBtn from "../dayOffSettingModals/PolicyModalBtn";

const DayoffMenu = ({
  dayoffData,
  setDayoffData,
  dayOffPolicyId,
  setDayOffPolicyId,
  approvalLineData,
  confirmApprovalLineData,
  resetApprovalLineData,
  approval_user_list,
  selectdayoffPolicy,
  target_user_id = null,
}) => {
  const { day_off_weekday_list, dayoff_policy_list } =
    useRecoilValue(HrWorkPolicyData);

  const { startYear, startMonth, startDay, endYear, endMonth, endDay } =
    useRecoilValue(HrCalendarUsingModalStatus);

  const [startDate, setStartDate] = useState(
    dayjs(`${startYear}-${startMonth}-${startDay}`)
  );
  const [endDate, setEndDate] = useState(
    dayjs(`${endYear}-${endMonth}-${endDay}`)
  );

  const query_work_type_sub_data = useQuery(
    [
      "annual_leave_sub_data",
      startDate.format("YYYY-MM-DD"),
      endDate.format("YYYY-MM-DD"),
      target_user_id,
    ],
    () => {
      return query_fetch_data(startDate, endDate, target_user_id);
    },
    {
      onSuccess: (data) => {
        setDayoffData((prev) => {
          return {
            ...prev,
            remaing_count: data?.remaining_annal_leave_count ?? 0,
          };
        });
      },
    }
  );

  const { data: annualSubData = {}, isLoading } = query_work_type_sub_data;

  const { hoily_day_list = [] } = annualSubData;

  const { policySetting, policy = {} } = selectdayoffPolicy;

  const {
    id,
    dayoff_policy_name,
    approve_use,
    cancel_approve_use,
    max_duration,
    pay_or_not,
    attachment_required,
    active_after_annualleave,
    recharge_condition_id,
    origin_id,
  } = policy;

  useEffect(() => {
    if (policySetting) {
      const dateDiff = endDate.diff(startDate, "day");

      const dayoffDataArr = [];

      if (dateDiff >= 0) {
        for (let i = 0; i <= dateDiff; i++) {
          const targetDateDayjs = dayjs(startDate).add(i, "days");

          const targetDay = targetDateDayjs.day();
          const targetDate = targetDateDayjs.format("YYYY-MM-DD");

          if (
            !day_off_weekday_list.includes(targetDay) &&
            !hoily_day_list.includes(targetDate)
          ) {
            dayoffDataArr.push({
              id: i,
              date: targetDate,
              year: targetDateDayjs.format("YYYY"),
              month: targetDateDayjs.format("MM"),
              day: targetDateDayjs.format("DD"),
              morning_off: false,
              afternoon_off: false,
            });
          }
        }
      }

      setDayoffData((prev) => {
        return { ...prev, list: dayoffDataArr };
      });
    } // eslint-disable-next-line
  }, [
    startDate,
    endDate,
    day_off_weekday_list,
    hoily_day_list,
    selectdayoffPolicy,
  ]);

  const handleStartDate = (e) => {
    if (e.diff(endDate, "days") > 0) {
      return create_alert_fn(
        "warning",
        "The start date cannot be greater than the end date."
      );
    }

    return setStartDate(e);
  };

  const handleEndDate = (e) => {
    if (e.diff(startDate, "days") < 0) {
      return create_alert_fn(
        "warning",
        "The start date cannot be greater than the end date."
      );
    }
    return setEndDate(e);
  };

  if (isLoading) {
    return null;
  }

  return (
    <div className="using-menu-body">
      <div className="using-menu-flex-1-box">
        <LabelSelect
          label="Dayoff"
          value={dayOffPolicyId}
          setValue={setDayOffPolicyId}
          list={[
            { name: "", value: "" },
            ...dayoff_policy_list.map((c) => {
              const { dayoff_policy_name, id, max_duration } = c;

              return {
                name: `${dayoff_policy_name} (${max_duration})`,
                value: id,
              };
            }),
          ]}
        />
      </div>
      {policySetting && (
        <>
          <div className="using-menu-date-range-box">
            <div className="using-menu-date-range">
              <div style={{ width: 90 }} className="using-menu-default-text">
                Start Date:
              </div>
              <DayPicker
                value={startDate}
                onChange={handleStartDate}
                width="calc(100% - 90px)"
              />
            </div>
            <div className="using-menu-date-range">
              <div style={{ width: 90 }} className="using-menu-default-text">
                End Date:
              </div>
              <DayPicker
                value={endDate}
                onChange={handleEndDate}
                width="calc(100% - 90px)"
              />
            </div>
          </div>
          <div className="using-menu-column-box">
            <div className="using-menu-flex-1-box">Edit Detailed Schedule</div>
            {(dayoffData?.list ?? []).map((c, i) => {
              const { id, date, year, month, day } = c;

              const week = dayjs(`${year}-${month}-${day}`).format("ddd");

              return (
                <div
                  key={id}
                  className="using-menu-work-list-card align-center"
                >
                  <div className="using-menu-default-text">{`${
                    i + 1
                  }. ${date} (${week})`}</div>
                </div>
              );
            })}
          </div>
          <div className="using-menu-flex-space-between-box">
            <div className="using-menu-default-text">
              {`Required Attachment (${
                dayoffData.attachment_list
                  ? dayoffData.attachment_list.length
                  : 0
              })`}
            </div>
            <Button
              component="label"
              role={undefined}
              variant="outlined"
              tabIndex={-1}
              startIcon={<FileUploadOutlinedIcon fontSize="small" />}
              sx={{ fontSize: 14, textTransform: "none", width: 300 }}
            >
              Upload File
              <input
                type="file"
                multiple
                style={{
                  clip: "rect(0 0 0 0)",
                  clipPath: "inset(50%)",
                  height: 1,
                  overflow: "hidden",
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  whiteSpace: "nowrap",
                  width: 1,
                }}
                onChange={(e) => {
                  const fileCount = e.target.files.length;
                  if (fileCount > 0) {
                    setDayoffData((prev) => {
                      return { ...prev, attachment_list: e.target.files };
                    });
                  } else {
                    setDayoffData((prev) => {
                      return { ...prev, attachment_list: null };
                    });
                  }
                }}
              />
            </Button>
          </div>
          {approve_use ? (
            <>
              <HorizontalSolidLine />
              <ApprovalLineBtn
                approvalLineData={approvalLineData}
                confirmApprovalLineData={confirmApprovalLineData}
                approval_user_list={approval_user_list}
              />
            </>
          ) : null}
        </>
      )}
    </div>
  );
};

export default DayoffMenu;

const query_fetch_data = async (startDate, endDate, target_user_id) => {
  let result = {
    remaining_annal_leave_count: 0,
    hoily_day_list: [],
  };

  try {
    const startYear = startDate.format("YYYY");
    const startMonth = startDate.format("MM");
    const startDay = startDate.format("DD");
    const endYear = endDate.format("YYYY");
    const endMonth = endDate.format("MM");
    const endDay = endDate.format("DD");

    const requestAnnualSubData = await userPermAxios.get(
      `/hr/get_annual_sub_data?startYear=${startYear}&startMonth=${startMonth}&startDay=${startDay}&endYear=${endYear}&endMonth=${endMonth}&endDay=${endDay}&target_user_id=${target_user_id}`
    );

    const { success, data } = requestAnnualSubData;

    if (success) {
      result = { ...data };
    }
  } catch (err) {
  } finally {
    return result;
  }
};

const UploadIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        d="M9.375 13.4523C9.375 13.7975 9.65482 14.0773 10 14.0773C10.3452 14.0773 10.625 13.7975 10.625 13.4523V4.12726C10.625 3.78208 10.3452 3.50226 10 3.50226C9.65482 3.50226 9.375 3.78208 9.375 4.12726V13.4523Z"
        fill="#0080DB"
      />
      <path
        d="M6.41733 6.60905C6.19422 6.86086 6.19422 7.26912 6.41733 7.52092C6.64043 7.77273 7.00215 7.77273 7.22526 7.52092L10.4044 3.93276C10.6275 3.68096 10.6275 3.27269 10.4044 3.02089C10.1813 2.76908 9.81956 2.76908 9.59646 3.02089L6.41733 6.60905Z"
        fill="#0080DB"
      />
      <path
        d="M13.5827 6.60905C13.8058 6.86086 13.8058 7.26912 13.5827 7.52092C13.3596 7.77273 12.9978 7.77273 12.7747 7.52092L9.59561 3.93276C9.37251 3.68096 9.37336 3.27269 9.59646 3.02089C9.81956 2.76908 10.1813 2.76908 10.4044 3.02089L13.5827 6.60905Z"
        fill="#0080DB"
      />
      <path
        d="M3.75 13.457C3.75 13.1119 3.47018 12.832 3.125 12.832C2.77982 12.832 2.5 13.1119 2.5 13.457V17.207C2.5 17.5522 2.77982 17.832 3.125 17.832H16.75C17.1642 17.832 17.5 17.4962 17.5 17.082V13.457C17.5 13.1119 17.2202 12.832 16.875 12.832C16.5298 12.832 16.25 13.1119 16.25 13.457V16.582H3.75V13.457Z"
        fill="#0080DB"
      />
    </svg>
  );
};
