import { useState, useEffect } from "react";
import _ from "lodash";

const useScrollCheck = (gridElement, apiRef) => {
  const [isScrollable, setIsScrollable] = useState(false);

  useEffect(() => {
    const checkScroll = () => {
      if (gridElement && apiRef) {
        const columnWidth = _.sumBy(apiRef.getAllColumns(), "computedWidth");
        const clientWidth = gridElement.clientWidth;

        const hasHorizontalScroll = columnWidth > clientWidth;

        setIsScrollable(hasHorizontalScroll);
      }
    };

    // 초기 스크롤 체크
    checkScroll();

    // 리사이즈 시 스크롤 상태 확인
    window.addEventListener("resize", checkScroll);
    return () => window.removeEventListener("resize", checkScroll);
  }, [gridElement, apiRef]);

  return isScrollable;
};

export default useScrollCheck;
