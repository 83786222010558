import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { CostApprovalModalStatus } from "@/atoms/modalStatus";
import { UserTargetProject } from "@/atoms/userInfo";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import Btn from "@/components/CustomIconBtn";
import CostApprovalModal from "@components/approvalManagement/Modal/CostApprovalModal";
import InitialBudget from "@/components/initialBudget/InitialBudget";
import PlusIcon from "@/components/Icons/PlusIcon";
import { userPermAxios } from "@/utils/customAxios";
import useRefreshReactQuery from "@/hooks/useRefreshReactQuery";
import AddInitialBudgetModal from "@/components/initialBudget/AddInitialBudgetModal";
import VerticalSolidLine from "@/components/VerticalSolidLine";

const InitialBudgetPage = () => {
  const [addModalToggle, setAddModalToggle] = useState(false);
  const { project_code, project_name } = useRecoilValue(UserTargetProject);
  const resetModalStatus = useResetRecoilState(CostApprovalModalStatus);
  const { open: modal_open } = useRecoilValue(CostApprovalModalStatus);

  useEffect(() => {
    if (project_code === "overall") {
      window.location.replace("/home/budget_sheet");
    }
  }, [project_code]);

  useEffect(() => {
    return () => resetModalStatus();
    // eslint-disable-next-line
  }, []);

  const query_initial_budget_data = useQuery(
    ["initial_budget_data", project_code],
    () => query_fetch_data(project_code)
  );

  const query_approval_user_list_data = useQuery(
    "approval_user_list_data",
    () => query_approval_fetch_data()
  );

  const [refresh] = useRefreshReactQuery(["initial_budget_data", project_code]);

  const query_cost_code_data = useQuery("cost_code_data", () =>
    query_cost_code_fetch_data()
  );

  const { data: ibData = {}, isLoading: ibDataLoading } =
    query_initial_budget_data;

  const { data: userListData = {}, isLoading: userListLoading } =
    query_approval_user_list_data;

  const { data: costCodeListData = {}, isLoading: costCodeLoading } =
    query_cost_code_data;

  const {
    contract_amount = [],
    initial_budget_list = [],
    add_button_use = false,
  } = ibData;

  const { list: userList = [] } = userListData;
  const { list: costCodeList = [] } = costCodeListData;

  const handleAddModal = () => {
    setAddModalToggle((prev) => !prev);
  };

  if (ibDataLoading || userListLoading || costCodeLoading) {
    return null;
  }

  return (
    <div className="work-policy-setting-page-body">
      <div className="work-policy-setting-page-title-box">
        <div className="work-policy-setting-page-title">Initial Budget</div>
        <div className="work-policy-setting-page-btn-box">
          <Btn
            icon={
              <RefreshOutlinedIcon sx={{ width: "20px", height: "20px" }} />
            }
            width={40}
            onClick={refresh}
          />

          {add_button_use && (
            <>
              <VerticalSolidLine height={24} />
              <Btn
                label="Add Initial Budget"
                icon={<PlusIcon />}
                onClick={handleAddModal}
                width={200}
              />
            </>
          )}
        </div>
      </div>
      <div className="company-setting-contents-body">
        <InitialBudget
          contract_amount={contract_amount}
          initial_budget_list={initial_budget_list}
        />
      </div>
      {modal_open && <CostApprovalModal />}
      {addModalToggle && (
        <AddInitialBudgetModal
          open={addModalToggle}
          onClose={handleAddModal}
          refresh={refresh}
          project_code={project_code}
          project_name={project_name}
          approval_user_list={userList}
          cost_code_list={costCodeList}
        />
      )}
    </div>
  );
};

export default InitialBudgetPage;

const query_fetch_data = async (project_code) => {
  let result = {
    contract_amount: [],
    initial_budget_list: [],
  };

  try {
    const requestInitialBudgetData = await userPermAxios.get(
      `/cost/get_initial_budget_data?project_code=${project_code}`
    );

    const { success, data } = requestInitialBudgetData;

    if (success) {
      result = { ...data };
    }
  } catch (err) {
  } finally {
    return result;
  }
};

const query_approval_fetch_data = async () => {
  let result = {
    list: [],
  };

  try {
    const requestApprovalUserList = await userPermAxios.get(
      "/cost/get_approval_user_list"
    );

    const { success, data } = requestApprovalUserList;

    if (success) {
      result = { ...data };
    }
  } catch (err) {
  } finally {
    return result;
  }
};

const query_cost_code_fetch_data = async () => {
  let result = {
    list: [],
  };

  try {
    const requestCostCodeList = await userPermAxios.get("/cost/get_cost_code");

    const { success, data } = requestCostCodeList;

    if (success) {
      result = { ...data };
    }
  } catch (err) {
  } finally {
    return result;
  }
};
