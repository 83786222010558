import React from "react";
import {
  CustomNormalTextTypeCell,
  CustomNumberTypeCell,
} from "@components/Cells";

export const budgetAndSpendColumn = [
  // {
  //   field: "job_number",
  //   headerName: "Job Number",
  //   flex: 1,
  //   data_type: "string",
  //   editable: false,
  //   filterable: true,
  //   renderCell: (params) => {
  //     return <CustomNormalTextTypeCell params={params} />;
  //   },
  // },
  {
    field: "sales_lead",
    headerName: "Lead",
    minWidth: 150,
    flex: 1,
    data_type: "string",
    editable: false,
    filterable: true,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "budget",
    headerName: "Budget",
    width: 150,
    data_type: "string",
    editable: false,
    filterable: true,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "spend",
    headerName: "Spend",
    width: 150,
    data_type: "string",
    editable: false,
    filterable: true,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "rate",
    headerName: "Rate(%)",
    width: 90,
    data_type: "string",
    editable: false,
    filterable: true,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
];

export const monthlySalesStackColumn = [
  {
    name: "On Bid",
    field: "on_bid",
    color: "#00b4d8",
  },
  {
    name: "Won",
    field: "won",
    color: "#0080db",
  },
  {
    name: "Lost",
    field: "lost",
    color: "#fe3426",
  },
];

export const monthlySpendBarColumn = [
  {
    name: "Spend",
    field_name: "spend",
    color: "#E0B589",
  },
];

export const monthlySpendLineColumn = [
  {
    name: "Acc Spend",
    field_name: "acc_spend",
    color: "#EB7A25",
  },
];

export const monthlyPrPoBarColumn = [
  {
    name: "PR",
    field_name: "pr",
    color: "#EFE1CE",
  },
  {
    name: "PO",
    field_name: "po",
    color: "#E0B589",
  },
];

export const todayWorkStatusBarColumn = [
  {
    name: "Work",
    field_name: "work",
    color: "#EFE1CE",
  },
  {
    name: "Absent",
    field_name: "absent",
    color: "#E0B589",
  },
];

export const monthlyAbsentTrendLineColumn = [
  {
    name: "Direct",
    field_name: "direct",
    color: "#EFE1CE",
  },
  {
    name: "Indirect",
    field_name: "indirect",
    color: "#E0B589",
  },
];
