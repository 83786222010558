import React, { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useRecoilValue } from "recoil";
import { EditManpowerRequestModalStatus } from "@/atoms/modalStatus";
import { Button } from "@mui/material";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import LabelInput from "@components/LabelInput";
import LabelSelect from "@components/LabelSelect";
import ModalBody from "@components/ModalBody";
import DayPicker from "@components/DatePicker";
import useRefreshReactQuery from "@/hooks/useRefreshReactQuery";
import { userPermAxios } from "@/utils/customAxios";
import { create_alert_fn } from "@/utils/createAlert";

dayjs.extend(customParseFormat);

const EditManPowerRequestModal = () => {
  const { open, onClose, id, list_query_key } = useRecoilValue(
    EditManpowerRequestModalStatus
  );

  const [approvalStatus, setApprovalStatus] = useState("");
  const [dateofHiring, setDateofHiring] = useState(null);
  const [dubleClick, setDubleClick] = useState(true);

  const query_manpower_detail_data = useQuery(
    ["manpower_detail_data", id],
    () => {
      return query_fetch_data(id);
    },
    {
      onSuccess: (result) => {
        const { approval_status, hiring_date } = result;
        setApprovalStatus(approval_status);
        setDateofHiring(hiring_date ? dayjs(hiring_date, "YYYY-MM-DD") : null);
        return;
      },
    }
  );

  const { data = {}, isLoading } = query_manpower_detail_data;

  const {
    request_username = "",
    request_person = 0,
    reason_for_hiring = "",
    nec_org_setting_name = "",
    nec_lv_name = "",
    nec_fn_name = "",
    approval_status = "",
    edit_disabled = true,
    hiring_date = null,
  } = data;

  const [refresh] = useRefreshReactQuery(list_query_key);

  const requestUpdateStatus = async () => {
    setDubleClick(false);

    let msgStatus = {
      status: "success",
      message: "",
    };

    try {
      const requestUpdateStatus = await userPermAxios.post(
        `/hr/update_manpower_request_status`,
        {
          id,
          approval_status: approvalStatus,
          date_of_hiring: dateofHiring
            ? dayjs(dateofHiring).format("YYYY-MM-DD")
            : null,
        }
      );

      const { success, message } = requestUpdateStatus;

      if (success) {
        msgStatus.status = "success";
      } else {
        msgStatus.status = "warning";
      }

      msgStatus.message = message;
    } catch (err) {
      msgStatus.status = "warning";
      if (err.response && err?.response?.data?.message) {
        msgStatus.message = err?.response?.data?.message;
      }
    } finally {
      setDubleClick(true);

      create_alert_fn(msgStatus.status, msgStatus.message);

      if (msgStatus.status === "success") {
        refresh();
        onClose();
      }
    }
  };

  const confirmSave = () => {
    if (!dubleClick) {
      return;
    }
    const msg = "Are you sure you want to update status?";

    return create_alert_fn("info", msg, requestUpdateStatus, true);
  };

  const status_disabled = useMemo(() => {
    const disabled_status_list = [
      "Rejected",
      "Offer Accepted",
      "Rejected by Candidate",
    ];

    if (disabled_status_list.includes(approval_status)) {
      return true;
    }
    return false;
  }, [approval_status]);

  const button_disabled = useMemo(() => {
    if (approval_status === approvalStatus && dateofHiring === hiring_date) {
      return true;
    }

    if (approval_status === "Rejected" || approval_status === "Closed") {
      return true;
    }

    return edit_disabled || status_disabled;
  }, [
    approval_status,
    approvalStatus,
    edit_disabled,
    dateofHiring,
    hiring_date,
    status_disabled,
  ]);

  if (isLoading) {
    return null;
  }

  return (
    <ModalBody
      open={open}
      onClose={onClose}
      title="Edit Manpower Request"
      disableEscapeKeyDown={true}
      modalWidth={500}
    >
      <div className="work-policy-modal-contents-box">
        <div
          className="work-policy-modal-detail-contents-box"
          style={{ maxHeight: 500, overflowY: "auto" }}
        >
          <div
            className="work-policy-modal-time-picker-detail"
            style={{ width: "100%" }}
          >
            <div className="work-policy-modal-detail-contents-title">
              Request User
            </div>
            <LabelInput disabled value={request_username} />
          </div>
          <div
            className="work-policy-modal-time-picker-detail"
            style={{ width: "100%" }}
          >
            <div className="work-policy-modal-detail-contents-title">
              Reason for Hiring
            </div>
            <LabelInput disabled value={reason_for_hiring} />
          </div>

          <div
            className="work-policy-modal-time-picker-detail"
            style={{ width: "100%" }}
          >
            <div className="work-policy-modal-detail-contents-title">
              Necessary Department
            </div>
            <LabelInput disabled value={nec_org_setting_name} />
          </div>
          <div
            className="work-policy-modal-time-picker-detail"
            style={{ width: "100%" }}
          >
            <div className="work-policy-modal-detail-contents-title">
              Necessary Job Position
            </div>
            <LabelInput disabled value={nec_lv_name} />
          </div>
          <div
            className="work-policy-modal-time-picker-detail"
            style={{ width: "100%" }}
          >
            <div className="work-policy-modal-detail-contents-title">
              Necessary Job Grade
            </div>
            <LabelInput disabled value={nec_fn_name} />
          </div>
          <div
            className="work-policy-modal-time-picker-detail"
            style={{ width: "100%" }}
          >
            <div className="work-policy-modal-detail-contents-title">
              Necessary Personnel
            </div>
            <LabelInput
              disabled
              value={Number(request_person).toLocaleString()}
            />
          </div>
          <div
            className="work-policy-modal-time-picker-detail"
            style={{ width: "100%" }}
          >
            <div className="work-policy-modal-detail-contents-title">
              Date of Hiring
            </div>
            <DayPicker
              width="100%"
              value={dateofHiring}
              onChange={(e) => setDateofHiring(e)}
              disabled={edit_disabled || status_disabled}
            />
          </div>
          <div
            className="work-policy-modal-time-picker-detail"
            style={{ width: "100%" }}
          >
            <div className="work-policy-modal-detail-contents-title">
              Status
            </div>
            <LabelSelect
              list={status_list}
              value={approvalStatus}
              setValue={setApprovalStatus}
              disabled={edit_disabled || status_disabled}
            />
          </div>
        </div>
      </div>
      <div className="work-policy-modal-btn-box">
        <Button
          role={undefined}
          variant="outlined"
          tabIndex={-1}
          onClick={onClose}
          className="work-policy-modal-cancel-btn"
        >
          Cancel
        </Button>
        <Button
          className="work-policy-modal-update-btn"
          disabled={button_disabled}
          onClick={confirmSave}
        >
          Save
        </Button>
      </div>
    </ModalBody>
  );
};

export default EditManPowerRequestModal;

const query_fetch_data = async (id) => {
  let result = {
    request_username: "",
    request_person: 0,
    reason_for_hiring: "",
    nec_org_setting_name: "",
    nec_lv_name: "",
    nec_fn_name: "",
    approval_status: "Draft",
    hiring_date: null,
    edit_disabled: true,
  };

  try {
    const requestDetailData = await userPermAxios.get(
      `/hr/get_manpower_request_detail_data?id=${id}`
    );
    const { success, data } = requestDetailData;
    if (success) {
      result = { ...data };
    }
  } catch (err) {
  } finally {
    return result;
  }
};

const status_list = [
  { name: "Draft", value: "Draft" },
  { name: "Approved", value: "Approved" },
  { name: "Rejected", value: "Rejected" },
  { name: "Sourcing Candidates", value: "Sourcing Candidates" },
  { name: "Interviewing", value: "Interviewing" },
  { name: "Offer Extended", value: "Offer Extended" },
  { name: "Offer Accepted", value: "Offer Accepted" },
  // { name: "Closed", value: "Closed" },
  { name: "Withdrawn", value: "Withdrawn" },
  { name: "On Hold", value: "On Hold" },
  { name: "Rejected by Candidate", value: "Rejected by Candidate" },
];
