import React, { useState } from "react";
import ReactQuill from "react-quill";
import Button from "@mui/material/Button";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import "react-quill/dist/quill.snow.css";
import { userPermAxios } from "@utils/customAxios";

const ReCommentEidtorForm = ({
  target_id,
  initial_value,
  parent_comment_id,
  table_name,
  row_id,
  type,
  setEditorMode,
  refresh_fn,
  table_refresh_fn,
}) => {
  const [value, setValue] = useState(initial_value ?? "");
  const [files, setFiles] = useState(null);

  const updateRecomment = async () => {
    const formData = new FormData();

    if (files) {
      for (const file in files) {
        if (file !== "length" && file !== "item") {
          const targetFile = files[file];

          formData.append(`files`, targetFile);
        }
      }
    }

    const bodyData = {
      text: value,
      commentableId: row_id,
      commentableType: table_name,
      commentId: target_id ?? null,
      parentId: parent_comment_id,
    };

    formData.append("additionalData", JSON.stringify(bodyData));

    if (type === "create") {
      const requestCreateComment = await userPermAxios
        .post("/create-comments", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch((err) => {
          return { success: false };
        });

      const { success } = requestCreateComment;

      if (success) {
        closeEditMode();
        refresh_fn();
        table_refresh_fn();
      }
    }

    if (type === "modified") {
      const requestCreateComment = await userPermAxios
        .post("/modified-comments", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch((err) => {
          return { success: false };
        });

      const { success } = requestCreateComment;

      if (success) {
        closeEditMode();
        refresh_fn();
        table_refresh_fn();
      }
    }
  };

  const closeEditMode = () => {
    setValue("");
    setFiles(null);
    setEditorMode(false);
  };

  return (
    <div className="re-comment-eidtor-total-body">
      <div className="re-comment-eidtor-editor-box">
        <ReactQuill
          theme="snow"
          value={value}
          onChange={setValue}
          modules={{ toolbar: false }}
        />
      </div>
      <div className="re-comment-eidtor-btn-box">
        <Button
          component="label"
          role={undefined}
          variant="outlined"
          tabIndex={-1}
          startIcon={<FileUploadOutlinedIcon fontSize="small" />}
          sx={{ fontSize: 12, textTransform: "none" }}
        >
          Upload File {files && `(${files.length})`}
          <input
            type="file"
            multiple
            style={{
              clip: "rect(0 0 0 0)",
              clipPath: "inset(50%)",
              height: 1,
              overflow: "hidden",
              position: "absolute",
              bottom: 0,
              left: 0,
              whiteSpace: "nowrap",
              width: 1,
            }}
            onChange={(e) => {
              const fileCount = e.target.files.length;
              if (fileCount > 0) {
                setFiles(e.target.files);
              } else {
                setFiles(null);
              }
            }}
          />
        </Button>
        <div className="re-comment-eidtor-control-btn-box">
          {type === "modified" && (
            <Button
              role={undefined}
              variant="contained"
              tabIndex={-1}
              sx={{
                fontSize: 12,
                textTransform: "none",
                padding: "5px 10px",
                minWidth: 0,
              }}
              onClick={closeEditMode}
            >
              Cancel
            </Button>
          )}

          <Button
            role={undefined}
            variant="contained"
            tabIndex={-1}
            sx={{
              fontSize: 12,
              textTransform: "none",
              padding: "5px 10px",
              minWidth: 0,
            }}
            onClick={updateRecomment}
          >
            Post
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ReCommentEidtorForm;
