import React, { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useRecoilValue } from "recoil";
import { UserSetting } from "@atoms/userInfo";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import {
  InputAdornment,
  TextField,
  Modal,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { ThemeProvider } from "@emotion/react";
import { labelInputTheme } from "@styles/muiThemes";
import { userPermAxios } from "@utils/customAxios";
import FileDownloadBtn from "../FileDownloadBtn";
import useRefreshReactQuery from "@hooks/useRefreshReactQuery";

const AttachmentSheet = ({ targetComment, tableHeight }) => {
  const { parent_table_name, table_name, id } = targetComment ?? {};

  const userSetting = useRecoilValue(UserSetting);

  const { userId, admin_user } = userSetting ?? {};

  const [refresh] = useRefreshReactQuery([
    "attachment_data",
    parent_table_name,
    table_name,
    id,
  ]);

  const [searchValue, setSearchValue] = useState("");
  const [files, setFiles] = useState([]);

  const query_attachment_data = useQuery(
    ["attachment_data", parent_table_name, table_name, id],
    () => query_fetch_data(parent_table_name, table_name, id),
    {
      initialData: { attachment: [], comment_attachment: [] },
      onSuccess: () => {
        // setIsDataLoading(false);
      },
    }
  );

  const searchAttatchmentFile = useMemo(() => {
    const { attachment, comment_attachment } = query_attachment_data.data;

    const filterData = [attachment, comment_attachment].map((c) => {
      return c.filter((c2) => {
        const { filename } = c2;

        return filename?.toLowerCase()?.includes(searchValue.toLowerCase());
      });
    });

    const [filterAttach, filterCommentAttach] = filterData;

    return {
      attachment: filterAttach,
      comment_attachment: filterCommentAttach,
    };
  }, [query_attachment_data, searchValue]);

  const { attachment, comment_attachment } = searchAttatchmentFile;

  const handleFileRemove = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const submitAttachment = async () => {
    const formData = new FormData();

    if (files.length > 0) {
      for (const file of files) {
        formData.append(`files`, file);
      }
    }

    const bodyData = {
      commentableId: id,
      commentableType: table_name,
    };

    formData.append("additionalData", JSON.stringify(bodyData));

    const requestCreateComment = await userPermAxios
      .post("/create-attach", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .catch((err) => {
        console.log(err);
        return { success: false };
      });

    const { success } = requestCreateComment;

    if (success) {
      setFiles([]);
      setSearchValue("");
      refresh();
    }
  };

  const deleteAttachmentFile = async (id, type) => {
    try {
      const requestDeleteAttach = await userPermAxios.post("/delete-attach", {
        id,
        type,
      });

      const { success } = requestDeleteAttach;

      if (success) {
        refresh();
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div
      className="attachment-sheet-total-body"
      style={{ height: tableHeight, overflow: "auto" }}
    >
      <div className="attachment-sheet-control-box">
        <Button
          component="label"
          role={undefined}
          variant="outlined"
          tabIndex={-1}
          startIcon={<FileUploadOutlinedIcon fontSize="small" />}
          sx={{ fontSize: 14, textTransform: "none" }}
        >
          Upload File {files.length > 0 && `(${files.length})`}
          <input
            type="file"
            multiple
            style={{
              clip: "rect(0 0 0 0)",
              clipPath: "inset(50%)",
              height: 1,
              overflow: "hidden",
              position: "absolute",
              bottom: 0,
              left: 0,
              whiteSpace: "nowrap",
              width: 1,
            }}
            onChange={(e) => {
              const fileCount = e.target.files.length;
              if (fileCount > 0) {
                const files = Array.from(e.target.files);
                setFiles(files);
              } else {
                setFiles([]);
              }
            }}
          />
        </Button>

        <ThemeProvider theme={labelInputTheme}>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlinedIcon />
                </InputAdornment>
              ),
            }}
            placeholder="Search Attachment"
            sx={{ maxWidth: "300px" }}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </ThemeProvider>
      </div>
      <div className="attachment-sheet-attachment-group-box">
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            Attachment
          </AccordionSummary>
          <AccordionDetails
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "10px",
            }}
          >
            {!attachment || attachment.length === 0
              ? "There is no attachment."
              : attachment.map((c, i) => {
                  const { filename, url, id: target_id } = c;
                  return (
                    <FileDownloadBtn
                      key={target_id}
                      url={url}
                      filename={filename}
                      delete_fn_use
                      delete_fn={() => deleteAttachmentFile(target_id, "main")}
                    />
                  );
                })}
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            Comment Attachment
          </AccordionSummary>
          <AccordionDetails
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "10px",
            }}
          >
            {!comment_attachment || comment_attachment.length === 0
              ? "There is no attachment."
              : comment_attachment.map((c, i) => {
                  const { filename, url, id: target_id, author_id } = c;

                  return (
                    <FileDownloadBtn
                      key={i}
                      url={url}
                      filename={filename}
                      delete_fn_use={
                        admin_user === true ? true : author_id === userId
                      }
                      delete_fn={() =>
                        deleteAttachmentFile(target_id, "comment")
                      }
                    />
                  );
                })}
          </AccordionDetails>
        </Accordion>
      </div>
      <Modal
        open={files.length > 0}
        onClose={() => setFiles([])}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ zIndex: 9999 }}
      >
        <Box sx={style}>
          <div className="attachment-modal-body">
            <List
              sx={{
                width: "100%",
                maxWidth: 500,
                height: 260,
                overflow: "auto",
              }}
              className="attachment-modal-contents-total-box"
            >
              {files &&
                files.map((c, i) => {
                  const targetFile = c.name;
                  return (
                    <ListItem
                      key={i}
                      disableGutters
                      secondaryAction={
                        <IconButton
                          aria-label="comment"
                          onClick={() => handleFileRemove(i)}
                        >
                          <CloseOutlinedIcon />
                        </IconButton>
                      }
                      className="attachment-modal-contents-box"
                    >
                      <ListItemText
                        primary={`${i + 1}. ${targetFile}`}
                        className="attachment-modal-contents-name"
                      />
                    </ListItem>
                  );
                })}
            </List>

            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="500"
                height="1"
                viewBox="0 0 640 1"
                fill="none"
              >
                <path d="M0 0H640V1H0V0Z" fill="#E4E7EC" />
              </svg>
            </div>
            <div className="attachment-modal-control-box">
              <Button
                onClick={submitAttachment}
                className="attachment-modal-submit-btn"
              >
                Save
              </Button>
              <Button
                role={undefined}
                variant="outlined"
                tabIndex={-1}
                onClick={() => setFiles([])}
                className="attachment-modal-cancle-btn"
              >
                Cancel
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default AttachmentSheet;

const query_fetch_data = async (parent_table_name, table_name, id) => {
  const result = { attachment: [], comment_attachment: [] };

  try {
    const requestAttachmentList = await userPermAxios.post("/get-attach", {
      tableName: table_name,
      rowId: id,
    });

    const { success, data } = requestAttachmentList;

    if (success) {
      result.attachment = data?.attachment ?? [];
      result.comment_attachment = data?.comment_attachment ?? [];
    }
  } catch (err) {
  } finally {
    return result;
  }
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  borderRadius: "16px",
  border: "1px solid var(--Gray-200, #E4E7EC)",
  background: "var(--White, #FFF)",
  boxShadow:
    "0px 8px 16px 0px rgba(0, 0, 0, 0.14), 0px 0px 2px 0px rgba(0, 0, 0, 0.12)",
};
