export const groupColorList = [
  "#F8443E",
  "#F95D6A",
  "#F66D44",
  "#FEAE65",
  "#F2E85C",
  "#E6F69D",
  "#B0E6E6",
  "#AADEA7",
  "#64C2A6",
  "#12B76A",
  "#003F5C",
  "#665191",
  "#A05195",
  "#D45087",
  "#F79009",
  "#0077B6",
  "#0096C7",
  "#00B4D8",
  "#48CAE4",
  "#90E0EF",
];

export const salesStatusColorList = [
  // { name: "Lead Identified", color: "#B7B7B7", fontColor: "#fff" },
  // { name: "Submitted", color: "#04C63A", fontColor: "#fff" },
  { name: "On Bid", color: "#00B4D8", fontColor: "#fff" },
  // { name: "Contacted", color: "#FFC90B", fontColor: "#fff" },
  // { name: "Qualified", color: "#04C63A", fontColor: "#fff" },
  // { name: "Proposal Sent", color: "#00B4D8", fontColor: "#fff" },
  // { name: "Negotiation", color: "#FFA011", fontColor: "#fff" },
  { name: "Won", color: "#0080DB", fontColor: "#fff" },
  { name: "Lost", color: "#FE3426", fontColor: "#fff" },
];

export const salesPriorityColorList = [
  { name: "High", color: "#00A6FF", fontColor: "#fff" },
  { name: "Mid", color: "#FBAB43", fontColor: "#fff" },
  { name: "Low", color: "#98A2B3", fontColor: "#fff" },
];

export const pRStatusColorList = [
  // { name: "Draft", color: "#B7B7B7", fontColor: "#fff" },
  { name: "Sent for Approval", color: "#04C63A", fontColor: "#fff" },
  { name: "Approved", color: "#0080DB", fontColor: "#fff" },
  { name: "Rejected", color: "#FE3426", fontColor: "#fff" },
];

export const vendorSatusColorList = [
  // { name: "Draft", color: "#B7B7B7", fontColor: "#fff" },
  { name: "Sent for Approval", color: "#04C63A", fontColor: "#fff" },
  { name: "Approved", color: "#0080DB", fontColor: "#fff" },
  { name: "Rejected", color: "#FE3426", fontColor: "#fff" },
];

export const customerSatusColorList = [
  // { name: "Draft", color: "#B7B7B7", fontColor: "#fff" },
  // { name: "Sent for Approval", color: "#04C63A", fontColor: "#fff" },
  // { name: "Approved", color: "#0080DB", fontColor: "#fff" },
  // { name: "Rejected", color: "#FE3426", fontColor: "#fff" },
  { name: "Active", color: "#0080DB", fontColor: "#fff" },
  { name: "In Active", color: "#FE3426", fontColor: "#fff" },
];

export const rfqSatusColorList = [
  // { name: "Draft", color: "#B7B7B7", fontColor: "#fff" },
  { name: "Sent for Approval", color: "#04C63A", fontColor: "#fff" },
  { name: "Approved", color: "#0080DB", fontColor: "#fff" },
  { name: "Rejected", color: "#FE3426", fontColor: "#fff" },
];

export const packingListStatusColorList = [
  // { name: "Draft", color: "#B7B7B7", fontColor: "#fff" },
  { name: "Sent for Approval", color: "#04C63A", fontColor: "#fff" },
  { name: "Approved", color: "#0080DB", fontColor: "#fff" },
  { name: "Rejected", color: "#FE3426", fontColor: "#fff" },
];

export const materialReceivedSatusColorList = [
  { name: "Arrived", color: "#04C63A", fontColor: "#fff" },
  { name: "Inspected", color: "#0080DB", fontColor: "#fff" },
  { name: "Return", color: "#FE3426", fontColor: "#fff" },
];

export const materialReleaseSatusColorList = [
  // { name: "Draft", color: "#B7B7B7", fontColor: "#fff" },
  { name: "Sent for Approval", color: "#04C63A", fontColor: "#fff" },
  { name: "Approved", color: "#0080DB", fontColor: "#fff" },
  { name: "Rejected", color: "#FE3426", fontColor: "#fff" },
];

export const invQtyCtrlSatusColorList = [
  // { name: "Draft", color: "#B7B7B7", fontColor: "#fff" },
  { name: "Sent for Approval", color: "#04C63A", fontColor: "#fff" },
  { name: "Approved", color: "#0080DB", fontColor: "#fff" },
  { name: "Rejected", color: "#FE3426", fontColor: "#fff" },
];

export const inventoryColorList = [
  { name: "1 inventory", color: "#B7B7B7", fontColor: "#fff" },
  { name: "2 inventory", color: "#B7B7B7", fontColor: "#fff" },
  { name: "3 inventory", color: "#B7B7B7", fontColor: "#fff" },
  { name: "4 inventory", color: "#B7B7B7", fontColor: "#fff" },
];

export const shopColorList = [
  { name: "1 shop", color: "#B7B7B7", fontColor: "#fff" },
  { name: "2 shop", color: "#B7B7B7", fontColor: "#fff" },
  { name: "3 shop", color: "#B7B7B7", fontColor: "#fff" },
  { name: "4 shop", color: "#B7B7B7", fontColor: "#fff" },
];
