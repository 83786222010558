import React, { useMemo, useState, useRef } from "react";
import ReactLoading from "react-loading";
import { useRecoilValue } from "recoil";
import { UserTargetProject } from "@/atoms/userInfo";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import dayjs from "dayjs";
import { useQuery } from "react-query";
import MonthPicker from "@/components/MonthPicker";
import Btn from "@/components/CustomIconBtn";
import StackColumnChart from "@/components/stackColumnChart/StackColumnChart";
import LineChart from "@/components/lineChart/LineChart";
import ColumnLineChart from "@/components/columnLineChart/ColumnLineChart";
import MaterialStatus from "@/components/materialStatus/MaterialStatus";
import DashboardTable from "@/components/dashboardTable/DashboardTable";
import {
  budgetAndSpendColumn,
  monthlyAbsentTrendLineColumn,
  monthlyPrPoBarColumn,
  monthlySalesStackColumn,
  monthlySpendBarColumn,
  monthlySpendLineColumn,
  todayWorkStatusBarColumn,
} from "./initialData";
import useWindowDimensions from "@/hooks/useWindowDimensions";
import useRefreshReactQuery from "@/hooks/useRefreshReactQuery";
import { userPermAxios } from "@/utils/customAxios";

const DashboardPage = () => {
  const contentsBoxRef = useRef(null);
  const { height } = useWindowDimensions();
  const { project_name, project_code } = useRecoilValue(UserTargetProject);
  const [startMonth, setStartMonth] = useState(dayjs().subtract(2, "months"));
  const [endMonth, setEndMonth] = useState(dayjs().add(2, "months"));

  const monthDiff = endMonth.diff(startMonth, "month") + 1;

  const query_key = [
    project_code,
    startMonth.startOf("month").format("YYYY-MM-DD"),
    endMonth.endOf("month").format("YYYY-MM-DD"),
  ];

  const query_dashboard_data = useQuery(query_key, () => {
    return query_fetch_data(
      project_code,
      startMonth.startOf("month").format("YYYY-MM-DD"),
      endMonth.endOf("month").format("YYYY-MM-DD")
    );
  });

  const { data = {}, isLoading } = query_dashboard_data;

  const [dashboardDataRefresh] = useRefreshReactQuery(query_key);

  const {
    monthly_sales_data = [],
    budget_and_spend_data = [],
    monthly_spend_data = [],
    procurement_and_material_data = {},
    monthly_pr_po_data = [],
    today_work_status = [],
    monthly_absent_trend = [],
  } = data;

  const dashboardContentsHeight = useMemo(() => {
    return height - 104 - 32 - 40 - 52 - 20;
  }, [height]);

  return (
    <div className="memu-total-body hr-menu-total-body">
      <div
        className="hr-menu-total-title-box"
        style={{ justifyContent: "space-between" }}
      >
        <div className="hr-menu-total-title">{project_name} Dashboard</div>
        <div className="menu-button-box">
          <MonthPicker value={startMonth} onChange={(e) => setStartMonth(e)} />
          <div>-</div>
          <MonthPicker value={endMonth} onChange={(e) => setEndMonth(e)} />
          <div className="menu-button-box-vertical-bar" />
          <Btn
            icon={
              <RefreshOutlinedIcon sx={{ width: "20px", height: "20px" }} />
            }
            width={40}
            onClick={dashboardDataRefresh}
          />
        </div>
      </div>
      <div
        className="dashboard-menu-body"
        style={{
          height: dashboardContentsHeight,
          width: "100%",
          boxSizing: "border-box",
        }}
        ref={contentsBoxRef}
      >
        {project_code === "overall" && (
          <DashboardCardBox
            title="Monthly Sales"
            style={{ gridColumn: "1 / 11" }}
            isLoading={isLoading}
          >
            <StackColumnChart
              chart_id="monthly_sales_stack_column_chart"
              chart_data={monthly_sales_data}
              column_list={monthlySalesStackColumn}
              category_field="month"
              start_visible_length={monthDiff}
              column_text_color="#FFF"
            />
          </DashboardCardBox>
        )}

        <DashboardCardBox
          title="Budget & Spend"
          style={{ gridColumn: "1 / 5" }}
          isLoading={isLoading}
        >
          <DashboardTable
            rows={budget_and_spend_data}
            columns={budgetAndSpendColumn}
            rowHeight={28}
          />
        </DashboardCardBox>
        <DashboardCardBox
          title="Monthly Spend"
          style={{ gridColumn: "5 / 11" }}
          isLoading={isLoading}
        >
          <ColumnLineChart
            chart_id="monthly_spend_column_line_chart"
            chart_data={monthly_spend_data}
            column_list={monthlySpendBarColumn}
            line_list={monthlySpendLineColumn}
            category_field="month"
            start_visible_length={monthDiff}
            column_text_color="#FFF"
            unit="฿"
          />
        </DashboardCardBox>
        <DashboardCardBox
          title="Procurement & Material"
          style={{ gridColumn: "1 / 5" }}
          isLoading={isLoading}
          height={200}
        >
          <MaterialStatus table_data={procurement_and_material_data} />
        </DashboardCardBox>
        <DashboardCardBox
          title="Monthly PR/PO"
          style={{ gridColumn: "5 / 11" }}
          isLoading={isLoading}
          height={200}
        >
          <ColumnLineChart
            chart_id="monthly_pr_po_column_line_chart"
            chart_data={monthly_pr_po_data}
            column_list={monthlyPrPoBarColumn}
            start_visible_length={monthDiff}
            line_list={[]}
          />
        </DashboardCardBox>
        <DashboardCardBox
          title="Today Work Status"
          style={{ gridColumn: "1 / 7" }}
          isLoading={isLoading}
        >
          <ColumnLineChart
            chart_id="today_work_status_column_line_chart"
            chart_data={today_work_status}
            column_list={todayWorkStatusBarColumn}
            start_visible_length={30}
            category_field="day"
            line_list={[]}
            use_slide_bar
          />
        </DashboardCardBox>
        <DashboardCardBox
          title="Monthly Absent Trend"
          style={{ gridColumn: "7 / 11" }}
          isLoading={isLoading}
        >
          <LineChart
            chart_id="monthly_absent_trend_line_chart"
            chart_data={monthly_absent_trend}
            line_list={monthlyAbsentTrendLineColumn}
            start_visible_length={30}
            category_field="day"
            use_slide_bar
          />
        </DashboardCardBox>
      </div>
    </div>
  );
};

export default DashboardPage;

const query_fetch_data = async (project_code, startDate, endDate) => {
  let result = {
    monthly_sales_data: [],
    budget_and_spend_data: [],
    monthly_spend_data: [],
    procurement_and_material_data: {
      total_order_qnty_request: 0,
      total_order_qnty_order: 0,
      total_receive_qnty: 0,
      total_release_qnty: 0,
    },
    monthly_pr_po_data: [],
    today_work_status: [],
    monthly_absent_trend: [],
  };

  try {
    const requestDashBoardData = await userPermAxios.get(
      `/dashboard/get_dashboard_data?project_code=${project_code}&startDate=${startDate}&endDate=${endDate}`
    );

    const { success, data } = requestDashBoardData;

    if (success) {
      result = { ...data };
    }
  } catch (err) {
    console.log(err);
  } finally {
    return result;
  }
};

const DashboardCardBox = ({
  children,
  title = "",
  height = 300,
  style = {},
  isLoading = true,
}) => {
  return (
    <div
      className="dashboard-card"
      style={{
        height,

        ...style,
      }}
    >
      <div className="dashboard-title-box">
        <div className="dashboard-title">{title}</div>
      </div>
      <div className="dashboard-card-content-box">
        {isLoading ? (
          <ReactLoading
            type="spinningBubbles"
            color="black"
            width={56}
            height={56}
          />
        ) : (
          children
        )}
      </div>
    </div>
  );
};
