import React from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { materialTableStyle } from "@styles/muiThemes";
import { costCodeColumn } from "./Column";
import useCostTableHeight from "@/hooks/useCostTableHeight";
import "./costCode.scss";

const CostCodeTable = ({ row = [] }) => {
  const { height } = useCostTableHeight(false, true, false);

  if (height <= 0) {
    return null;
  }
  return (
    <div className="cost-code-table-body" style={{ maxHeight: height }}>
      <DataGridPro
        rows={row}
        columns={costCodeColumn}
        initialState={{}}
        rowHeight={40}
        columnHeaderHeight={40}
        sx={{
          ...materialTableStyle,
        }}
        disableColumnPinning
        disableColumnSelector
        disableDensitySelector
        hideFooter
      />
    </div>
  );
};

export default CostCodeTable;
