import React, { useMemo, useState } from "react";
import { animated, useSpring } from "@react-spring/web";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { UserGeneralMenu, UserTargetProject } from "@/atoms/userInfo";
import { useTreeViewApiRef, RichTreeView } from "@mui/x-tree-view";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";
import KeyboardDoubleArrowLeftRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowLeftRounded";
import KeyboardDoubleArrowRightRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowRightRounded";
import { unstable_useTreeItem2 as useTreeItem2 } from "@mui/x-tree-view/useTreeItem2";
import {
  TreeItem2Checkbox,
  TreeItem2IconContainer,
  TreeItem2Label,
} from "@mui/x-tree-view/TreeItem2";
import { TreeItem2Icon } from "@mui/x-tree-view/TreeItem2Icon";
import { TreeItem2Provider } from "@mui/x-tree-view/TreeItem2Provider";

import Collapse from "@mui/material/Collapse";
import clsx from "clsx";
import {
  // StyledTreeItemRoot,
  CustomTreeItemContent,
  // StyledTreeItemLabelText,
} from "@styles/customStyled";
import HorizontalSolidLine from "@/components/HorizontalSolidLine";
import {
  SalesMenuIcon,
  MaterialMenuIcon,
  CompanyMenuIcon,
  CostMenuIcon,
  HRMenuIcon,
  ApproveMenuIcon,
  PayrollMenuIcon,
  SettingMenuIcon,
  LogMenuIcon,
  UsersMenuIcon,
  ReportMenuIcon,
  DashboardMenuIcon,
} from "./SidebarIcon";

const Sidebar = ({ top_menu, menu }) => {
  // eslint-disable-next-line
  const navigate = useNavigate();
  const { userGeneralMenu, adminMenu } = useRecoilValue(UserGeneralMenu);
  const { project_code } = useRecoilValue(UserTargetProject);
  const apiRef = useTreeViewApiRef();
  const [sidebarWidth, setSidebarWidth] = useState(expandedWidth);

  const handleToggleNav = () => {
    setSidebarWidth(
      sidebarWidth === expandedWidth ? collapsedWidth : expandedWidth
    );
  };

  const [expandedItems, setExpandedItems] = useState([]);

  const handleExpandedItemsChange = (event, itemIds) => {
    setExpandedItems(itemIds);
  };

  const handleExpandClick = () => {
    setExpandedItems((oldExpanded) =>
      oldExpanded.length === 0
        ? getAllItemsWithChildrenItemIds(userGeneralMenu)
        : []
    );
  };

  const selectMenu = useMemo(() => {
    if (top_menu === "home") {
      return (userGeneralMenu ?? []).map((c) => {
        const { label, children } = c;

        if (label !== "Cost") {
          return c;
        }

        if (label === "Cost" && project_code === "overall") {
          const filter_children = children.filter((c2) => {
            const { label: child_label } = c2;

            if (
              child_label === "Initial Budget" ||
              child_label === "Budget Reallocation" ||
              child_label === "Budget Change" ||
              child_label === "Change Order" ||
              child_label === "Interim Payment"
            ) {
              return false;
            }

            return true;
          });

          return { ...c, children: filter_children };
        }

        return c;
      });
    }

    if (top_menu === "admin") {
      return adminMenu ?? [];
    }

    return [];
  }, [top_menu, userGeneralMenu, adminMenu, project_code]);

  return (
    <div
      className={`sidebar-total-body ${
        sidebarWidth !== expandedWidth ? "none" : "active"
      }`}
    >
      <Box
        sx={{
          height: `calc(100vh - ${headerHeight} - 100px)`,
          overflowY: "auto",
        }}
      >
        {sidebarWidth === expandedWidth && (
          <Stack
            spacing={4}
            sx={{
              flex: 1,
              minHeight: "0",
              padding: 2,
              bgcolor: "background.paper",
            }}
          >
            <RichTreeView
              items={selectMenu}
              apiRef={apiRef}
              slots={{ item: CustomTreeItem }}
              expandedItems={expandedItems}
              onExpandedItemsChange={handleExpandedItemsChange}
              onItemSelectionToggle={(event, itemIds, isSelected) => {
                const item = apiRef.current.getItem(itemIds);

                if (item?.path) {
                  navigate(item.path);
                }
              }}
              multiSelect={false} // Set to true if you need multi-selection
              checkboxSelection={false} // Enable if you want checkboxes
              sx={{
                "& .MuiTypography-root": {
                  paddingInline: "10px",
                },
              }}
            />
          </Stack>
        )}
      </Box>
      {sidebarWidth === expandedWidth && (
        <div style={{ padding: "8px 16px" }}>
          <HorizontalSolidLine />
        </div>
      )}
      <div className="siderBar-bottom-group">
        <div
          className={`siderBar-copy-right-group ${
            sidebarWidth !== expandedWidth ? "none" : "active"
          }`}
        >
          Copyright © 2024 Plant Module. <br />
          All rights reserved by Plant Module
        </div>
        <div
          className={`siderBar-control-btn-group ${
            sidebarWidth !== expandedWidth ? "none" : "active"
          }`}
        >
          <Button
            onClick={handleToggleNav}
            className="siderbar-menu-control-btn"
            startIcon={
              sidebarWidth === expandedWidth ? (
                <KeyboardDoubleArrowLeftRoundedIcon
                  style={{ color: "lightgray" }}
                /> // Any hex color
              ) : (
                <KeyboardDoubleArrowRightRoundedIcon
                  style={{ color: "lightgray" }}
                />
              ) // Any hex color
            }
          />

          <Button
            onClick={handleExpandClick}
            className={`siderbar-menu-list-control-btn ${
              sidebarWidth !== expandedWidth ? "none" : "active"
            }`}
            startIcon={
              expandedItems.length === 0 ? (
                <AddCircleOutlineRoundedIcon style={{ color: "lightgray" }} /> // Any hex color
              ) : (
                <RemoveCircleOutlineRoundedIcon
                  style={{ color: "lightgray" }}
                />
              ) // Any hex color
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

const AnimatedCollapse = animated(Collapse);

function TransitionComponent(props) {
  const { in: props_in } = props;
  const style = useSpring({
    to: {
      opacity: props_in ? 1 : 0,
      transform: `translate3d(0,${props_in ? 0 : 20}px,0)`,
    },
  });

  return <AnimatedCollapse style={style} {...props} />;
}

function CustomLabel({
  icon: Icon,
  expandable,
  selected,
  focused,
  children,
  ...other
}) {
  return (
    <TreeItem2Label
      {...other}
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "8px",
      }}
    >
      {Icon && (
        <Box
          component={Icon}
          className="labelIcon"
          color="inherit"
          sx={{ mr: 1, width: "24px", height: "24px" }}
        />
      )}

      <div
        className={`side-bar-menu-text ${
          expandable && focused ? "expandable-active" : ""
        } ${selected ? "selected-active" : ""}
         `}
        variant="body2"
        style={{ width: "calc(100% - 24px -24px)" }}
      >
        {children}
      </div>
      {/* {expandable && <DotIcon />} */}
    </TreeItem2Label>
  );
}
const expandedWidth = "18rem";
const collapsedWidth = "64px";
const headerHeight = "75px";

const getAllItemsWithChildrenItemIds = (MenuList) => {
  const itemIds = [];
  const registerItemId = (item) => {
    if (item.children?.length) {
      itemIds.push(item.id);
      item.children.forEach(registerItemId);
    }
  };

  MenuList.forEach(registerItemId);

  return itemIds;
};

// const isExpandable = (reactChildren) => {
//   if (Array.isArray(reactChildren)) {
//     return reactChildren.length > 0 && reactChildren.some(isExpandable);
//   }
//   return Boolean(reactChildren);
// };

//아이폰 변경하는 곳

const getIconFromFileType = (fileType, status = {}) => {
  if (fileType === "" || !fileType) {
    return;
  }

  const {
    expanded,
    selected,
    // focused, disabled
  } = status;

  switch (fileType) {
    case "sales":
      return () => SalesMenuIcon(expanded, selected);
    case "company":
      return () => CompanyMenuIcon(expanded, selected);
    case "material":
      return () => MaterialMenuIcon(expanded, selected);
    case "cost":
      return () => CostMenuIcon(expanded, selected);
    case "hr":
      return () => HRMenuIcon(expanded, selected);
    case "approve":
      return () => ApproveMenuIcon(expanded, selected);
    case "setting":
      return () => SettingMenuIcon(expanded, selected);
    case "payroll":
      return () => PayrollMenuIcon(expanded, selected);
    case "log":
      return () => LogMenuIcon(expanded, selected);
    case "users":
      return () => UsersMenuIcon(expanded, selected);
    case "report":
      return () => ReportMenuIcon(expanded, selected);
    case "dashboard":
      return () => DashboardMenuIcon(expanded, selected);
    default:
      return;
  }
};

const CustomTreeItem = React.forwardRef(function CustomTreeItem(props, ref) {
  const { id, itemId, label, disabled, children, ...other } = props;

  const {
    getRootProps,
    getContentProps,
    getIconContainerProps,
    getCheckboxProps,
    getLabelProps,
    getGroupTransitionProps,
    status,
    publicAPI,
  } = useTreeItem2({ id, itemId, children, label, disabled, rootRef: ref });

  const item = publicAPI.getItem(itemId);
  // const expandable = isExpandable(children);
  const icon = getIconFromFileType(item.fileType, status);

  const { ownerState, ...filteredOther } = other;

  return (
    <TreeItem2Provider itemId={itemId}>
      <div {...getRootProps(filteredOther)}>
        <CustomTreeItemContent
          className="side-bar-menu-container"
          {...getContentProps({
            className: clsx("content", {
              "Mui-expanded": status.expanded,
              "Mui-selected": status.selected,
              "Mui-focused": status.focused,
              "Mui-disabled": status.disabled,
            }),
          })}
        >
          <TreeItem2IconContainer {...getIconContainerProps()}>
            <TreeItem2Icon status={status} />
          </TreeItem2IconContainer>
          <TreeItem2Checkbox {...getCheckboxProps()} />
          <CustomLabel
            {...getLabelProps({
              icon,
              // expandable: expandable && status.expanded,
              expandable: status.expanded,
              selected: status.selected,
              focused: status.focused,
            })}
          />
        </CustomTreeItemContent>
        {children && <TransitionComponent {...getGroupTransitionProps()} />}
      </div>
    </TreeItem2Provider>
  );
});

// function DotIcon() {
//   return (
//     <Box
//       sx={{
//         width: 6,
//         height: 6,
//         borderRadius: "70%",
//         bgcolor: "warning.main",
//         display: "inline-block",
//         verticalAlign: "middle",
//         zIndex: 1,
//         mx: 1,
//       }}
//     />
//   );
// }
