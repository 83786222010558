import React, { useEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import dayjs from "dayjs";

const ColumnLineChart = ({
  chart_id = "dashboard_column_line_chart",
  width = "100%",
  height = "100%",
  category_field = "month",
  chart_data = sampleData,
  column_list = sampleData2,
  line_list = sampleData3,
  chart_stroke_color = "#666",
  info_text_color = "#666",
  unit = "",
  side_unit_lable = undefined,
  use_line_label = true,
  actual_mark_use = undefined,
  start_visible_length = 3,
  use_slide_bar = false,
}) => {
  useEffect(() => {
    if (chart_id) {
      let root = am5.Root.new(chart_id);
      root.setThemes([am5themes_Animated.new(root)]);

      let chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: false,
          panY: false,
          wheelX: "panX",
          wheelY: "zoomX",
          layout: root.verticalLayout,
          maxTooltipDistance: -1,
        })
      );

      chart.zoomOutButton.set("forceHidden", true);

      let tooltip = am5.Tooltip.new(root, {});

      chart.plotContainer.set("tooltipPosition", "pointer");
      chart.plotContainer.set("tooltipText", "a");
      chart.plotContainer.set("tooltip", tooltip);

      tooltip.label.adapters.add("text", function (text, target) {
        text = "";
        let i = 0;
        chart.series.each(function (series) {
          let tooltipDataItem = series.get("tooltipDataItem");

          if (tooltipDataItem) {
            if (i === 0) {
              text += "[bold]" + tooltipDataItem.get("categoryX") + "\n";
            }

            if (i !== 0) {
              text += "\n";
            }

            text +=
              "[" +
              series.get("stroke") +
              "]●[/] [width:100px]" +
              series.get("name") +
              ":[/] " +
              Number(
                (tooltipDataItem.get("valueY") ?? 0).toFixed(2)
              ).toLocaleString() +
              ` ${unit}`;
          }
          i++;
        });
        return text;
      });

      tooltip.get("background").setAll({
        stroke: am5.color(0x000000),
        strokeOpacity: 0.8,
        fill: am5.color(0xffffff),
        fillOpacity: 0.8,
      });

      // Add cursor
      // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
      let cursor = chart.set(
        "cursor",
        am5xy.XYCursor.new(root, {
          behavior: "zoomX",
        })
      );

      cursor.lineX.setAll({
        stroke: chart_stroke_color,
        strokeWidth: 1,
        strokeDasharray: [5, 5],
      });

      cursor.lineY.setAll({
        stroke: chart_stroke_color,
        strokeWidth: 1,
        strokeDasharray: [5, 5],
      });

      let legend = chart.children.push(
        am5.Legend.new(root, {
          centerX: am5.p50,
          x: am5.p50,
        })
      );

      legend.markers.template.setAll({
        width: 10,
        height: 10,
      });

      legend.labels.template.setAll({
        fontSize: "14px",
        fontWeight: "400",
        width: 90,
        fill: info_text_color,
      });

      // Create axes
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      let xRenderer = am5xy.AxisRendererX.new(root, {
        minGridDistance: 100,
        cellStartLocation: 0.1,
        cellEndLocation: 0.9,
        strokeOpacity: 1,
        strokeWidth: 1,
        stroke: chart_stroke_color,
      });

      let xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          categoryField: category_field,
          renderer: xRenderer,
        })
      );

      xRenderer.grid.template.setAll({
        location: 1,
        strokeOpacity: 0,
      });

      xRenderer.labels.template.setAll({
        paddingTop: 10,
        fontSize: "14px",
        // scale: 0.7,
        fill: info_text_color,
        // labelFrequency: 2,
      });

      xAxis.data.setAll(chart_data);

      let yRenderer = am5xy.AxisRendererY.new(root, {
        strokeOpacity: 1,
        strokeWidth: 1,
        stroke: chart_stroke_color,
        grid: {
          disabled: true,
        },
      });

      let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          strictMinMaxSelection: true,
          extraMin: 0.03,
          extraMax: 0.06,
          renderer: yRenderer,
        })
      );

      if (unit !== "" && side_unit_lable) {
        let yAxislabel = am5.Label.new(root, {
          rotation: 0,
          text: unit,
          y: am5.p50,
          centerX: am5.p50,
          fill: info_text_color,
        });

        yAxis.children.unshift(yAxislabel);
      }

      yRenderer.grid.template.setAll({
        strokeOpacity: 0,
      });

      yRenderer.labels.template.setAll({
        paddingRight: 10,
        fontSize: "14px",
        fill: info_text_color,
      });

      yAxis.set("numberFormat", "#,###");

      let paretoAxisRenderer = am5xy.AxisRendererY.new(root, {
        opposite: true,
        strokeOpacity: 1,
        strokeWidth: 1,
        stroke: chart_stroke_color,
      });

      let paretoAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          strictMinMaxSelection: true,
          extraMin: 0.03,
          extraMax: 0.06,
          renderer: paretoAxisRenderer,
          visible: use_line_label,
        })
      );

      paretoAxisRenderer.grid.template.setAll({
        strokeOpacity: 0,
      });

      paretoAxisRenderer.labels.template.setAll({
        paddingLeft: 10,
        fontSize: "14px",
        fill: info_text_color,
      });

      chart.yAxes.autoDispose = false;

      const makeSeries = (name, fieldName, color) => {
        let series = chart.series.push(
          am5xy.ColumnSeries.new(root, {
            name: name,
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: fieldName,
            categoryXField: category_field,
            fill: color,
            stroke: color,
            legendLabelText: name + " ({valueY.formatNumber('#,###.##')})",
            legendRangeLabelText: name,
          })
        );

        series.data.setAll(chart_data);

        series.appear();

        series.bullets.push(function () {
          return am5.Bullet.new(root, {
            locationY: 0,
            sprite: am5.Label.new(root, {
              fill: root.interfaceColors.get("alternativeText"),
              centerY: 0,
              centerX: am5.p50,
              populateText: true,
            }),
          });
        });

        if (start_visible_length > 0) {
          series.events.once("datavalidated", function (ev, target) {
            xAxis.zoomToIndexes(
              chart_data.length - start_visible_length,
              chart_data.length
            );
          });
        }

        legend.data.push(series);
      };

      const makeParetoSeries = (name, fieldName, color) => {
        let paretoSeries = chart.series.push(
          am5xy.LineSeries.new(root, {
            name: name,
            xAxis: xAxis,
            yAxis: paretoAxis,
            valueYField: fieldName,
            categoryXField: category_field,
            stroke: color,
            fill: color,
            // maskBullets: false,
            // tooltip: am5.Tooltip.new(root, {
            //   labelText: "[bold]{name}[/]\n{valueX.formatDate()}: {valueY}",
            // }),
            legendLabelText: name + " ({valueY.formatNumber('#,###.##')})",
            legendRangeLabelText: name,
          })
        );

        paretoSeries.strokes.template.setAll({
          strokeWidth: 2,
        });

        paretoSeries.bullets.push(function () {
          return am5.Bullet.new(root, {
            locationY: 1,
            sprite: am5.Circle.new(root, {
              radius: 3,
              stroke: paretoSeries.get("stroke"),
              strokeWidth: 2,
              fill: am5.color(0xffffff),
            }),
          });
        });

        paretoSeries.data.setAll(chart_data);

        if (start_visible_length > 0) {
          paretoSeries.events.once("datavalidated", function (ev, target) {
            xAxis.zoomToIndexes(
              chart_data.length - start_visible_length,
              chart_data.length
            );
          });
        }

        legend.data.push(paretoSeries);
      };

      column_list.forEach((com) => {
        const { name, field_name, color } = com;

        makeSeries(name, field_name, color);
      });

      line_list.forEach((com) => {
        const { name, field_name, color } = com;

        makeParetoSeries(name, field_name, color);
      });

      // Create axis ranges
      function createRange(value, endValue, color) {
        let rangeDataItem = yAxis.makeDataItem({
          value: value,
          endValue: endValue,
        });

        let range = yAxis.createAxisRange(rangeDataItem);

        if (endValue) {
          range.get("axisFill").setAll({
            fill: color,
            fillOpacity: 0.2,
            visible: true,
          });

          range.get("label").setAll({
            fill: am5.color(0xffffff),

            location: 1,
          });
        } else {
          range.get("label").setAll({
            fill: am5.color(0xffffff),
          });
        }

        range.get("grid").setAll({
          stroke: color,
          strokeOpacity: 1,
          location: 1,
        });
      }

      createRange(0, undefined, "rgba(224, 224, 224, 0.5)");

      const create_xAxis_list = (startValue, endValue) => {
        let rangeDataItem = xAxis.makeDataItem({
          category: startValue,
          endCategory: endValue,
        });

        xAxis.createAxisRange(rangeDataItem);

        rangeDataItem.get("grid").setAll({
          strokeOpacity: 1,
          stroke: "#A3CFFF",
          strokeWidth: 2,
          fill: info_text_color,
        });

        rangeDataItem.get("axisFill").setAll({
          fill: "rgba(163, 207, 255, 0.1)",
          visible: true,
        });

        rangeDataItem.get("label").setAll({
          visible: false,
        });
      };

      if (actual_mark_use && chart_data.length > 0) {
        create_xAxis_list(
          chart_data[0][category_field],
          dayjs().format("YY년 MM월")
        );
      }

      if (use_slide_bar) {
        let scrollbarX = am5.Scrollbar.new(root, {
          orientation: "horizontal",
        });

        chart.set("scrollbarX", scrollbarX);
      }

      chart.appear(1000, 100);

      return () => {
        root && root.dispose();
      };
    }
  }, [
    chart_id,
    chart_data,
    column_list,
    line_list,
    unit,
    use_line_label,
    category_field,
    actual_mark_use,
    side_unit_lable,
    start_visible_length,
    chart_stroke_color,
    info_text_color,
    use_slide_bar,
  ]);

  if (!chart_id) {
    return null;
  }

  return <div id={chart_id} style={{ width, height }} />;
};

export default ColumnLineChart;

const sampleData = [
  {
    month: "24년 01월",
    cash_in_plan: 10,
    cash_in_act: 10,
    cash_out_plan: 10,
    cash_out_act: 10,
    ncf_plan: 10,
    ncf_act: 10,
  },
  {
    month: "24년 02월",
    cash_in_plan: 15,
    cash_in_act: 15,
    cash_out_plan: 15,
    cash_out_act: 15,
    ncf_plan: 15,
    ncf_act: 15,
  },
  {
    month: "24년 03월",
    cash_in_plan: 20,
    cash_in_act: 20,
    cash_out_plan: 20,
    cash_out_act: 20,
    ncf_plan: 20,
    ncf_act: 20,
  },
];

const sampleData2 = [
  {
    name: "계획 Cash-In",
    field_name: "cash_in_plan",
    color: "#939597",
  },
  {
    name: "실적 Cash-In",
    field_name: "cash_in_act",
    color: "#EDF1FF",
  },
  {
    name: "계획 Cash-Out",
    field_name: "cash_out_plan",
    color: "#EFE1CE",
  },
  {
    name: "실적 Cash-Out",
    field_name: "cash_out_act",
    color: "#E0B589",
  },
];

const sampleData3 = [
  {
    name: "계획 NCF",
    field_name: "ncf_plan",
    color: "#EB7A25",
  },
  {
    name: "실적 NCF",
    field_name: "ncf_act",
    color: "#FDAC53",
  },
];
