import React from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { materialTableStyle, TableScrollbarStyle } from "@styles/muiThemes";

const DashboardTable = ({
  rows = [],
  columns = [],
  rowHeight = 28,
  height = 252,
}) => {
  return (
    <div className="dashboard-table-box" style={{ height }}>
      <DataGridPro
        rows={rows}
        columns={columns}
        rowHeight={rowHeight}
        columnHeaderHeight={rowHeight}
        sx={{
          ...materialTableStyle,
          ...TableScrollbarStyle,
        }}
        hideFooter
        disableColumnPinning
        disableColumnSelector
        disableDensitySelector
      />
    </div>
  );
};

export default DashboardTable;
