import React, { useMemo, useState } from "react";
import { Button } from "@mui/material";
import dayjs from "dayjs";
import ModalBody from "@components/ModalBody";
import DayPicker from "@components/DatePicker";
import { userPermAxios } from "@/utils/customAxios";
import { create_alert_fn } from "@/utils/createAlert";

const AdditionalModal = ({
  open,
  onClose = () => {},
  refresh = () => {},
  user_id,
}) => {
  const [additionalDate, setAdditionalDate] = useState(dayjs());
  const [additionalCount, setAdditionalCount] = useState(0);
  const [dubleClick, setDubleClick] = useState(true);

  const handleNumberInputValue = (e, setValue) => {
    let value = e.target.value;

    const isValidNumber = /^-?\d*(\.\d{0,1})?$/;

    if (isValidNumber.test(value)) {
      if (value === "-") {
        setValue(value);
      } else {
        setValue(Number(value));
      }
    }
  };

  const button_disabled = useMemo(() => {
    if (additionalCount === 0 || additionalCount === "") {
      return true;
    }

    return false;
  }, [additionalCount]);

  const createAdditional = async () => {
    setDubleClick(false);

    let msgStatus = {
      status: "success",
      message: "",
    };
    try {
      const requestCreateAnnualLeaveCount = await userPermAxios.post(
        `/dayoff/create_admin_additional_leave_count`,
        {
          user_id,
          additionalCount,
          additionalYear: additionalDate.format("YYYY"),
          additionalMonth: additionalDate.format("MM"),
          additionalDay: additionalDate.format("DD"),
        }
      );

      const { success, message } = requestCreateAnnualLeaveCount;

      if (success) {
        msgStatus.status = "success";
      } else {
        msgStatus.status = "warning";
      }

      msgStatus.message = message;
    } catch (err) {
      msgStatus.status = "warning";
      if (err.response && err?.response?.data?.message) {
        msgStatus.message = err?.response?.data?.message;
      }
    } finally {
      setDubleClick(true);

      create_alert_fn(msgStatus.status, msgStatus.message);

      if (msgStatus.status === "success") {
        refresh();
        onClose();
      }
    }
  };

  const confirmAdditional = () => {
    if (!dubleClick) {
      return;
    }

    return create_alert_fn(
      "info",
      "Are you sure you want to create annual leave count?",
      createAdditional,
      true
    );
  };

  return (
    <ModalBody
      open={open}
      onClose={onClose}
      modalWidth={300}
      title="Add Annual Leave Count"
      disableEscapeKeyDown={!dubleClick}
    >
      <div className="work-policy-modal-contents-box">
        <div className="work-policy-modal-detail-contents-box">
          <div
            className="work-policy-modal-time-picker-detail"
            style={{ width: "100%" }}
          >
            <div className="work-policy-modal-detail-contents-title">
              Additional Date
            </div>
            <DayPicker
              width="100%"
              value={additionalDate}
              onChange={(e) => setAdditionalDate(e)}
            />
          </div>

          <div
            className="work-policy-modal-time-picker-detail"
            style={{ width: "100%" }}
          >
            <div className="work-policy-modal-detail-contents-title">
              Additional Count
            </div>
            <NumberInput
              width="100%"
              onChange={handleNumberInputValue}
              value={additionalCount}
              setValue={setAdditionalCount}
            />
          </div>
        </div>
      </div>
      <div className="work-policy-modal-btn-box">
        <Button
          role={undefined}
          variant="outlined"
          tabIndex={-1}
          onClick={onClose}
          className="work-policy-modal-cancel-btn"
        >
          Cancel
        </Button>
        <Button
          className="work-policy-modal-update-btn"
          onClick={confirmAdditional}
          disabled={button_disabled}
        >
          Save
        </Button>
      </div>
    </ModalBody>
  );
};

export default AdditionalModal;

const NumberInput = ({
  value,
  setValue,
  onChange,
  disabled = false,
  width = "100%",
}) => {
  return (
    <input
      className="work-policy-input"
      onChange={(e) => onChange(e, setValue)}
      value={value}
      disabled={disabled}
      style={{
        width,
        boxSizing: "border-box",
        marginTop: 4,
        height: 40,
        // borderColor: "rgb(118, 118, 118)",
      }}
    />
  );
};
