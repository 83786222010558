import React, { useEffect, useMemo, useState } from "react";
import Button from "@mui/material/Button";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import "./signatureSetting.scss";

const SignatureSetting = ({
  initialSignature,
  signatureFile,
  setSigntureFile,
}) => {
  const [preview, setPreview] = useState(null); // 미리 보기 이미지 URL

  useEffect(() => {
    if (signatureFile) {
      const file = signatureFile[0]; // 첨부된 파일 가져오기
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          setPreview(e.target.result); // 미리 보기 이미지 URL 설정
        };
        reader.readAsDataURL(file); // 파일을 Data URL로 읽기
      } else {
        setPreview(null); // 파일이 없으면 미리 보기 초기화
      }
    } else {
      setPreview(null);
    }
  }, [signatureFile]);

  const signatureImg = useMemo(() => {
    if (preview) {
      return preview;
    }

    if (initialSignature) {
      return initialSignature;
    }

    return null;
  }, [initialSignature, preview]);

  return (
    <div className="signature-body">
      <div className="signature-title-box">
        <div className="signature-title">Registered Signature</div>
      </div>
      <div className="signature-contents-box">
        {signatureImg && <img className="signture-img" src={signatureImg} />}
        <Button
          component="label"
          role={undefined}
          variant="outlined"
          tabIndex={-1}
          startIcon={<FileUploadOutlinedIcon fontSize="small" />}
          sx={{
            fontSize: 12,
            textTransform: "none",
            width: 344,
            background: "#FFF",
          }}
        >
          Upload File
          <input
            type="file"
            multiple={false}
            accept="image/*"
            style={{
              clip: "rect(0 0 0 0)",
              clipPath: "inset(50%)",
              height: 1,
              overflow: "hidden",
              position: "absolute",
              bottom: 0,
              left: 0,
              whiteSpace: "nowrap",
              width: 1,
            }}
            onChange={(e) => {
              const fileCount = e.target.files.length;
              if (fileCount > 0) {
                setSigntureFile(e.target.files);
              } else {
                setSigntureFile(null);
              }
            }}
          />
        </Button>
      </div>
    </div>
  );
};

export default SignatureSetting;
