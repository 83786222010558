import React, { useState, useMemo, useEffect } from "react";
import { Modal, Box, Button } from "@mui/material";
import { useQuery } from "react-query";
import dayjs from "dayjs";
import LabelInput from "@components/LabelInput";
import LabelSelect from "@components/LabelSelect";
import OrgSelectBtn from "@components/orgSettingModal/OrgSelectBtn";
import SwicthBtn from "@components/SwicthBtn";
import useWindowDimensions from "@/hooks/useWindowDimensions";
import useRefreshReactQuery from "@/hooks/useRefreshReactQuery";
import { userPermAxios } from "@utils/customAxios";
import { create_alert_fn } from "@utils/createAlert";
import { timeZoneList, timeFormatList, countries } from "@utils/userTimeZone";
import { validateEmail } from "@utils/valueRegex";
import "./userPermTable.scss";
import DayPicker from "../DatePicker";

const UserCreateModal = ({ open, setOpen }) => {
  const { height } = useWindowDimensions();
  const [username, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("Taiwan");
  const [language, setLanguage] = useState("en");
  const [timeZone, setTimeZone] = useState(
    "(UTC+07:00) Bangkok, Hanoi, Jakarta"
  );
  const [timeFormat, setTimeFormat] = useState("YYYY. MM. DD HH:mm");
  const [dubleClick, setDubleClick] = useState(true);
  const [orgValue, setOrgValue] = useState("");
  const [jobPositionValue, setJobPositionValue] = useState("");
  const [jobGradeValue, setJobGradeValue] = useState("");
  const [joiningDate, setJoiningDate] = useState(dayjs());
  const [birthDate, setBirthDate] = useState(dayjs());
  const [bankAccount, setBankAccount] = useState("");
  const [directEmployment, setDirectEmployment] = useState(true);

  const query_hr_setting_list = useQuery(
    "hr_setting_list",
    () => {
      return query_fetch_data();
    },
    {
      initialData: {
        orgList: [],
        jobPositionList: [],
        jobGradeList: [],
      },
    }
  );

  const [refresh] = useRefreshReactQuery("hr_setting_list");

  const { data } = query_hr_setting_list;

  const { orgList, jobPositionList, jobGradeList } = data;

  useEffect(() => {
    setUserName("");
    setEmail("");
    setPhone("");
    setCountry("Taiwan");
    setLanguage("en");
    setTimeZone("(UTC+07:00) Bangkok, Hanoi, Jakarta");
    setTimeFormat("YYYY. MM. DD HH:mm");
  }, [open]);

  const closeFn = () => {
    setOpen(false);
  };

  const emailCheck = useMemo(() => {
    if (email.length === 0) {
      return null;
    }

    return validateEmail(email);
  }, [email]);

  const button_disabled = useMemo(() => {
    if (
      username.length > 0 &&
      email.length > 0 &&
      phone.length > 0 &&
      country.length > 0 &&
      language.length > 0 &&
      timeZone.length > 0 &&
      timeFormat.length > 0 &&
      bankAccount.length > 0 &&
      !emailCheck
    ) {
      if (
        orgValue.length === 0 ||
        jobPositionValue.length === 0 ||
        jobGradeValue.length === 0
      ) {
        return true;
      }
      return false;
    }

    return true;
  }, [
    username,
    email,
    phone,
    country,
    language,
    timeZone,
    timeFormat,
    emailCheck,
    orgValue,
    jobPositionValue,
    jobGradeValue,
    bankAccount,
  ]);

  const createUser = async () => {
    try {
      const requestCreateUser = await userPermAxios.post("/create-user", {
        username,
        email,
        phone,
        country,
        language,
        timeZone,
        timeFormat,
        orgValue,
        jobPositionValue,
        jobGradeValue,
        joiningDate: joiningDate.format("YYYY-MM-DD"),
        birthDate: birthDate.format("YYYY-MM-DD"),
        bankAccount,
        directEmployment,
      });

      const { success } = requestCreateUser;

      if (success) {
        create_alert_fn("success", "Successfully Create New User", () => {});
        refresh();
        return closeFn();
      }
    } catch (err) {
      let message = "";

      if (err.response && err?.response?.data?.message) {
        message = err?.response?.data?.message;
      }

      create_alert_fn("warning", message, () => {});
    } finally {
      setDubleClick(true);
    }
  };

  const openAlert = () => {
    if (!dubleClick) return;
    setDubleClick(false);

    create_alert_fn(
      "info",
      "Are you sure you want to create a user?",
      async () => {
        await createUser();
      },
      true
    );
  };

  return (
    <Modal
      open={open}
      onClose={closeFn}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="create-user-modal-total-body">
          <div
            className="create-user-modal-add-input-box"
            style={{ maxHeight: height * 0.7, overflow: "auto" }}
          >
            <LabelInput
              label="User Name"
              value={username}
              setValue={setUserName}
            />
            <LabelInput
              label="Email"
              value={email}
              setValue={setEmail}
              error={emailCheck === null ? false : true}
              helperText={emailCheck}
            />
            <LabelInput label="Phone" value={phone} setValue={setPhone} />
            <LabelSelect
              label="Country"
              value={country}
              setValue={setCountry}
              list={countries.map((c) => ({ name: c.label, value: c.label }))}
            />
            <LabelSelect
              label="Language"
              value={language}
              setValue={setLanguage}
              list={[
                { value: "en", name: "English" },
                { value: "th", name: "เกาหลี" },
              ]}
            />
            <LabelSelect
              label="Time Format"
              value={timeZone}
              setValue={setTimeZone}
              list={timeZoneList.map((c) => ({ name: c.name, value: c.name }))}
            />
            <LabelSelect
              label="Date Format"
              value={timeFormat}
              setValue={setTimeFormat}
              list={timeFormatList}
              helperText={
                !timeFormat && timeFormat === ""
                  ? null
                  : `ex) ${dayjs().format(timeFormat)}`
              }
            />
            <LabelSelect
              label="Date Format"
              value={timeFormat}
              setValue={setTimeFormat}
              list={timeFormatList}
              helperText={
                !timeFormat && timeFormat === ""
                  ? null
                  : `ex) ${dayjs().format(timeFormat)}`
              }
            />

            <div className="create-user-modal-contents-box">
              <div className="create-user-modal-contents-title">
                Date of Joining
              </div>
              <DayPicker
                value={joiningDate}
                onChange={(e) => {
                  setJoiningDate(e);
                }}
                width="100%"
              />
            </div>

            <div className="create-user-modal-contents-box">
              <div className="create-user-modal-contents-title">
                Date of Birth
              </div>
              <DayPicker
                value={birthDate}
                onChange={(e) => {
                  setBirthDate(e);
                }}
                width="100%"
              />
            </div>

            <LabelInput
              label="Bank Account"
              value={bankAccount}
              setValue={setBankAccount}
              // error={emailCheck === null ? false : true}
              // helperText={emailCheck}
            />

            <div className="create-user-modal-contents-box">
              <div className="create-user-modal-contents-title">
                Organization
              </div>
              <OrgSelectBtn
                list={orgList}
                value={orgValue}
                setValue={setOrgValue}
              />
            </div>

            <LabelSelect
              label="Job Position"
              value={jobPositionValue}
              setValue={setJobPositionValue}
              list={jobPositionList.map((c) => {
                return { name: c.name, value: c.id };
              })}
            />

            <LabelSelect
              label="Job Grade"
              value={jobGradeValue}
              setValue={setJobGradeValue}
              list={jobGradeList.map((c) => {
                return { name: c.name, value: c.id };
              })}
            />

            <div
              className="create-user-modal-contents-box"
              style={{
                justifyContent: "space-between",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div className="create-user-modal-contents-title">
                Direct Employment
              </div>
              <SwicthBtn
                checked={directEmployment}
                onClick={() => setDirectEmployment((prev) => !prev)}
              />
            </div>
          </div>

          <div className="create-user-modal-control-box">
            <Button
              onClick={openAlert}
              disabled={button_disabled}
              className="create-user-modal-submit-btn"
            >
              Submit
            </Button>
            <Button
              role={undefined}
              variant="outlined"
              tabIndex={-1}
              onClick={closeFn}
              className="create-user-modal-cancle-btn"
            >
              Cancel
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default UserCreateModal;

const query_fetch_data = async () => {
  const result = {
    orgList: [],
    jobPositionList: [],
    jobGradeList: [],
  };

  try {
    const requestHRSettingList = await userPermAxios.get(
      `/hr/get_hr_setting_list`
    );

    const { success, data } = requestHRSettingList;

    if (success) {
      const { orgList = [], jobPositionList = [], jobGradeList = [] } = data;

      result.orgList = orgList;
      result.jobPositionList = jobPositionList;
      result.jobGradeList = jobGradeList;
    }
  } catch (err) {
    console.log(err);
  } finally {
    return result;
  }
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  borderRadius: "16px",
  border: "1px solid var(--Gray-200, #E4E7EC)",
  background: "var(--White, #FFF)",
  boxShadow:
    "0px 8px 16px 0px rgba(0, 0, 0, 0.14), 0px 0px 2px 0px rgba(0, 0, 0, 0.12)",
};
