import React, { useState } from "react";
import { useQuery } from "react-query";
import { useRecoilValue } from "recoil";
import { MaterialApprovalModalStatus } from "@/atoms/modalStatus";
import { Tooltip } from "@mui/material";
import ModalBody from "@components/ModalBody";
import FileDownloadBtn from "@/components/FileDownloadBtn";
import LabelTextArea from "@/components/LabelTextArea";
import ApprovalCards from "./ApprovalCards";
import CarbonCopyCards from "./CarbonCopyCards";
import MaterialItem from "./MaterialItem";
import UserInfoTooltip from "./UserInfoTooltip";
import useRefreshReactQuery from "@/hooks/useRefreshReactQuery";
import { userPermAxios } from "@/utils/customAxios";
import { create_alert_fn } from "@/utils/createAlert";

const MaterialApprovalModal = () => {
  const [dubleClick, setDubleClick] = useState(true);
  const {
    id,
    open,
    onClose,
    modal_type,
    table_name,
    count_query_key,
    list_refresh = () => {},
  } = useRecoilValue(MaterialApprovalModalStatus);

  const [approval_count_refresh] = useRefreshReactQuery(count_query_key);

  const query_approval_detail_data = useQuery(
    ["material_approval_detail_data", id, modal_type, table_name],
    () => {
      return query_fetch_data(id, modal_type, table_name);
    }
  );

  const { data = {}, isLoading } = query_approval_detail_data;

  const {
    approval_date = "",
    approval_title = "",
    username = "",
    org_setting_name = "",
    lv_name = "",
    fn_name = "",
    project_lead = "",
    detail_contents = {},
    approval_line_list = [],
    carborn_copy_list = [],
    attachment_list = [],
    action_button_disabled = true,

    remark = "",
  } = data;

  const requestApprovalAction = async (action) => {
    setDubleClick(false);

    let msgStatus = {
      status: "success",
      message: "",
    };

    try {
      const requestUpdateApprovalStatus = await userPermAxios.post(
        `/approval/update_material_approval_status`,
        {
          id,
          action,
          modal_type,
        }
      );

      const { success, message } = requestUpdateApprovalStatus;

      if (success) {
        msgStatus.status = "success";
      } else {
        msgStatus.status = "warning";
      }

      msgStatus.message = message;
    } catch (err) {
      msgStatus.status = "warning";
      if (err.response && err?.response?.data?.message) {
        msgStatus.message = err?.response?.data?.message;
      }
    } finally {
      setDubleClick(true);

      create_alert_fn(msgStatus.status, msgStatus.message);

      if (msgStatus.status === "success") {
        approval_count_refresh();
        list_refresh();
        onClose();
      }
    }
  };

  const approval_action = (action) => {
    if (!dubleClick) {
      return;
    }
    let msg;

    if (action === "Canceled") {
      msg = "Are you sure you want to cancel?";
    }

    if (action === "Rejected") {
      msg = "Are you sure you want to reject?";
    }

    if (action === "Approved") {
      msg = "Are you sure you want to approve?";
    }

    if (!msg) {
      return;
    }

    return create_alert_fn(
      "info",
      msg,
      () => requestApprovalAction(action),
      true
    );
  };

  if (isLoading) {
    return null;
  }

  return (
    <ModalBody
      open={open}
      onClose={onClose}
      title={`${modalTitle?.[table_name]?.name ?? ""} Approval`}
      disableEscapeKeyDown={true}
      modalHeight={800}
      modalWidth={840}
    >
      <div className="approval-modal-contents-body">
        <div className="approval-modal-contents-box">
          <div className="approval-modal-info-body">
            <div className="approval-modal-info-title-box">
              <div className="approval-modal-info-title">Sales Lead</div>
            </div>
            <div className="approval-modal-info-contents-box">
              <div className="approval-modal-info-contents">{project_lead}</div>
            </div>
          </div>
          <Tooltip
            title={
              <UserInfoTooltip
                title="Approver Info"
                username={username}
                org_setting_name={org_setting_name}
                lv_name={lv_name}
                fn_name={fn_name}
              />
            }
            followCursor
            arrow
          >
            <div className="approval-modal-info-body">
              <div className="approval-modal-info-title-box">
                <div className="approval-modal-info-title">Approver</div>
              </div>
              <div className="approval-modal-info-contents-box">
                <div className="approval-modal-info-contents">{username}</div>
              </div>
            </div>
          </Tooltip>
          <div className="approval-modal-info-body">
            <div className="approval-modal-info-title-box">
              <div className="approval-modal-info-title">Approval Date</div>
            </div>
            <div className="approval-modal-info-contents-box">
              <div className="approval-modal-info-contents">
                {approval_date}
              </div>
            </div>
          </div>
          <div className="approval-modal-info-body">
            <div className="approval-modal-info-title-box">
              <div className="approval-modal-info-title">Approval Item</div>
            </div>
            <div className="approval-modal-info-contents-box">
              <div className="approval-modal-info-contents">
                {approval_title}
              </div>
            </div>
          </div>

          <MaterialItem table_name={table_name} items={detail_contents} />
          <div className="approval-modal-column-body">
            <div
              className="approval-modal-item-title-box"
              style={{ height: "auto" }}
            >
              <div className="approval-modal-item-title">Remark</div>
            </div>
            <div className="approval-modal-row-wrop-body">
              <LabelTextArea value={remark} readOnly={true} disabled={true} />
            </div>
          </div>
          {attachment_list.length > 0 && (
            <div className="approval-modal-column-body">
              <div className="approval-modal-item-title-box">
                <div className="approval-modal-item-title">Attachment</div>
              </div>
              <div className="approval-modal-row-wrop-body">
                {attachment_list.map((c) => {
                  const { id: attachment_id, filename, url } = c;

                  return (
                    <FileDownloadBtn
                      url={url}
                      filename={filename}
                      key={attachment_id}
                    />
                  );
                })}
              </div>
            </div>
          )}

          <div className="approval-modal-vertical-line" />

          <div className="approval-modal-column-body">
            <div className="approval-modal-item-title-box">
              <div className="approval-modal-item-title">Approval Line</div>
            </div>
            <div className="approval-modal-row-scroll-body">
              <ApprovalCards approval_list={approval_line_list} />
            </div>
          </div>

          {carborn_copy_list.length > 0 && (
            <div className="approval-modal-column-body">
              <div className="approval-modal-item-title-box">
                <div className="approval-modal-item-title">Carbon Copy</div>
              </div>
              <div className="approval-modal-row-scroll-body">
                <CarbonCopyCards carbon_copy_list={carborn_copy_list} />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="approval-modal-btn-body">
        {buttonGroups(
          modal_type,
          onClose,
          action_button_disabled,
          approval_action
        )}
      </div>
    </ModalBody>
  );
};

export default MaterialApprovalModal;

const query_fetch_data = async (id, modal_type, table_name) => {
  let result = {
    contents_table_name: "",
    approval_date: "",
    approved_status: "",
    username: "",
    org_setting_name: "",
    lv_name: "",
    fn_name: "",
    approval_title: "",
    project_lead: "",
    detail_contents: {},
    approval_line_list: [],
    carborn_copy_list: [],
    attachment_list: [],
    remark: "",
    action_button_disabled: true,
  };

  try {
    const requestDetailApprovalData = await userPermAxios.get(
      `/approval/get_detail_material_approval?id=${id}&modal_type=${modal_type}&table_name=${table_name}`
    );
    const { success, data } = requestDetailApprovalData;
    if (success) {
      result = { ...data };
    }
  } catch (err) {
  } finally {
    return result;
  }
};

const buttonGroups = (
  modal_type,
  onClose = () => {},
  disabled = false,
  onClick = () => {}
) => {
  if (modal_type === "approval") {
    return (
      <>
        <button
          className="approval-modal-btn color"
          onClick={() => onClick("Approved")}
          disabled={disabled}
        >
          <div className="approval-modal-btn-label">Approval</div>
        </button>
        <button
          className="approval-modal-btn normal"
          onClick={() => onClick("Rejected")}
          disabled={disabled}
        >
          <div className="approval-modal-btn-label">Rejection</div>
        </button>
        <button className="approval-modal-btn normal" onClick={onClose}>
          <div className="approval-modal-btn-label">Close</div>
        </button>
      </>
    );
  }
  if (modal_type === "carbon_copy") {
    return (
      <button className="approval-modal-btn normal" onClick={onClose}>
        <div className="approval-modal-btn-label">Close</div>
      </button>
    );
  }
  if (modal_type === "draft") {
    return (
      <>
        {/* <button
          className="approval-modal-btn color"
          onClick={() => onClick("Canceled")}
          disabled={disabled}
        >
          <div className="approval-modal-btn-label">Draft Cancel</div>
        </button> */}
        <button className="approval-modal-btn normal" onClick={onClose}>
          <div className="approval-modal-btn-label">Close</div>
        </button>
      </>
    );
  }

  return;
};

const modalTitle = {
  purchase_request: {
    name: "Purchase Requisition",
  },
  request_for_quotation: {
    name: "Request for Quotation",
  },
  purchase_order: {
    name: "Purchase Order",
  },
  packing_list: {
    name: "Packing List",
  },
  material_receive: {
    name: "Material Received",
  },
  material_release: {
    name: "Material Release",
  },
  product_export: {
    name: "Product Export",
  },
  inventory_quantity_control: {
    name: "Inventory Quantity Control",
  },
};
