import React, { useState } from "react";
import { useQuery } from "react-query";
import Btn from "@components/CustomIconBtn";
import ProjectListModal from "./ProjectListModal";
import TransferHistoryModal from "./TransferHistoryModal";
import {
  UserIcon,
  EmailIcon,
  CalendarIcon,
  IdCardIcon,
  EarthIcon,
  PhoneIcon,
  BankIcon,
  OrganizationIcon,
  JobPositionIcon,
  JobGradeIcon,
  TransferHistoryIcon,
  ProjectListIcon,
} from "./Icons";
import "./myInfo.scss";
import { userPermAxios } from "@/utils/customAxios";
import VerticalSolidLine from "@/components/VerticalSolidLine";

const MyInfo = () => {
  const query_my_information_data = useQuery("my_information_data", () => {
    return query_fetch_data();
  });

  const { data = {}, isLoading } = query_my_information_data;

  const {
    username,
    user_id,
    email,
    joining_date,
    resignation_date,
    birth_date,
    country,
    phone,
    bank_account,
    department,
    job_position,
    job_function,
    project_list,
    transfer_history,
  } = data;

  const [projectListToggle, setProjectListToggle] = useState(false);
  const [historyToggle, setHistoryToggle] = useState(false);

  const handleModal = (setValue) => {
    setValue((prev) => !prev);
  };

  const { my_info_left, my_info_right } = {
    my_info_left: [
      {
        icon: <UserIcon />,
        info_title: "Name",
        info_value: "",
        info_valuse: [
          { info_values_title: "User Name", info_values_value: username },
          { info_values_title: "User Number", info_values_value: user_id },
        ],
      },
      {
        icon: <EmailIcon />,
        info_title: "Email",
        info_value: email,
        info_valuse: [],
      },
      {
        icon: <CalendarIcon />,
        info_title: "Employment Info",
        info_value: "",
        info_valuse: [
          {
            info_values_title: "Date of Joining",
            info_values_value: joining_date,
          },
          {
            info_values_title: "Date of Resignation",
            info_values_value: resignation_date,
          },
        ],
      },
    ],
    my_info_right: [
      {
        icon: <IdCardIcon />,
        info_title: "Date of Birth",
        info_value: birth_date,
        info_valuse: [],
      },
      {
        icon: <EarthIcon />,
        info_title: "Country",
        info_value: country,
        info_valuse: [],
      },
      {
        icon: <PhoneIcon />,
        info_title: "Phone Number",
        info_value: phone,
        info_valuse: [],
      },
      {
        icon: <BankIcon />,
        info_title: "Bank Account",
        info_value: bank_account,
        info_valuse: [],
      },
    ],
  };

  if (isLoading) {
    return null;
  }

  return (
    <div className="my-info-body">
      {projectListToggle && (
        <ProjectListModal
          open={projectListToggle}
          rows={project_list}
          onClose={() => handleModal(setProjectListToggle)}
        />
      )}
      {historyToggle && (
        <TransferHistoryModal
          open={historyToggle}
          rows={transfer_history}
          onClose={() => handleModal(setHistoryToggle)}
        />
      )}
      <div className="my-info-top-body">
        <div className="my-info-title-box">
          <div className="my-info-contents-title">Basic Information</div>
        </div>
        <div className="my-info-contents-box2">
          <div className="my-info-contents-detail-box">
            {create_my_info_contest(my_info_left)}
          </div>
          <VerticalSolidLine />
          <div className="my-info-contents-detail-box">
            {create_my_info_contest(my_info_right)}
          </div>
        </div>
      </div>
      <div className="my-info-bottom-body">
        <div className="my-info-bottom-left-body">
          <div className="my-info-top-body">
            <div className="my-info-title-box">
              <div className="my-info-contents-title">
                Employment Contract Information
              </div>
            </div>
            <div className="my-info-contents-box">
              <div className="my-info-contents-detail-left-box">
                <div className="my-info-contents-left-title-box">
                  <CalendarIcon />
                  <div className="my-info-contents-left-title">
                    Contract Duration
                  </div>
                </div>
              </div>
              <div className="my-info-contents-detail-box">
                <div className="my-info-contents-right-title-box">
                  <div className="my-info-contents-right-title">
                    {`${joining_date} ~ ${
                      resignation_date === "-"
                        ? "Full-time Employee"
                        : resignation_date
                    }`}
                  </div>
                </div>

                {/* <div className="my-info-contents-right-title-box">
                  <div className="my-info-contents-right-title">Department</div>
                </div> */}
              </div>
            </div>
          </div>

          <div className="my-info-title-box">
            <div className="my-info-contents-title">Assigned Project</div>
            <div>
              <Btn
                label="Project List"
                width={142}
                icon={<ProjectListIcon />}
                onClick={() => handleModal(setProjectListToggle)}
              />
            </div>
          </div>
        </div>
        <div className="my-info-bottom-right-body">
          <div className="my-info-title-box">
            <div className="my-info-contents-title">HR Information</div>
            <div>
              <Btn
                label="Transfer History"
                width={160}
                icon={<TransferHistoryIcon />}
                onClick={() => handleModal(setHistoryToggle)}
              />
            </div>
          </div>
          <div className="my-info-contents-box">
            <div className="my-info-contents-detail-left-box">
              <div className="my-info-contents-left-title-box">
                <OrganizationIcon />
                <div className="my-info-contents-left-title">Department</div>
              </div>
              <div className="my-info-contents-left-title-box">
                <JobPositionIcon />
                <div className="my-info-contents-left-title">Job Position</div>
              </div>
              <div className="my-info-contents-left-title-box">
                <JobGradeIcon />
                <div className="my-info-contents-left-title">Job Grade</div>
              </div>
            </div>
            <div className="my-info-contents-detail-box">
              <div className="my-info-contents-right-title-box">
                <div className="my-info-contents-right-title">{department}</div>
              </div>

              <div className="my-info-contents-right-title-box">
                <div className="my-info-contents-right-title">
                  {job_position}
                </div>
              </div>
              <div className="my-info-contents-right-title-box">
                <div className="my-info-contents-right-title">
                  {job_function}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyInfo;

const query_fetch_data = async () => {
  let result = {
    username: "-",
    user_id: "-",
    email: "-",
    joining_date: "-",
    resignation_date: "-",
    birth_date: "-",
    country: "-",
    phone: "-",
    bank_account: "-",
    department: "-",
    job_position: "-",
    job_function: "-",
    project_list: [],
    transfer_history: [],
  };

  try {
    const requestMyInformation = await userPermAxios.get(
      "/hr/get_my_information"
    );

    const { success, data } = requestMyInformation;
    if (success) {
      result = { ...data };
    }
  } catch (err) {
  } finally {
    return result;
  }
};

const create_my_info_contest = (arr = []) => {
  return arr.map((c) => {
    const { icon, info_title, info_value, info_valuse } = c;

    return (
      <div className="my-info-contents-detail" key={info_title}>
        <div className="my-info-contents-detail-title-box">
          {icon}
          <div className="my-info-contents-detail-title">{info_title}</div>
        </div>
        {info_valuse.length === 0 ? (
          <div className="my-info-contents-detail-value-box">
            <div className="my-info-contents-detail-value">{info_value}</div>
          </div>
        ) : (
          <div className="my-info-contents-detail-value-box">
            {info_valuse.map((c2) => {
              const { info_values_title, info_values_value } = c2;

              return (
                <div
                  className="my-info-contents-detail-values-boxs"
                  key={info_values_title}
                >
                  <div className="my-info-contents-detail-values-title-box">
                    <div className="my-info-contents-detail-values-title">
                      {info_values_title}
                    </div>
                  </div>
                  <div className="my-info-contents-detail-value">
                    {info_values_value}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  });
};
