import React from "react";

const Btn = ({
  icon,
  label,
  onClick = () => {},
  width,
  labelInput,
  onChange = () => {},
  disabled = false,
  borderColor,
  textColor,
}) => {
  const widthStyle = width ? { width, flex: "none" } : {};
  const borderStyle = borderColor ? { borderColor: borderColor } : {};
  const labelStyle = textColor ? { color: textColor } : {};

  if (labelInput) {
    return (
      <>
        <label
          className="company-setting-btn"
          style={widthStyle}
          htmlFor="company-setting-btn-input"
        >
          {icon && icon}
          {label && <div className="company-setting-btn-label">{label}</div>}
        </label>
        <input
          id="company-setting-btn-input"
          type="file"
          style={{
            position: "absolute",
            opacity: 0, // input을 투명하게 만듭니다
            width: "1px",
            height: "1px",
          }}
          onClick={(event) => {
            event.target.value = null;
          }}
          onChange={(e) => {
            if (e.target.files[0]) {
              onChange(e.target.files[0]);
            }
          }}
          disabled={disabled}
        />
      </>
    );
  }
  return (
    <button
      className="company-setting-btn"
      onClick={onClick}
      style={{ ...widthStyle, ...borderStyle }}
      disabled={disabled}
    >
      {icon && icon}
      {label && (
        <div className="company-setting-btn-label" style={labelStyle}>
          {label}
        </div>
      )}
    </button>
  );
};

export default Btn;
