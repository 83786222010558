import React, { useState, useMemo } from "react";
import { useRecoilState } from "recoil";
import { PwSettingModalStatus } from "@atoms/modalStatus";
import { Modal, IconButton, Button } from "@mui/material";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import PwInput from "@components/PwInput";
import CustomCheckBox from "@components/CustomCheckBox";
import { validatePassword } from "@utils/valueRegex";
import { create_alert_fn } from "@utils/createAlert";
import { userPermAxios } from "@utils/customAxios";
import "./changePasswordModal.scss";

const ChangePasswordModal = () => {
  const [pwSettingModalStatus, setPwSettingModalStatus] =
    useRecoilState(PwSettingModalStatus);

  const [currentPW, setCurrentPW] = useState("");
  const [newPW, setNewPW] = useState("");
  const [repeatNewPW, setRepeatNewPW] = useState("");
  const [checkValue, setCheckValue] = useState(false);
  const [dubleClick, setDubleClick] = useState(true);

  const closeModal = () => {
    setPwSettingModalStatus((prev) => ({ ...prev, open: false }));
    setCurrentPW("");
    setNewPW("");
    setRepeatNewPW("");
    setCheckValue(false);
    setDubleClick(true);
  };

  const newPWCheck = useMemo(() => {
    if (newPW.length === 0) {
      return null;
    }

    if (currentPW === newPW) {
      return "The new password and the current password are the same.";
    }

    return validatePassword(newPW);
  }, [newPW]);

  const repeatPwCheck = useMemo(() => {
    if (repeatNewPW.length === 0) {
      return null;
    }

    if (repeatNewPW === newPW) {
      return null;
    }

    return "The passwords do not match.";
  }, [repeatNewPW, newPW]);

  const button_disabled = useMemo(() => {
    if (
      currentPW.length > 0 &&
      newPW.length > 0 &&
      repeatNewPW.length > 0 &&
      !repeatPwCheck &&
      !repeatPwCheck
      // && checkValue
    ) {
      return false;
    }
    return true;
  }, [
    currentPW,
    newPW,
    repeatNewPW,
    repeatPwCheck,
    repeatPwCheck,
    // checkValue
  ]);

  const updateUserPw = async () => {
    if (!dubleClick) return;
    setDubleClick(false);

    const alertData = {
      type: "",
      message: "",
      callback_fn: () => {},
    };

    try {
      const requestUpdateUserPW = await userPermAxios.post("/change-user-pw", {
        currentPW,
        newPW,
      });

      const { success, message } = requestUpdateUserPW;

      if (success) {
        alertData.type = "Success";
        alertData.message = message;
        alertData.callback_fn = () => {
          closeModal();
        };
      }
    } catch (error) {
      if (error.response) {
        const { status, data } = error.response;
        const { message } = data;

        alertData.type = "warning";
        alertData.message = message;
      }
    } finally {
      setDubleClick(true);
      return create_alert_fn(
        alertData.type,
        alertData.message,
        alertData.callback_fn
      );
    }
  };

  return (
    <Modal
      open={pwSettingModalStatus.open}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="user-setting-change-pw-body">
        <div className="user-setting-change-pw-header-box">
          <div className="user-setting-change-pw-header-title">
            Change Password
          </div>
          <IconButton
            onClick={closeModal}
            className="user-setting-change-pw-close-btn"
          >
            <CloseOutlined fontSize="small" />
          </IconButton>
        </div>
        <ChangePasswordHorizontal />
        <div className="user-setting-change-pw-main-box">
          <div className="user-setting-change-pw-main-input-box">
            <div className="user-setting-change-pw-main-input-box-top">
              <PwInput
                label="Current Password"
                value={currentPW}
                setValue={setCurrentPW}
                placeholder=""
              />
              <PwInput
                label="New Password"
                value={newPW}
                setValue={setNewPW}
                placeholder=""
                error={newPWCheck === null ? false : true}
                helperText={newPWCheck}
              />
              <PwInput
                label="Confirm Password"
                value={repeatNewPW}
                setValue={setRepeatNewPW}
                placeholder=""
                error={repeatPwCheck === null ? false : true}
                helperText={repeatPwCheck}
              />
            </div>
            {/* <div className="user-setting-change-pw-main-input-box-bottom">
              <CustomCheckBox
                checked={checkValue}
                onChange={() => setCheckValue((prev) => !prev)}
              />

              <div className="includes-link-text-box">
                <div className="normal-text" style={{ color: "#344054" }}>
                  I Agree to our{" "}
                </div>
                <div className="under-line-text">Terms of use</div>
                <div className="normal-text" style={{ color: "#344054" }}>
                  and
                </div>
                <div className="under-line-text">Privacy Policy</div>
              </div>
            </div> */}
          </div>
        </div>
        <ChangePasswordHorizontal />
        <div className="user-setting-change-pw-btn-box">
          <Button
            role={undefined}
            variant="outlined"
            tabIndex={-1}
            onClick={closeModal}
            className="user-setting-change-pw-cancel-btn"
          >
            Cancel
          </Button>
          <Button
            onClick={updateUserPw}
            className="user-setting-change-pw-update-btn"
            disabled={button_disabled}
          >
            Update
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ChangePasswordModal;

const ChangePasswordHorizontal = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="638"
      height="1"
      viewBox="0 0 640 1"
      fill="none"
    >
      <path d="M0 0H640V1H0V0Z" fill="#E4E7EC" />
    </svg>
  );
};
