import React from "react";

const UserInfoTooltip = ({
  title,
  username,
  org_setting_name,
  lv_name,
  fn_name,
}) => {
  const contentsArr = [
    {
      title: "Name",
      contents: username,
    },
    {
      title: "Department",
      contents: org_setting_name,
    },
    {
      title: "Job Position",
      contents: lv_name,
    },
    {
      title: "Job Grade",
      contents: fn_name,
    },
  ];

  return (
    <div className="user-info-toolip-body">
      <div className="user-info-toolip-title-box">
        <div className="user-info-toolip-title">{title}</div>
      </div>
      <div className="user-info-toolip-contents-box">
        {contentsArr.map((c) => {
          const { title: contents_title, contents } = c;

          return (
            <div className="user-info-toolip-contents" key={contents_title}>
              <div className="user-info-toolip-contents-title-box">
                <div className="user-info-toolip-contents-title">
                  {contents_title}
                </div>
              </div>
              <div className="user-info-toolip-contents-detail-box">
                <div className="user-info-toolip-contents-detail">
                  {contents}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default UserInfoTooltip;
