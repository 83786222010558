import { resetRecoil } from "recoil-nexus";
import { TargetComment } from "@/atoms/commnet";
import { modalStatusList } from "@/atoms/modalStatus";
import {
  UserInfo,
  UserSetting,
  UserAuthorization,
  UserTargetProject,
  UserGeneralMenu,
} from "@/atoms/userInfo";

const allResetStates = () => {
  const resetStates = [
    TargetComment,
    UserInfo,
    UserSetting,
    UserAuthorization,
    UserTargetProject,
    UserGeneralMenu,
    ...modalStatusList,
  ];

  resetStates.forEach((c) => resetRecoil(c));
};

export default allResetStates;
