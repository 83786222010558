import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Layout from "@/layout/Layout";
import { getUserGeneralMenu } from "@/utils/isLoginCheck";
import { create_alert_fn } from "@/utils/createAlert";
import allResetStates from "@/utils/allResetStates";
import { userPermAxios } from "@/utils/customAxios";

const HomeRedirect = () => {
  const navigate = useNavigate();

  const query_home_menu = useQuery("user_start_menu", async () => {
    const { userGeneralMenu } = await getUserGeneralMenu();

    return userGeneralMenu;
  });

  const { data = [], isLoading } = query_home_menu;

  useEffect(() => {
    if (!isLoading && data.length === 0) {
      (async () => {
        create_alert_fn(
          "warning",
          "You do not have permission for all menus, please contact your administrator.",
          async () => {
            await userPermAxios.get("/logout").catch((err) => console.log(err));

            allResetStates();
            window.location.replace("/login");
          }
        );
      })();
    } else {
      const startPath = data?.[0]?.children[0].path;

      navigate(startPath);
    }
  }, [isLoading, data.length]);

  if (isLoading) {
    return null;
  }

  if (data.length === 0) {
    return <Layout top_menu="home">{null}</Layout>;
  }

  return;
};

export default HomeRedirect;
