import React from "react";
import { useSetRecoilState, useResetRecoilState } from "recoil";
import {
  MaterialApprovalModalStatus,
  MaterialApprovalRequestModalStatus,
} from "@/atoms/modalStatus";
import PhoneAndroidOutlinedIcon from "@mui/icons-material/PhoneAndroidOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
} from "@mui/x-data-grid-pro";
import { IconButton } from "@mui/material";
import {
  CustomLeadCell,
  CustomAddLineCell,
  CustomEditLeadCell,
  CustomDateTypeCell,
  CustomEditDateTypeCell,
  CustomNormalTextTypeCell,
  CustomEditPhoneNumberTypeCell,
  CustomEditEmailTypeCell,
  CustomEditSelectCell,
  // CustomUserTypeCell,
  // CustomEditUserTypeCell,
  CustomEditNormalTextTypeCell,
  CustomStageTypeCell,
  CustomAmountTypeCell,
  CustomEditAmountTypeCell,
  CustomEidtorPickerTypeCell,
  CustomEditNumberTypeCell,
  CustomSubPriceCell,
  CustomNumberTypeCell,
  LineDeleteBtnCell,
  DataConsolidationCell,
  QRCodeCreateBtnCell,
  QRCodeDownLoadBtnCell,
  CustomCheckBoxCell,
  CustomDetailPanelToggle,
  CustomEditSelectListCell,
  // CustomSelectListCell,
  ReleaseDataConsolidationCell,
  RequestDataConsolidationCell,
  CustomReceivedCheckBoxCell,
  CustomTaxPriceCell,
  CustomSumTaxPriceCell,
  CustomDiscountPriceCell,
  CustomCustomerContactPointCell,
  RequestSalesJobNumberCell,
  CustomEditDropDownListCell,
  CustomEidtorSalesPickerTypeCell,
  QtyControlDataConsolidationCell,
  PackingListDataConsolidationCell,
  PODownloadBtnCell,
} from "@components/Cells";
import BlankAddCell from "@components/materialTable/BlankAddCell";

import {
  salesStatusColorList,
  pRStatusColorList,
  materialReceivedSatusColorList,
  inventoryColorList,
  shopColorList,
  materialReleaseSatusColorList,
  vendorSatusColorList,
  customerSatusColorList,
  packingListStatusColorList,
  rfqSatusColorList,
  invQtyCtrlSatusColorList,
} from "@utils/colorList";
import { amount_currnecy_list } from "@utils/amountCurrency";
import { create_alert_fn } from "@/utils/createAlert";

export const sales_columns = (
  table_name,
  query_key,
  approval_user,
  admin_user,
  user_perm
) => {
  return [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      width: 40,
      renderCell: (params) => {
        return <CustomDetailCheckRow params={params} />;
      },
    },
    {
      field: "data_consolidation_cell",
      headerName: "",
      width: 40,
      editable: false,
      resizable: false,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <RequestSalesJobNumberCell
            params={params}
            table_name={table_name}
            query_key={query_key}
          />
        );
      },
    },
    {
      field: "lead",
      headerName: "Lead",
      width: 250,
      data_type: "string",
      editable: true,
      filterable: true,
      renderCell: (params) => {
        return (
          <CustomLeadCell
            params={params}
            table_name={table_name}
            query_key={query_key}
            add_text="Add Lead"
          />
        );
      },
      renderEditCell: (params) => {
        return <CustomEditLeadCell params={params} table_name={table_name} />;
      },
    },
    {
      field: "job_number",
      headerName: "Job Number",
      width: 180,
      data_type: "string",
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "bid_job_number",
      headerName: "Bid Job Number",
      width: 180,
      data_type: "string",
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "scope_name",
      headerName: "Scope Name",
      width: 150,
      data_type: "",
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "scope_detail_name",
      headerName: "Scope Detail Name",
      width: 150,
      data_type: "",
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "customer",
      headerName: "Customer",
      width: 150,
      data_type: "",
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "customer_contact_points",
      headerName: "Contact Points",
      width: 150,
      editable: false,
      renderCell: (params) => {
        return (
          <CustomCustomerContactPointCell
            params={params}
            query_key={query_key}
            admin_user={admin_user}
            user_perm={user_perm}
            blank_add_cell={
              <BlankAddCell
                right_icon={
                  <AccountCircleIcon
                    sx={{
                      color: "gray",
                    }}
                  />
                }
              />
            }
          />
        );
      },
    },
    {
      field: "sales_username",
      headerName: "Sales User",
      width: 150,
      data_type: "string",
      editable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
      renderEditCell: (params) => {
        return (
          <CustomEidtorPickerTypeCell
            params={params}
            table_name={table_name}
            picker_table_name="users"
            picker_column_list={["id", "username"]}
            picker_main_column_field={["username"]}
            picker_update_column_list={[]}
            fk_key="sales_user_id"
          />
        );
      },
    },
    {
      field: "sales_stage",
      headerName: "Sales Stage",
      width: 150,
      data_type: "string",
      editable: true,
      renderCell: (params) => {
        return (
          <CustomStageTypeCell
            params={params}
            blank_add_cell={<BlankAddCell />}
            list={salesStatusColorList}
          />
        );
      },
      renderEditCell: (params) => {
        const { value } = params;

        return (
          <CustomEditSelectCell
            params={params}
            table_name={table_name}
            required_fields={[
              { field: "customer", headerName: "Customer" },
              { field: "amount", headerName: "Volume" },
              { field: "amount_currency", headerName: "Volume Currency" },
              { field: "on_bid_date", headerName: "On Bid Date" },
              { field: "confirm_date", headerName: "Confirm Date" },
              { field: "end_date", headerName: "End Date" },
            ].filter((c) => {
              if (value === "Lead Identified" && c.field === "confirm_date") {
                return false;
              }

              return true;
            })}
            approval_list={salesStatusColorList.filter((c) => {
              const { name } = c;

              if (value === name) {
                return false;
              }

              if (value === "Lead Identified" && name === "Won") {
                return false;
              }

              if (name === "Won" || name === "Lost") {
                return approval_user;
              }

              return true;
            })}
          />
        );
      },
    },
    {
      field: "on_bid_date",
      headerName: "On Bid Date",
      width: 150,
      data_type: "string",
      type: "date",
      editable: true,
      renderCell: (params) => {
        return <CustomDateTypeCell params={params} />;
      },
      valueGetter: (params) => {
        const dateValue = params;
        return dateValue ? new Date(params) : null;
      },
      renderEditCell: (params) => {
        return (
          <CustomEditDateTypeCell params={params} table_name={table_name} />
        );
      },
    },
    {
      field: "confirm_date",
      headerName: "Confirm Date",
      width: 150,
      data_type: "string",
      type: "date",
      editable: true,
      renderCell: (params) => {
        return <CustomDateTypeCell params={params} />;
      },
      valueGetter: (params) => {
        const dateValue = params;
        return dateValue ? new Date(params) : null;
      },
      renderEditCell: (params) => {
        return (
          <CustomEditDateTypeCell params={params} table_name={table_name} />
        );
      },
    },
    {
      field: "end_date",
      headerName: "End Date",
      width: 150,
      data_type: "string",
      type: "date",
      editable: true,
      renderCell: (params) => {
        return <CustomDateTypeCell params={params} />;
      },
      valueGetter: (params) => {
        const dateValue = params;
        return dateValue ? new Date(params) : null;
      },
      renderEditCell: (params) => {
        return (
          <CustomEditDateTypeCell params={params} table_name={table_name} />
        );
      },
    },

    // {
    //   field: "next_due_date",
    //   headerName: "Next Due Date",
    //   width: 150,
    //   data_type: "string",
    //   type: "date",
    //   editable: true,
    //   renderCell: (params) => {
    //     return <CustomDateTypeCell params={params} />;
    //   },
    //   valueGetter: (params) => {
    //     const dateValue = params;
    //     return dateValue ? new Date(params) : null;
    //   },
    //   renderEditCell: (params) => {
    //     return (
    //       <CustomEditDateTypeCell params={params} table_name={table_name} />
    //     );
    //   },
    // },
    // {
    //   field: "next_task",
    //   headerName: "Next Task",
    //   width: 150,
    //   data_type: "string",
    //   editable: true,
    //   renderCell: (params) => {
    //     return (
    //       <CustomNormalTextTypeCell
    //         params={params}
    //         blank_add_cell={
    //           <BlankAddCell right_icon={<CreateOutlinedIcon />} />
    //         }
    //       />
    //     );
    //   },
    //   renderEditCell: (params) => {
    //     return (
    //       <CustomEditNormalTextTypeCell
    //         params={params}
    //         table_name={table_name}
    //       />
    //     );
    //   },
    // },
    {
      field: "amount",
      headerName: "Volume",
      width: 150,
      data_type: "number",
      editable: true,
      renderCell: (params) => {
        return (
          <CustomAmountTypeCell
            params={params}
            blank_add_cell={<PaidOutlinedIcon />}
          />
        );
      },

      renderEditCell: (params) => {
        return (
          <CustomEditAmountTypeCell params={params} table_name={table_name} />
        );
      },
    },
    {
      field: "change_order_amount",
      headerName: "Change Order Volume",
      width: 150,
      data_type: "",
      editable: false,
      renderCell: (params) => {
        return <CustomNumberTypeCell params={params} />;
      },
    },
    {
      field: "total_amount",
      headerName: "Total Volume",
      width: 150,
      data_type: "",
      editable: false,
      renderCell: (params) => {
        return <CustomNumberTypeCell params={params} />;
      },
    },
    {
      field: "updated_at",
      headerName: "Last Update Time",
      width: 150,
      data_type: "dateTime",
      type: "date",
      editable: false,
      renderCell: (params) => {
        return <CustomDateTypeCell params={params} />;
      },
      valueGetter: (params) => {
        const dateValue = params;
        return dateValue ? new Date(params) : null;
      },
      // renderEditCell: (params) => {
      //   return (
      //     <CustomEditDateTypeCell params={params} table_name={table_name} />
      //   );
      // },
    },
  ];
};

export const material_master_column = (table_name, query_key) => {
  return [
    {
      field: "mat_code",
      headerName: "Material Code",
      width: 150,
      data_type: "string",
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "cost_code",
      headerName: "Cost Code",
      width: 150,
      data_type: "string",
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "mat_description",
      headerName: "Description",
      width: 550,
      data_type: "string",
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "mat_category",
      headerName: "Category",
      data_type: "string",
      width: 150,
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "mat_uom",
      headerName: "Unit",
      data_type: "string",
      width: 150,
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "mat_size",
      headerName: "Size",
      data_type: "string",
      width: 150,
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "mat_item",
      headerName: "Item",
      data_type: "string",
      width: 150,
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "mat_type",
      headerName: "Type",
      data_type: "string",
      width: 150,
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "mat_material",
      headerName: "Material",
      data_type: "string",
      flex: 1,
      minWidth: 150,
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
  ];
};

export const job_number_column = (table_name, query_key) => {
  return [
    {
      field: "category",
      headerName: "Category",
      width: 200,
      data_type: "string",
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "scope_name",
      headerName: "Scope Name",
      width: 150,
      data_type: "string",
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "scope_detail_name",
      headerName: "Scope Detail Name",
      width: 550,
      data_type: "string",
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "scope_code",
      headerName: "Scope Code",
      data_type: "string",
      width: 150,
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
  ];
};

export const vendor_columns = (table_name, query_key, approval_user) => {
  return [
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      width: 40,
      renderCell: (params) => (
        <CustomDetailPanelToggle id={params.id} value={params.value} />
      ),
    },
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      width: 40,
      renderCell: (params) => {
        return <CustomDetailCheckRow params={params} />;
      },
    },
    {
      field: "vendor_name",
      headerName: "Vendor Name",
      width: 250,
      data_type: "string",
      editable: true,
      filterable: true,
      renderCell: (params) => {
        return (
          <CustomLeadCell
            params={params}
            table_name={table_name}
            query_key={query_key}
            add_text="Add Vendor"
          />
        );
      },
      renderEditCell: (params) => {
        return <CustomEditLeadCell params={params} table_name={table_name} />;
      },
    },
    {
      field: "reg_no",
      headerName: "Tax ID",
      width: 150,
      data_type: "",
      editable: true,
      renderCell: (params) => {
        return (
          <CustomNormalTextTypeCell
            params={params}
            blank_add_cell={
              <BlankAddCell right_icon={<CreateOutlinedIcon />} />
            }
          />
        );
      },
      renderEditCell: (params) => {
        return (
          <CustomEditNormalTextTypeCell
            params={params}
            table_name={table_name}
          />
        );
      },
    },
    {
      field: "address",
      headerName: "Address",
      flex: 1,
      minWidth: 150,
      data_type: "",
      editable: true,
      renderCell: (params) => {
        return (
          <CustomNormalTextTypeCell
            params={params}
            blank_add_cell={
              <BlankAddCell right_icon={<LocationOnOutlinedIcon />} />
            }
          />
        );
      },
      renderEditCell: (params) => {
        return (
          <CustomEditNormalTextTypeCell
            params={params}
            table_name={table_name}
          />
        );
      },
    },
    {
      field: "contact_name",
      headerName: "Contact Name",
      width: 150,
      data_type: "",
      editable: true,
      renderCell: (params) => {
        return (
          <CustomNormalTextTypeCell
            params={params}
            blank_add_cell={
              <BlankAddCell right_icon={<CreateOutlinedIcon />} />
            }
          />
        );
      },
      renderEditCell: (params) => {
        return (
          <CustomEditNormalTextTypeCell
            params={params}
            table_name={table_name}
          />
        );
      },
    },
    {
      field: "phone",
      headerName: "Contact Phone",
      width: 150,
      data_type: "",
      editable: true,
      renderCell: (params) => {
        return (
          <CustomNormalTextTypeCell
            params={params}
            blank_add_cell={
              <BlankAddCell right_icon={<PhoneAndroidOutlinedIcon />} />
            }
          />
        );
      },
      renderEditCell: (params) => {
        return (
          <CustomEditPhoneNumberTypeCell
            params={params}
            table_name={table_name}
          />
        );
      },
    },
    {
      field: "email",
      headerName: "Contact Email",
      width: 150,
      data_type: "",
      editable: true,
      renderCell: (params) => {
        return (
          <CustomNormalTextTypeCell
            params={params}
            blank_add_cell={<BlankAddCell right_icon={<EmailOutlinedIcon />} />}
          />
        );
      },
      renderEditCell: (params) => {
        return (
          <CustomEditEmailTypeCell params={params} table_name={table_name} />
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      data_type: "string",
      editable: true,
      renderCell: (params) => {
        return (
          <CustomStageTypeCell
            params={params}
            blank_add_cell={<BlankAddCell />}
            list={vendorSatusColorList}
          />
        );
      },
      renderEditCell: (params) => {
        return (
          <CustomEditSelectCell
            params={params}
            table_name={table_name}
            required_fields={[
              { field: "reg_no", headerName: "Tax ID" },
              { field: "address", headerName: "Address" },
              { field: "contact_name", headerName: "Contact Name" },
              { field: "phone", headerName: "Contact Phone" },
              { field: "email", headerName: "Contact Email" },
            ]}
            approval_list={vendorSatusColorList.filter((c) => {
              const { name } = c;

              if (name === "Approved") {
                return approval_user;
              }

              return true;
            })}
          />
        );
      },
    },
  ];
};

export const vendor_lineitems_columns = () => {
  return [
    {
      field: "bid_sortation",
      headerName: "Bid Sortation",
      width: 150,
      data_type: "string",
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "sales_lead",
      headerName: "Lead",
      width: 250,
      data_type: "string",
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "mat_code",
      headerName: "Material Code",
      width: 150,
      data_type: "string",
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "mat_description",
      headerName: "Description",
      flex: 1,
      minWidth: 550,
      data_type: "string",
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "mat_uom",
      headerName: "Unit",
      width: 150,
      data_type: "string",
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "order_qnty",
      headerName: "Order Qty",
      width: 150,
      data_type: "string",
      type: "number",
      editable: false,
      renderCell: (params) => {
        return <CustomNumberTypeCell params={params} />;
      },
    },
    {
      field: "amount",
      headerName: "Unit Price",
      width: 150,
      data_type: "number",
      editable: false,
      renderCell: (params) => {
        return (
          <CustomAmountTypeCell
            params={params}
            blank_add_cell={<PaidOutlinedIcon />}
          />
        );
      },
    },
    {
      field: "rfq_date",
      headerName: "RFQ Date",
      width: 200,
      data_type: "dateTime",
      type: "date",
      editable: false,
      renderCell: (params) => {
        return <CustomDateTypeCell params={params} />;
      },
      valueGetter: (params) => {
        const dateValue = params;
        return dateValue ? new Date(params) : null;
      },
    },
    {
      field: "val_date",
      headerName: "Validation Date",
      width: 200,
      data_type: "dateTime",
      type: "date",
      editable: false,
      renderCell: (params) => {
        return <CustomDateTypeCell params={params} />;
      },
      valueGetter: (params) => {
        const dateValue = params;
        return dateValue ? new Date(params) : null;
      },
    },
  ];
};

export const customer_columns = (table_name, query_key, approval_user) => {
  return [
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      width: 40,
      renderCell: (params) => (
        <CustomDetailPanelToggle id={params.id} value={params.value} />
      ),
    },
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      width: 40,
      renderCell: (params) => {
        return <CustomDetailCheckRow params={params} />;
      },
    },
    {
      field: "customer",
      headerName: "Customer Name",
      width: 250,
      data_type: "string",
      editable: true,
      filterable: true,
      renderCell: (params) => {
        return (
          <CustomLeadCell
            params={params}
            table_name={table_name}
            query_key={query_key}
            add_text="Add Customer"
          />
        );
      },
      renderEditCell: (params) => {
        return <CustomEditLeadCell params={params} table_name={table_name} />;
      },
    },
    {
      field: "customer_code",
      headerName: "Customer Code",
      width: 150,
      data_type: "string",
      editable: true,
      filterable: true,
      renderCell: (params) => {
        return (
          <CustomNormalTextTypeCell
            params={params}
            blank_add_cell={
              <BlankAddCell right_icon={<CreateOutlinedIcon />} />
            }
          />
        );
      },
      renderEditCell: (params) => {
        return (
          <CustomEditNormalTextTypeCell
            params={params}
            table_name={table_name}
          />
        );
      },
    },
    {
      field: "reg_no",
      headerName: "Tax ID",
      width: 150,
      data_type: "",
      editable: true,
      renderCell: (params) => {
        return (
          <CustomNormalTextTypeCell
            params={params}
            blank_add_cell={
              <BlankAddCell right_icon={<CreateOutlinedIcon />} />
            }
          />
        );
      },
      renderEditCell: (params) => {
        return (
          <CustomEditNormalTextTypeCell
            params={params}
            table_name={table_name}
          />
        );
      },
    },
    {
      field: "address",
      headerName: "Address",
      flex: 1,
      minWidth: 150,
      data_type: "",
      editable: true,
      renderCell: (params) => {
        return (
          <CustomNormalTextTypeCell
            params={params}
            blank_add_cell={
              <BlankAddCell right_icon={<LocationOnOutlinedIcon />} />
            }
          />
        );
      },
      renderEditCell: (params) => {
        return (
          <CustomEditNormalTextTypeCell
            params={params}
            table_name={table_name}
          />
        );
      },
    },
    {
      field: "contact",
      headerName: "Contact Number",
      width: 150,
      data_type: "",
      editable: true,
      renderCell: (params) => {
        return (
          <CustomNormalTextTypeCell
            params={params}
            blank_add_cell={
              <BlankAddCell right_icon={<CreateOutlinedIcon />} />
            }
          />
        );
      },
      renderEditCell: (params) => {
        return (
          <CustomEditNormalTextTypeCell
            params={params}
            table_name={table_name}
          />
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      data_type: "string",
      editable: true,
      renderCell: (params) => {
        return (
          <CustomStageTypeCell
            params={params}
            blank_add_cell={<BlankAddCell />}
            list={customerSatusColorList}
          />
        );
      },
      renderEditCell: (params) => {
        const { value } = params;
        return (
          <CustomEditSelectCell
            params={params}
            table_name={table_name}
            required_fields={[
              { field: "customer_code", headerName: "Customer Code" },
              { field: "reg_no", headerName: "Tax ID" },
              { field: "address", headerName: "Address" },
              { field: "contact", headerName: "Contact Number" },
            ]}
            approval_list={customerSatusColorList
              .filter((c) => {
                const { name } = c;

                if (name === "Approved") {
                  return approval_user;
                }

                return true;
              })
              .map((c) => {
                const result = { ...c };
                if (c.name === value) {
                  result.not_visible = true;
                }
                return result;
              })}
          />
        );
      },
    },
  ];
};

export const customer_lineitem_columns = () => {
  return [
    {
      field: "sales_lead",
      headerName: "Lead",
      width: 250,
      data_type: "string",
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "contact_name",
      headerName: "Contact Name",
      width: 150,
      data_type: "",
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "phone",
      headerName: "Phone",
      flex: 1,
      minWidth: 150,
      data_type: "",
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "email",
      headerName: "Email",
      width: 150,
      data_type: "",
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "job_name",
      headerName: "Job Name",
      width: 150,
      data_type: "",
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
  ];
};

export const purchase_request_column = (
  table_name,
  query_key,
  approval_user
) => {
  return [
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      headerName: "",
      width: 40,
      renderCell: (params) => (
        <CustomDetailPanelToggle id={params.id} value={params.value} />
      ),
    },
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      headerName: "",
      width: 40,
      renderCell: (params) => {
        return <CustomDetailCheckRow params={params} />;
      },
    },
    {
      field: "data_consolidation_cell",
      headerName: "",
      width: 40,
      editable: false,
      resizable: false,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <RequestDataConsolidationCell
            params={params}
            table_name={table_name}
          />
        );
      },
    },
    {
      field: "material_approval_cell",
      headerName: "",
      width: 40,
      editable: false,
      resizable: false,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return <MaterialApprovalCell params={params} table_name={table_name} />;
      },
    },
    {
      field: "material_approval_request_cell",
      headerName: "",
      width: 40,
      editable: false,
      resizable: false,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <MaterialApprovalRequestCell
            params={params}
            table_name={table_name}
            required_fields={[
              { field: "pr_date", headerName: "Date" },
              { field: "sales_id", headerName: "Project Name" },
              { field: "bid_sortation", headerName: "Bid Sortation" },
            ]}
          />
        );
      },
    },
    {
      field: "pr_no",
      headerName: "PR Number",
      data_type: "string",
      width: 200,
      editable: false,
      renderCell: (params) => {
        return (
          <CustomAddLineCell
            params={params}
            table_name={table_name}
            query_key={query_key}
            type="upper"
            next_focus_cell_field="bid_sortation"
            add_text="Add PR"
          />
        );
      },
    },
    {
      field: "bid_sortation",
      headerName: "Bid Sortation",
      flex: 1,
      minWidth: 150,
      data_type: "string",
      editable: true,
      drop_down_list: [
        { label: "For Bid", value: "For Bid" },
        { label: "For Work", value: "For Work" },
      ],
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
      renderEditCell: (params) => {
        return (
          <CustomEditDropDownListCell params={params} table_name={table_name} />
        );
      },
    },
    {
      field: "lead",
      headerName: "Project Name",
      flex: 1,
      minWidth: 150,
      data_type: "string",
      editable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
      renderEditCell: (params) => {
        return (
          <CustomEidtorSalesPickerTypeCell
            params={params}
            table_name={table_name}
            picker_table_name="sales"
            picker_column_list={["id", "lead", "customer"]}
            picker_main_column_field={["lead"]}
            picker_update_column_list={["lead", "customer"]}
            fk_key="sales_id"
          />
        );
      },
    },
    {
      field: "job_number",
      headerName: "Job Number",
      flex: 1,
      minWidth: 150,
      data_type: "string",
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },

    {
      field: "customer",
      headerName: "Customer",
      width: 150,
      data_type: "string",
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },

    {
      field: "pr_date",
      headerName: "Date",
      data_type: "string",
      type: "date",
      width: 150,
      editable: true,
      renderCell: (params) => {
        return <CustomDateTypeCell params={params} />;
      },
      valueGetter: (params) => {
        const dateValue = params;
        return dateValue ? new Date(params) : null;
      },
      renderEditCell: (params) => {
        return (
          <CustomEditDateTypeCell params={params} table_name={table_name} />
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      data_type: "string",
      width: 150,
      editable: false,
      renderCell: (params) => {
        return (
          <CustomStageTypeCell
            params={params}
            blank_add_cell={<BlankAddCell />}
            list={pRStatusColorList}
          />
        );
      },
      // renderEditCell: (params) => {
      //   return (
      //     <CustomEditSelectCell
      //       params={params}
      //       table_name={table_name}
      //       required_fields={[
      //         { field: "pr_date", headerName: "Date" },
      //         { field: "sales_id", headerName: "Project Name" },
      //         { field: "bid_sortation", headerName: "Bid Sortation" },
      //       ]}
      //       approval_list={pRStatusColorList.filter((c) => {
      //         const { name } = c;

      //         if (name === "Approved") {
      //           return approval_user;
      //         }

      //         return true;
      //       })}
      //     />
      //   );
      // },
    },
  ];
};

export const purchase_request_lineitems_column = (table_name, query_key) => {
  return [
    {
      field: "delete_cell",
      headerName: "",
      width: 40,
      editable: false,
      filterable: false,
      renderCell: (params) => {
        return <LineDeleteBtnCell params={params} table_name={table_name} />;
      },
    },
    {
      field: "pr_itm_no",
      headerName: "Item Number",
      data_type: "number",
      width: 150,
      editable: false,
      renderCell: (params) => {
        return (
          <CustomAddLineCell
            params={params}
            table_name={table_name}
            query_key={query_key}
            type="lineitem"
            next_focus_cell_field="mat_code"
            add_text="Add Item"
          />
        );
      },
    },
    {
      field: "mat_code",
      headerName: "Material Code",
      width: 150,
      data_type: "string",
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "mat_description",
      headerName: "Description",
      flex: 1,
      minWidth: 150,
      data_type: "string",
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "mat_category",
      headerName: "Category",
      width: 150,
      data_type: "string",
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "mat_uom",
      headerName: "Unit",
      width: 150,
      data_type: "string",
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "order_qnty",
      headerName: "Order Qty",
      width: 150,
      data_type: "string",
      type: "number",
      editable: true,
      renderCell: (params) => {
        return <CustomNumberTypeCell params={params} />;
      },
      renderEditCell: (params) => {
        return (
          <CustomEditNumberTypeCell params={params} table_name={table_name} />
        );
      },
    },
  ];
};

export const request_for_quotation_column = (
  table_name,
  query_key,
  approval_user
) => {
  return [
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      width: 40,
      renderCell: (params) => (
        <CustomDetailPanelToggle id={params.id} value={params.value} />
      ),
    },
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      width: 40,
      renderCell: (params) => {
        return <CustomDetailCheckRow params={params} />;
      },
    },
    {
      field: "data_consolidation_cell",
      headerName: "",
      width: 40,
      editable: false,
      resizable: false,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <DataConsolidationCell
            params={params}
            table_name={table_name}
            prev_table_name="purchase_request"
          />
        );
      },
    },
    {
      field: "material_approval_cell",
      headerName: "",
      width: 40,
      editable: false,
      resizable: false,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return <MaterialApprovalCell params={params} table_name={table_name} />;
      },
    },
    {
      field: "material_approval_request_cell",
      headerName: "",
      width: 40,
      editable: false,
      resizable: false,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <MaterialApprovalRequestCell
            params={params}
            table_name={table_name}
            required_fields={[
              { field: "vendor_name", headerName: "Vendor Name" },
              { field: "sales_id", headerName: "Project Name" },
              { field: "amount_currency", headerName: "Currency" },
              { field: "rfq_date", headerName: "RFQ Date" },
              { field: "val_date", headerName: "Validation Date" },
            ]}
          />
        );
      },
    },
    {
      field: "rfq_no",
      headerName: "RFQ Number",
      data_type: "string",
      width: 200,
      editable: false,
      renderCell: (params) => {
        return (
          <CustomAddLineCell
            params={params}
            table_name={table_name}
            query_key={query_key}
            type="upper"
            next_focus_cell_field="bid_sortation"
            add_text="Add RFQ"
          />
        );
      },
    },
    {
      field: "bid_sortation",
      headerName: "Bid Sortation",
      flex: 1,
      minWidth: 150,
      data_type: "string",
      editable: true,
      drop_down_list: [
        { label: "For Bid", value: "For Bid" },
        { label: "For Work", value: "For Work" },
      ],
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
      renderEditCell: (params) => {
        return (
          <CustomEditDropDownListCell params={params} table_name={table_name} />
        );
      },
    },
    {
      field: "lead",
      headerName: "Project Name",
      flex: 1,
      minWidth: 150,
      data_type: "string",
      editable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
      renderEditCell: (params) => {
        return (
          <CustomEidtorSalesPickerTypeCell
            params={params}
            table_name={table_name}
            picker_table_name="sales"
            picker_column_list={["id", "lead"]}
            picker_main_column_field={["lead"]}
            picker_update_column_list={["lead"]}
            fk_key="sales_id"
          />
        );
      },
    },
    {
      field: "job_number",
      headerName: "Job Number",
      flex: 1,
      minWidth: 150,
      data_type: "string",
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "vendor_name",
      headerName: "Vendor Name",
      width: 150,
      data_type: "string",
      editable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
      renderEditCell: (params) => {
        return (
          <CustomEidtorPickerTypeCell
            params={params}
            table_name={table_name}
            picker_table_name="vendor"
            picker_column_list={["id", "vendor_name", "reg_no"]}
            picker_main_column_field={["vendor_name"]}
            picker_update_column_list={["vendor_name"]}
            fk_key="vendor_id"
          />
        );
      },
    },
    {
      field: "amount_currency",
      headerName: "Currency",
      width: 100,
      data_type: "string",
      editable: true,
      list: amount_sybol_list,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
      valueGetter: (params) => {
        const amount_sybol = amount_sybol_list.find((c) => c.value === params);

        return amount_sybol?.name ?? params;
      },
      renderEditCell: (params) => {
        return (
          <CustomEditSelectListCell params={params} table_name={table_name} />
        );
      },
    },
    {
      field: "tax_percent",
      headerName: "Tax Pct(%)",
      width: 150,
      data_type: "number",
      type: "number",
      editable: true,
      renderCell: (params) => {
        return (
          <CustomNumberTypeCell
            params={params}
            blank_add_cell={<PaidOutlinedIcon />}
          />
        );
      },
      renderEditCell: (params) => {
        return (
          <CustomEditNumberTypeCell params={params} table_name={table_name} />
        );
      },
    },
    {
      field: "order_amount",
      headerName: "RFQ Amount",
      width: 150,
      data_type: "number",
      // type: "number",
      editable: false,
      renderCell: (params) => {
        return (
          <CustomNumberTypeCell
            params={params}
            blank_add_cell={<PaidOutlinedIcon />}
          />
        );
      },
    },
    {
      field: "tax_amount",
      headerName: "Tax Amount",
      width: 150,
      data_type: "number",
      // type: "number",
      editable: false,
      renderCell: (params) => {
        return (
          <CustomNumberTypeCell
            params={params}
            blank_add_cell={<PaidOutlinedIcon />}
          />
        );
      },
    },
    {
      field: "total_amount",
      headerName: "Total Amount",
      width: 150,
      data_type: "number",
      // type: "number",
      editable: false,
      renderCell: (params) => {
        return (
          <CustomNumberTypeCell
            params={params}
            blank_add_cell={<PaidOutlinedIcon />}
          />
        );
      },
    },
    {
      field: "rfq_date",
      headerName: "RFQ Date",
      data_type: "string",
      type: "date",
      width: 150,
      editable: true,
      renderCell: (params) => {
        return <CustomDateTypeCell params={params} />;
      },
      valueGetter: (params) => {
        const dateValue = params;
        return dateValue ? new Date(params) : null;
      },
      renderEditCell: (params) => {
        return (
          <CustomEditDateTypeCell params={params} table_name={table_name} />
        );
      },
    },
    {
      field: "val_date",
      headerName: "Validation Date",
      data_type: "string",
      type: "date",
      width: 150,
      editable: true,
      renderCell: (params) => {
        return <CustomDateTypeCell params={params} />;
      },
      valueGetter: (params) => {
        const dateValue = params;
        return dateValue ? new Date(params) : null;
      },
      renderEditCell: (params) => {
        return (
          <CustomEditDateTypeCell params={params} table_name={table_name} />
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      data_type: "string",
      width: 150,
      editable: false,
      renderCell: (params) => {
        return (
          <CustomStageTypeCell
            params={params}
            blank_add_cell={<BlankAddCell />}
            list={rfqSatusColorList}
          />
        );
      },
      // renderEditCell: (params) => {
      //   return (
      //     <CustomEditSelectCell
      //       params={params}
      //       table_name={table_name}
      //       required_fields={[
      //         { field: "vendor_name", headerName: "Vendor Name" },
      //         { field: "sales_id", headerName: "Project Name" },
      //         { field: "amount_currency", headerName: "Currency" },
      //         { field: "rfq_date", headerName: "RFQ Date" },
      //         { field: "val_date", headerName: "Validation Date" },
      //       ]}
      //       approval_list={rfqSatusColorList.filter((c) => {
      //         const { name } = c;

      //         if (name === "Approved") {
      //           return approval_user;
      //         }

      //         return true;
      //       })}
      //     />
      //   );
      // },
    },
  ];
};

export const request_for_quotation_lineitems_column = (
  table_name,
  query_key
) => {
  return [
    {
      field: "delete_cell",
      headerName: "",
      width: 40,
      editable: false,
      filterable: false,
      renderCell: (params) => {
        return <LineDeleteBtnCell params={params} table_name={table_name} />;
      },
    },
    {
      field: "rfq_itm_no",
      headerName: "Item Number",
      data_type: "number",
      width: 150,
      editable: false,
      renderCell: (params) => {
        return (
          <CustomAddLineCell
            params={params}
            table_name={table_name}
            query_key={query_key}
            type="lineitem"
            next_focus_cell_field="mat_code"
            add_text="Add Item"
          />
        );
      },
    },
    {
      field: "mat_code",
      headerName: "Material Code",
      flex: 1,
      minWidth: 150,
      data_type: "string",
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "mat_description",
      headerName: "Description",
      width: 550,
      data_type: "string",
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "mat_uom",
      headerName: "Unit",
      width: 150,
      data_type: "string",
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "request_order_qnty",
      headerName: "Request Qty",
      width: 150,
      data_type: "number",
      type: "number",
      editable: false,
      renderCell: (params) => {
        return <CustomNumberTypeCell params={params} />;
      },
    },
    {
      field: "order_qnty",
      headerName: "Qty",
      width: 150,
      data_type: "number",
      type: "number",
      editable: true,
      renderCell: (params) => {
        return <CustomNumberTypeCell params={params} />;
      },
      renderEditCell: (params) => {
        return (
          <CustomEditNumberTypeCell
            params={params}
            table_name={table_name}
            target_field_name="request_order_qnty"
          />
        );
      },
    },
    {
      field: "order_unitprice",
      headerName: "Unit Price",
      width: 150,
      data_type: "number",
      type: "number",
      editable: true,
      renderCell: (params) => {
        return (
          <CustomNumberTypeCell
            params={params}
            blank_add_cell={<PaidOutlinedIcon />}
          />
        );
      },
      renderEditCell: (params) => {
        return (
          <CustomEditNumberTypeCell params={params} table_name={table_name} />
        );
      },
    },
    {
      field: "sub_total_price",
      headerName: "Price",
      width: 150,
      data_type: "number",
      type: "number",
      editable: false,
      renderCell: (params) => {
        return (
          <CustomSubPriceCell
            params={params}
            qty_field="order_qnty"
            unit_price_field="order_unitprice"
          />
        );
      },
    },
    {
      field: "tax_price",
      headerName: "Tax Price",
      width: 150,
      data_type: "number",
      type: "number",
      editable: false,
      renderCell: (params) => {
        return (
          <CustomTaxPriceCell
            params={params}
            qty_field="order_qnty"
            unit_price_field="order_unitprice"
            tax_percent_field="tax_percent"
            discount_percent_field="discount_percent"
          />
        );
      },
    },
    {
      field: "sub_total",
      headerName: "Sub Total",
      width: 150,
      data_type: "number",
      type: "number",
      editable: false,
      renderCell: (params) => {
        return (
          <CustomSumTaxPriceCell
            params={params}
            qty_field="order_qnty"
            unit_price_field="order_unitprice"
            tax_percent_field="tax_percent"
            discount_percent_field="discount_percent"
          />
        );
      },
    },
  ];
};

export const purchase_order_column = (table_name, query_key, approval_user) => {
  return [
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      width: 40,
      renderCell: (params) => (
        <CustomDetailPanelToggle id={params.id} value={params.value} />
      ),
    },
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,

      width: 40,
      renderCell: (params) => {
        return <CustomDetailCheckRow params={params} />;
      },
    },
    {
      field: "data_consolidation_cell",
      headerName: "",
      width: 40,
      editable: false,
      resizable: false,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <DataConsolidationCell
            params={params}
            table_name={table_name}
            prev_table_name="request_for_quotation"
          />
        );
      },
    },
    {
      field: "material_approval_cell",
      headerName: "",
      width: 40,
      editable: false,
      resizable: false,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return <MaterialApprovalCell params={params} table_name={table_name} />;
      },
    },
    {
      field: "material_approval_request_cell",
      headerName: "",
      width: 40,
      editable: false,
      resizable: false,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <MaterialApprovalRequestCell
            params={params}
            table_name={table_name}
            required_fields={[
              { field: "vendor_name", headerName: "Vendor Name" },
              { field: "sales_id", headerName: "Project Name" },
              { field: "amount_currency", headerName: "Currency" },
              { field: "po_date", headerName: "Submitted Date" },
            ]}
          />
        );
      },
    },
    {
      field: "po_download_cell",
      headerName: "",
      width: 40,
      editable: false,
      resizable: false,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return <PODownloadBtnCell params={params} />;
      },
    },
    {
      field: "po_no",
      headerName: "PO Number",
      data_type: "string",
      width: 200,
      editable: false,
      renderCell: (params) => {
        return (
          <CustomAddLineCell
            params={params}
            table_name={table_name}
            query_key={query_key}
            type="upper"
            next_focus_cell_field="lead"
            add_text="Add PO"
          />
        );
      },
    },
    {
      field: "lead",
      headerName: "Project Name",
      flex: 1,
      minWidth: 150,
      data_type: "string",
      editable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
      renderEditCell: (params) => {
        return (
          <CustomEidtorSalesPickerTypeCell
            params={params}
            table_name={table_name}
            picker_table_name="sales"
            picker_column_list={["id", "lead"]}
            picker_main_column_field={["lead"]}
            picker_update_column_list={["lead"]}
            fk_key="sales_id"
          />
        );
      },
    },
    {
      field: "job_number",
      headerName: "Job Number",
      width: 180,
      data_type: "string",
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "vendor_name",
      headerName: "Vendor Name",
      width: 150,
      data_type: "string",
      editable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
      renderEditCell: (params) => {
        return (
          <CustomEidtorPickerTypeCell
            params={params}
            table_name={table_name}
            picker_table_name="vendor"
            picker_column_list={["id", "vendor_name", "reg_no"]}
            picker_main_column_field={["vendor_name"]}
            picker_update_column_list={["vendor_name"]}
            fk_key="vendor_id"
          />
        );
      },
    },
    {
      field: "amount_currency",
      headerName: "Currency",
      width: 100,
      data_type: "string",
      editable: true,
      list: amount_sybol_list,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
      valueGetter: (params) => {
        const amount_sybol = amount_sybol_list.find((c) => c.value === params);

        return amount_sybol?.name ?? params;
      },
      renderEditCell: (params) => {
        return (
          <CustomEditSelectListCell params={params} table_name={table_name} />
        );
      },
    },
    {
      field: "tax_percent",
      headerName: "Tax Pct(%)",
      width: 150,
      data_type: "number",
      type: "number",
      editable: true,
      renderCell: (params) => {
        return (
          <CustomNumberTypeCell
            params={params}
            blank_add_cell={<PaidOutlinedIcon />}
          />
        );
      },
      renderEditCell: (params) => {
        return (
          <CustomEditNumberTypeCell params={params} table_name={table_name} />
        );
      },
    },
    {
      field: "withholding_tax_percent",
      headerName: "Withholding Tax Pct(%)",
      width: 150,
      data_type: "number",
      type: "number",
      editable: true,
      renderCell: (params) => {
        return (
          <CustomNumberTypeCell
            params={params}
            blank_add_cell={<PaidOutlinedIcon />}
          />
        );
      },
      renderEditCell: (params) => {
        return (
          <CustomEditNumberTypeCell params={params} table_name={table_name} />
        );
      },
    },
    {
      field: "order_amount",
      headerName: "PO Amount",
      width: 150,
      data_type: "number",
      // type: "number",
      editable: false,
      renderCell: (params) => {
        return (
          <CustomNumberTypeCell
            params={params}
            blank_add_cell={<PaidOutlinedIcon />}
          />
        );
      },
    },
    {
      field: "discount_amount",
      headerName: "Discounted Amount",
      width: 150,
      data_type: "number",
      // type: "number",
      editable: false,
      renderCell: (params) => {
        return (
          <CustomNumberTypeCell
            params={params}
            blank_add_cell={<PaidOutlinedIcon />}
          />
        );
      },
    },
    {
      field: "tax_amount",
      headerName: "Tax Amount",
      width: 150,
      data_type: "number",
      editable: false,
      renderCell: (params) => {
        return (
          <CustomNumberTypeCell
            params={params}
            blank_add_cell={<PaidOutlinedIcon />}
          />
        );
      },
    },
    {
      field: "withhold_tax_amount",
      headerName: "Withholding Tax Amount",
      width: 150,
      data_type: "number",
      editable: false,
      renderCell: (params) => {
        return (
          <CustomNumberTypeCell
            params={params}
            blank_add_cell={<PaidOutlinedIcon />}
          />
        );
      },
    },
    {
      field: "total_amount",
      headerName: "Total Amount",
      width: 150,
      data_type: "number",
      // type: "number",
      editable: false,
      renderCell: (params) => {
        return (
          <CustomNumberTypeCell
            params={params}
            blank_add_cell={<PaidOutlinedIcon />}
          />
        );
      },
    },
    {
      field: "po_date",
      headerName: "Submitted Date",
      data_type: "string",
      type: "date",
      width: 150,
      editable: true,
      renderCell: (params) => {
        return <CustomDateTypeCell params={params} />;
      },
      valueGetter: (params) => {
        const dateValue = params;
        return dateValue ? new Date(params) : null;
      },
      renderEditCell: (params) => {
        return (
          <CustomEditDateTypeCell params={params} table_name={table_name} />
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      data_type: "string",
      width: 150,
      editable: false,
      renderCell: (params) => {
        return (
          <CustomStageTypeCell
            params={params}
            blank_add_cell={<BlankAddCell />}
            list={pRStatusColorList}
          />
        );
      },
      // renderEditCell: (params) => {
      //   return (
      //     <CustomEditSelectCell
      //       params={params}
      //       table_name={table_name}
      //       required_fields={[
      //         { field: "vendor_name", headerName: "Vendor Name" },
      //         { field: "sales_id", headerName: "Project Name" },
      //         { field: "amount_currency", headerName: "Currency" },
      //         { field: "po_date", headerName: "Submitted Date" },
      //       ]}
      //       approval_list={pRStatusColorList.filter((c) => {
      //         const { name } = c;

      //         if (name === "Approved") {
      //           return approval_user;
      //         }

      //         return true;
      //       })}
      //     />
      //   );
      // },
    },
    {
      field: "po_approved_date",
      headerName: "Approved Date",
      data_type: "string",
      type: "date",
      width: 150,
      editable: false,
      renderCell: (params) => {
        return <CustomDateTypeCell params={params} />;
      },
      valueGetter: (params) => {
        const dateValue = params;
        return dateValue ? new Date(params) : null;
      },
    },
  ];
};

export const purchase_order_lineitems_column = (table_name, query_key) => {
  return [
    {
      field: "delete_cell",
      headerName: "",
      width: 40,
      editable: false,
      filterable: false,
      renderCell: (params) => {
        return <LineDeleteBtnCell params={params} table_name={table_name} />;
      },
    },
    {
      field: "po_itm_no",
      headerName: "Item Number",
      data_type: "number",
      width: 150,
      editable: false,
      renderCell: (params) => {
        return (
          <CustomAddLineCell
            params={params}
            table_name={table_name}
            query_key={query_key}
            type="lineitem"
            next_focus_cell_field="mat_code"
            add_text="Add Item"
          />
        );
      },
    },
    {
      field: "mat_code",
      headerName: "Material Code",
      flex: 1,
      minWidth: 150,
      data_type: "string",
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "mat_description",
      headerName: "Description",
      width: 550,
      data_type: "string",
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "mat_uom",
      headerName: "Unit",
      width: 150,
      data_type: "string",
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "request_order_qnty",
      headerName: "Request Qty",
      width: 150,
      data_type: "number",
      type: "number",
      editable: false,
      renderCell: (params) => {
        return <CustomNumberTypeCell params={params} />;
      },
    },
    {
      field: "prev_order_qnty",
      headerName: "Prev Qty",
      width: 150,
      data_type: "number",
      type: "number",
      editable: false,
      renderCell: (params) => {
        return <CustomNumberTypeCell params={params} />;
      },
    },
    {
      field: "available_qnty",
      headerName: "Available Qty",
      width: 150,
      data_type: "number",
      type: "number",
      editable: false,
      renderCell: (params) => {
        return <CustomNumberTypeCell params={params} />;
      },
    },
    {
      field: "order_qnty",
      headerName: "Qty",
      width: 150,
      data_type: "number",
      type: "number",
      editable: true,
      renderCell: (params) => {
        return <CustomNumberTypeCell params={params} />;
      },
      renderEditCell: (params) => {
        return (
          <CustomEditNumberTypeCell
            params={params}
            table_name={table_name}
            target_field_name="available_qnty"
          />
        );
      },
    },
    {
      field: "order_unitprice",
      headerName: "Unit Price",
      width: 150,
      data_type: "number",
      type: "number",
      editable: true,
      renderCell: (params) => {
        return (
          <CustomNumberTypeCell
            params={params}
            blank_add_cell={<PaidOutlinedIcon />}
          />
        );
      },
      renderEditCell: (params) => {
        return (
          <CustomEditNumberTypeCell params={params} table_name={table_name} />
        );
      },
    },
    {
      field: "discount_percent",
      headerName: "Discount Pct(%)",
      width: 150,
      data_type: "number",
      type: "number",
      editable: true,
      renderCell: (params) => {
        return (
          <CustomNumberTypeCell
            params={params}
            blank_add_cell={<PaidOutlinedIcon />}
          />
        );
      },
      renderEditCell: (params) => {
        return (
          <CustomEditNumberTypeCell params={params} table_name={table_name} />
        );
      },
    },
    {
      field: "sub_total_price",
      headerName: "Price",
      width: 150,
      data_type: "number",
      type: "number",
      editable: false,
      renderCell: (params) => {
        return (
          <CustomSubPriceCell
            params={params}
            qty_field="order_qnty"
            unit_price_field="order_unitprice"
          />
        );
      },
    },

    {
      field: "discount_price",
      headerName: "Discount Price",
      width: 150,
      data_type: "number",
      type: "number",
      editable: false,
      renderCell: (params) => {
        return (
          <CustomDiscountPriceCell
            params={params}
            qty_field="order_qnty"
            unit_price_field="order_unitprice"
            discount_percent_field="discount_percent"
          />
        );
      },
    },
    {
      field: "tax_price",
      headerName: "Tax Price",
      width: 150,
      data_type: "number",
      type: "number",
      editable: false,
      renderCell: (params) => {
        return (
          <CustomTaxPriceCell
            params={params}
            qty_field="order_qnty"
            unit_price_field="order_unitprice"
            tax_percent_field="tax_percent"
            discount_percent_field="discount_percent"
          />
        );
      },
    },
    {
      field: "sub_total",
      headerName: "Sub Total",
      width: 150,
      data_type: "number",
      type: "number",
      editable: false,
      renderCell: (params) => {
        return (
          <CustomSumTaxPriceCell
            params={params}
            qty_field="order_qnty"
            unit_price_field="order_unitprice"
            tax_percent_field="tax_percent"
            discount_percent_field="discount_percent"
          />
        );
      },
    },
  ];
};

export const packing_list_column = (table_name, query_key, approval_user) => {
  return [
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      width: 40,
      renderCell: (params) => (
        <CustomDetailPanelToggle id={params.id} value={params.value} />
      ),
    },
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      width: 40,
      renderCell: (params) => {
        return <CustomDetailCheckRow params={params} />;
      },
    },
    {
      field: "data_consolidation_cell",
      headerName: "",
      width: 40,
      editable: false,
      resizable: false,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <PackingListDataConsolidationCell
            params={params}
            table_name={table_name}
            prev_table_name="purchase_order"
          />
        );
      },
    },
    {
      field: "material_approval_cell",
      headerName: "",
      width: 40,
      editable: false,
      resizable: false,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return <MaterialApprovalCell params={params} table_name={table_name} />;
      },
    },
    {
      field: "material_approval_request_cell",
      headerName: "",
      width: 40,
      editable: false,
      resizable: false,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <MaterialApprovalRequestCell
            params={params}
            table_name={table_name}
            required_fields={[
              // { field: "vendor_name", headerName: "Vendor Name" },
              { field: "receive_date", headerName: "Plan Receive Date" },
              { field: "inv_name", headerName: "Inventory Name" },
              { field: "import_type", headerName: "Import Type" },
              { field: "import_date", headerName: "Import Date" },
            ]}
          />
        );
      },
    },
    {
      field: "qr_code_create_cell",
      headerName: "",
      width: 40,
      editable: false,
      resizable: false,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <QRCodeCreateBtnCell
            params={params}
            table_name={table_name}
            query_key={query_key}
          />
        );
      },
    },
    {
      field: "qr_code",
      headerName: "",
      width: 40,
      editable: false,
      resizable: false,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <QRCodeDownLoadBtnCell
            params={params}
            table_name={table_name}
            query_key={query_key}
          />
        );
      },
    },
    {
      field: "pl_no",
      headerName: "PL Number",
      data_type: "string",
      width: 200,
      editable: false,
      renderCell: (params) => {
        return (
          <CustomAddLineCell
            params={params}
            table_name={table_name}
            query_key={query_key}
            type="upper"
            next_focus_cell_field="lead"
            add_text="Add PL"
          />
        );
      },
    },
    {
      field: "lead",
      headerName: "Project Name",
      flex: 1,
      minWidth: 150,
      data_type: "string",
      editable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
      renderEditCell: (params) => {
        return (
          <CustomEidtorSalesPickerTypeCell
            params={params}
            table_name={table_name}
            picker_table_name="sales"
            picker_column_list={["id", "lead", "customer"]}
            picker_main_column_field={["lead"]}
            picker_update_column_list={["lead"]}
            fk_key="sales_id"
          />
        );
      },
    },
    {
      field: "job_number",
      headerName: "Job Number",
      width: 180,
      data_type: "string",
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "vendor_name",
      headerName: "Vendor Name",
      width: 150,
      data_type: "string",
      editable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
      renderEditCell: (params) => {
        return (
          <CustomEidtorPickerTypeCell
            params={params}
            table_name={table_name}
            picker_table_name="vendor"
            picker_column_list={["id", "vendor_name", "reg_no"]}
            picker_main_column_field={["vendor_name"]}
            picker_update_column_list={["vendor_name"]}
            fk_key="vendor_id"
          />
        );
      },
    },
    {
      field: "customer",
      headerName: "Customer Name",
      width: 150,
      data_type: "string",
      editable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
      renderEditCell: (params) => {
        return (
          <CustomEidtorPickerTypeCell
            params={params}
            table_name={table_name}
            picker_table_name="customer"
            picker_column_list={["id", "customer"]}
            picker_main_column_field={["customer"]}
            picker_update_column_list={["customer"]}
            fk_key="customer_id"
          />
        );
      },
    },
    {
      field: "shipment_date",
      headerName: "Shipment Date",
      width: 150,
      data_type: "string",
      type: "date",
      editable: true,
      renderCell: (params) => {
        return <CustomDateTypeCell params={params} />;
      },
      valueGetter: (params) => {
        const dateValue = params;
        return dateValue ? new Date(params) : null;
      },
      renderEditCell: (params) => {
        return (
          <CustomEditDateTypeCell params={params} table_name={table_name} />
        );
      },
    },
    {
      field: "receive_date",
      headerName: "Plan Receive Date",
      width: 150,
      data_type: "string",
      type: "date",
      editable: true,
      renderCell: (params) => {
        return <CustomDateTypeCell params={params} />;
      },
      valueGetter: (params) => {
        const dateValue = params;
        return dateValue ? new Date(params) : null;
      },
      renderEditCell: (params) => {
        return (
          <CustomEditDateTypeCell params={params} table_name={table_name} />
        );
      },
    },
    {
      field: "inv_name",
      headerName: "Inventory Name",
      data_type: "string",
      width: 150,
      editable: true,
      drop_down_list: inventoryColorList.map((c) => {
        const { name } = c;

        return { label: name, value: name };
      }),
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
      renderEditCell: (params) => {
        return (
          <CustomEditDropDownListCell params={params} table_name={table_name} />
        );
      },
    },
    {
      field: "receive_uom",
      headerName: "Receive Unit",
      data_type: "string",
      width: 150,
      editable: true,
      renderCell: (params) => {
        return (
          <CustomNormalTextTypeCell
            params={params}
            blank_add_cell={
              <BlankAddCell right_icon={<CreateOutlinedIcon />} />
            }
          />
        );
      },
      renderEditCell: (params) => {
        return (
          <CustomEditNormalTextTypeCell
            params={params}
            table_name={table_name}
          />
        );
      },
    },
    {
      field: "receive_qnty",
      headerName: "Receive Qty",
      data_type: "string",
      width: 150,
      editable: true,
      renderCell: (params) => {
        return (
          <CustomNumberTypeCell
            params={params}
            blank_add_cell={
              <BlankAddCell right_icon={<CreateOutlinedIcon />} />
            }
          />
        );
      },
      renderEditCell: (params) => {
        return (
          <CustomEditNumberTypeCell params={params} table_name={table_name} />
        );
      },
    },
    {
      field: "import_date",
      headerName: "Import Date",
      width: 150,
      data_type: "string",
      type: "date",
      editable: true,
      renderCell: (params) => {
        return <CustomDateTypeCell params={params} />;
      },
      valueGetter: (params) => {
        const dateValue = params;
        return dateValue ? new Date(params) : null;
      },
      renderEditCell: (params) => {
        return (
          <CustomEditDateTypeCell params={params} table_name={table_name} />
        );
      },
    },
    {
      field: "import_type",
      headerName: "Import Type",
      flex: 1,
      minWidth: 150,
      data_type: "string",
      editable: true,
      drop_down_list: [
        { label: "Imported from abroad", value: "Imported from abroad" },
        {
          label: "Received transfer from Duty-Free Zone / Free Zone",
          value: "Received transfer from Duty-Free Zone / Free Zone",
        },
      ],
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
      renderEditCell: (params) => {
        return (
          <CustomEditDropDownListCell params={params} table_name={table_name} />
        );
      },
    },
    {
      field: "import_declaration_number",
      headerName: "Import Declaration Number",
      width: 150,
      data_type: "",
      editable: true,
      renderCell: (params) => {
        return (
          <CustomNormalTextTypeCell
            params={params}
            blank_add_cell={
              <BlankAddCell right_icon={<CreateOutlinedIcon />} />
            }
          />
        );
      },
      renderEditCell: (params) => {
        return (
          <CustomEditNormalTextTypeCell
            params={params}
            table_name={table_name}
          />
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      data_type: "string",
      width: 150,
      editable: false,
      renderCell: (params) => {
        return (
          <CustomStageTypeCell
            params={params}
            blank_add_cell={<BlankAddCell />}
            list={packingListStatusColorList}
          />
        );
      },
    },
    {
      field: "mat_received",
      headerName: "Received",
      data_type: "string",
      width: 150,
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
  ];
};

export const packing_list_lineitems_column = (table_name, query_key) => {
  return [
    {
      field: "delete_cell",
      headerName: "",
      width: 40,
      editable: false,
      filterable: false,
      renderCell: (params) => {
        return <LineDeleteBtnCell params={params} table_name={table_name} />;
      },
    },
    {
      field: "pl_itm_no",
      headerName: "Item Number",
      data_type: "number",
      width: 150,
      editable: false,
      renderCell: (params) => {
        return (
          <CustomAddLineCell
            params={params}
            table_name={table_name}
            query_key={query_key}
            type="lineitem"
            next_focus_cell_field="mat_code"
            add_text="Add Item"
          />
        );
      },
    },
    {
      field: "mat_code",
      headerName: "Material Code",
      flex: 1,
      minWidth: 150,
      data_type: "string",
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "mat_description",
      headerName: "Description",
      width: 550,
      data_type: "string",
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "mat_uom",
      headerName: "Unit",
      width: 150,
      data_type: "string",
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "order_qnty",
      headerName: "Order Qty",
      width: 150,
      data_type: "number",
      type: "number",
      editable: false,
      renderCell: (params) => {
        return <CustomNumberTypeCell params={params} />;
      },
    },
    {
      field: "receive_qnty",
      headerName: "Packing Qty",
      width: 150,
      data_type: "number",
      type: "number",
      editable: true,
      renderCell: (params) => {
        return <CustomNumberTypeCell params={params} />;
      },
      renderEditCell: (params) => {
        const { row = {} } = params;

        const { po_line_id } = row;

        const target_field_name =
          po_line_id === null ? {} : { target_field_name: "order_qnty" };

        return (
          <CustomEditNumberTypeCell
            params={params}
            table_name={table_name}
            {...target_field_name}
          />
        );
      },
    },
    {
      field: "mat_supplied",
      headerName: "Supplied",
      width: 150,
      data_type: "string",
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
  ];
};

export const material_receive_column = (
  table_name,
  query_key,
  approval_user
) => {
  return [
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      width: 40,
      renderCell: (params) => (
        <CustomDetailPanelToggle id={params.id} value={params.value} />
      ),
    },
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      width: 40,
      renderCell: (params) => {
        return <CustomDetailCheckRow params={params} />;
      },
    },
    {
      field: "data_consolidation_cell",
      headerName: "",
      width: 40,
      editable: false,
      resizable: false,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <DataConsolidationCell
            params={params}
            table_name={table_name}
            prev_table_name="packing_list"
          />
        );
      },
    },
    {
      field: "mr_no",
      headerName: "MR Number",
      data_type: "string",
      width: 200,
      editable: false,
      renderCell: (params) => {
        return (
          <CustomAddLineCell
            params={params}
            table_name={table_name}
            query_key={query_key}
            type="upper"
            next_focus_cell_field="lead"
            add_text="Add MR"
          />
        );
      },
    },
    {
      field: "lead",
      headerName: "Project Name",
      flex: 1,
      minWidth: 150,
      data_type: "string",
      editable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
      renderEditCell: (params) => {
        return (
          <CustomEidtorSalesPickerTypeCell
            params={params}
            table_name={table_name}
            picker_table_name="sales"
            picker_column_list={["id", "lead", "customer"]}
            picker_main_column_field={["lead"]}
            picker_update_column_list={["lead"]}
            fk_key="sales_id"
          />
        );
      },
    },
    {
      field: "job_number",
      headerName: "Job Number",
      width: 180,
      data_type: "string",
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "vendor_name",
      headerName: "Vendor Name",
      width: 150,
      data_type: "string",
      editable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
      renderEditCell: (params) => {
        return (
          <CustomEidtorPickerTypeCell
            params={params}
            table_name={table_name}
            picker_table_name="vendor"
            picker_column_list={["id", "vendor_name", "reg_no"]}
            picker_main_column_field={["vendor_name"]}
            picker_update_column_list={["vendor_name"]}
            fk_key="vendor_id"
          />
        );
      },
    },
    {
      field: "customer",
      headerName: "Customer Name",
      width: 150,
      data_type: "string",
      editable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
      renderEditCell: (params) => {
        return (
          <CustomEidtorPickerTypeCell
            params={params}
            table_name={table_name}
            picker_table_name="customer"
            picker_column_list={["id", "customer"]}
            picker_main_column_field={["customer"]}
            picker_update_column_list={["customer"]}
            fk_key="customer_id"
          />
        );
      },
    },
    {
      field: "receive_date",
      headerName: "Arrived Date",
      width: 150,
      data_type: "string",
      type: "date",
      editable: true,
      renderCell: (params) => {
        return <CustomDateTypeCell params={params} />;
      },
      valueGetter: (params) => {
        const dateValue = params;
        return dateValue ? new Date(params) : null;
      },
      renderEditCell: (params) => {
        return (
          <CustomEditDateTypeCell params={params} table_name={table_name} />
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      data_type: "string",
      width: 150,
      editable: false,
      renderCell: (params) => {
        return (
          <CustomStageTypeCell
            params={params}
            blank_add_cell={<BlankAddCell />}
            list={materialReceivedSatusColorList}
          />
        );
      },
      // renderEditCell: (params) => {
      //   return (
      //     <CustomEditSelectCell
      //       params={params}
      //       table_name={table_name}
      //       required_fields={[
      //         { field: "vendor_name", headerName: "Vendor Name" },
      //         { field: "receive_date", headerName: "Arrived Date" },
      //       ]}
      //       approval_list={materialReceivedSatusColorList.filter((c) => {
      //         const { name } = c;

      //         if (name === "Inspected") {
      //           return approval_user;
      //         }

      //         return true;
      //       })}
      //     />
      //   );
      // },
    },
    {
      field: "has_issue",
      headerName: "Has Issue",
      data_type: "number",
      width: 150,
      editable: false,
      renderCell: (params) => {
        return (
          <CustomReceivedCheckBoxCell params={params} table_name={table_name} />
        );
      },
    },
  ];
};

export const material_receive_lineitems_column = (table_name, query_key) => {
  return [
    {
      field: "delete_cell",
      headerName: "",
      width: 40,
      editable: false,
      filterable: false,
      renderCell: (params) => {
        return <LineDeleteBtnCell params={params} table_name={table_name} />;
      },
    },
    {
      field: "mr_itm_no",
      headerName: "Item Number",
      data_type: "number",
      width: 150,
      editable: false,
      renderCell: (params) => {
        return (
          <CustomAddLineCell
            params={params}
            table_name={table_name}
            query_key={query_key}
            type="lineitem"
            next_focus_cell_field="mat_code"
            add_text="Add Item"
          />
        );
      },
    },
    {
      field: "mat_code",
      headerName: "Material Code",
      flex: 1,
      minWidth: 150,
      data_type: "string",
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "mat_description",
      headerName: "Description",
      width: 550,
      data_type: "string",
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "mat_uom",
      headerName: "Unit",
      width: 150,
      data_type: "string",
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "order_qnty",
      headerName: "Order Qty",
      width: 150,
      data_type: "number",
      type: "number",
      editable: false,
      renderCell: (params) => {
        return <CustomNumberTypeCell params={params} />;
      },
    },
    {
      field: "prev_stock_qnty",
      headerName: "Prev Arrived Qty",
      width: 150,
      data_type: "number",
      type: "number",
      editable: false,
      renderCell: (params) => {
        return <CustomNumberTypeCell params={params} />;
      },
    },
    {
      field: "receive_qnty",
      headerName: "Packing List Qty",
      width: 150,
      data_type: "number",
      type: "number",
      editable: false,
      renderCell: (params) => {
        return <CustomNumberTypeCell params={params} />;
      },
    },
    {
      field: "stock_update_qnty",
      headerName: "Arrived Qty",
      width: 150,
      data_type: "number",
      type: "number",
      editable: true,
      renderCell: (params) => {
        return <CustomNumberTypeCell params={params} />;
      },
      renderEditCell: (params) => {
        return (
          <CustomEditNumberTypeCell
            params={params}
            table_name={table_name}
            target_field_name="receive_qnty"
          />
        );
      },
    },
    {
      field: "disqualified_qnty",
      headerName: "Disq Qty",
      width: 150,
      data_type: "number",
      type: "number",
      editable: true,
      renderCell: (params) => {
        return <CustomNumberTypeCell params={params} />;
      },
      renderEditCell: (params) => {
        return (
          <CustomEditNumberTypeCell
            params={params}
            table_name={table_name}
            target_field_name="stock_update_qnty"
          />
        );
      },
    },
    {
      field: "mat_inspection",
      headerName: "Insepction",
      data_type: "string",
      width: 150,
      editable: false,
      renderCell: (params) => {
        return <CustomCheckBoxCell params={params} table_name={table_name} />;
      },
    },
    {
      field: "has_issue",
      headerName: "Has Issue",
      data_type: "number",
      width: 150,
      editable: false,
      renderCell: (params) => {
        return (
          <CustomReceivedCheckBoxCell params={params} table_name={table_name} />
        );
      },
    },
  ];
};

export const material_release_column = (
  table_name,
  query_key,
  approval_user
) => {
  return [
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      width: 40,
      renderCell: (params) => (
        <CustomDetailPanelToggle id={params.id} value={params.value} />
      ),
    },
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,

      width: 40,
      renderCell: (params) => {
        return <CustomDetailCheckRow params={params} />;
      },
    },
    {
      field: "data_consolidation_cell",
      headerName: "",
      width: 40,
      editable: false,
      resizable: false,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <ReleaseDataConsolidationCell
            params={params}
            table_name={table_name}
          />
        );
      },
    },
    {
      field: "material_approval_cell",
      headerName: "",
      width: 40,
      editable: false,
      resizable: false,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return <MaterialApprovalCell params={params} table_name={table_name} />;
      },
    },
    {
      field: "material_approval_request_cell",
      headerName: "",
      width: 40,
      editable: false,
      resizable: false,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <MaterialApprovalRequestCell
            params={params}
            table_name={table_name}
            required_fields={[
              { field: "shop_name", headerName: "Shop Name" },
              { field: "release_date", headerName: "Release Date" },
            ]}
          />
        );
      },
    },
    {
      field: "mr_no",
      headerName: "MRE Number",
      data_type: "string",
      width: 200,
      editable: false,
      renderCell: (params) => {
        return (
          <CustomAddLineCell
            params={params}
            table_name={table_name}
            query_key={query_key}
            type="upper"
            next_focus_cell_field="lead"
            add_text="Add MRE"
          />
        );
      },
    },
    {
      field: "lead",
      headerName: "Project Name",
      flex: 1,
      minWidth: 150,
      data_type: "string",
      editable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
      renderEditCell: (params) => {
        return (
          <CustomEidtorSalesPickerTypeCell
            params={params}
            table_name={table_name}
            picker_table_name="sales"
            picker_column_list={["id", "lead", "customer"]}
            picker_main_column_field={["lead"]}
            picker_update_column_list={["lead"]}
            fk_key="sales_id"
          />
        );
      },
    },
    {
      field: "job_number",
      headerName: "Job Number",
      width: 180,
      data_type: "string",
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "shop_name",
      headerName: "Shop Name",
      data_type: "string",
      width: 150,
      editable: true,
      drop_down_list: shopColorList.map((c) => {
        const { name } = c;

        return { label: name, value: name };
      }),
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
      renderEditCell: (params) => {
        return (
          <CustomEditDropDownListCell params={params} table_name={table_name} />
        );
      },
    },

    {
      field: "release_date",
      headerName: "Release Date",
      width: 150,
      data_type: "string",
      type: "date",
      editable: true,
      renderCell: (params) => {
        return <CustomDateTypeCell params={params} />;
      },
      valueGetter: (params) => {
        const dateValue = params;
        return dateValue ? new Date(params) : null;
      },
      renderEditCell: (params) => {
        return (
          <CustomEditDateTypeCell params={params} table_name={table_name} />
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      data_type: "string",
      width: 150,
      editable: false,
      renderCell: (params) => {
        return (
          <CustomStageTypeCell
            params={params}
            blank_add_cell={<BlankAddCell />}
            list={materialReleaseSatusColorList}
          />
        );
      },
      // renderEditCell: (params) => {
      //   return (
      //     <CustomEditSelectCell
      //       params={params}
      //       table_name={table_name}
      //       required_fields={[
      //         { field: "shop_name", headerName: "Shop Name" },
      //         { field: "release_date", headerName: "Release Date" },
      //       ]}
      //       approval_list={materialReleaseSatusColorList.filter((c) => {
      //         const { name } = c;

      //         if (name === "Approved") {
      //           return approval_user;
      //         }

      //         return true;
      //       })}
      //     />
      //   );
      // },
    },
  ];
};

export const material_release_lineitems_column = (table_name, query_key) => {
  return [
    {
      field: "delete_cell",
      headerName: "",
      width: 40,
      editable: false,
      filterable: false,
      renderCell: (params) => {
        return <LineDeleteBtnCell params={params} table_name={table_name} />;
      },
    },
    {
      field: "mr_itm_no",
      headerName: "Item Number",
      data_type: "number",
      width: 150,
      editable: false,
      renderCell: (params) => {
        return (
          <CustomAddLineCell
            params={params}
            table_name={table_name}
            query_key={query_key}
            type="lineitem"
            next_focus_cell_field="mat_code"
            add_text="Add Item"
          />
        );
      },
    },
    {
      field: "mat_code",
      headerName: "Material Code",
      flex: 1,
      minWidth: 150,
      data_type: "string",
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "mat_description",
      headerName: "Description",
      width: 550,
      data_type: "string",
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "mat_uom",
      headerName: "Unit",
      width: 150,
      data_type: "string",
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "inv_qnty",
      headerName: "Inventory Qty",
      width: 150,
      data_type: "number",
      type: "number",
      editable: false,
      renderCell: (params) => {
        return <CustomNumberTypeCell params={params} />;
      },
    },
    {
      field: "release_qnty",
      headerName: "Release Qty",
      width: 150,
      data_type: "number",
      type: "number",
      editable: true,
      renderCell: (params) => {
        return <CustomNumberTypeCell params={params} />;
      },
      renderEditCell: (params) => {
        return (
          <CustomEditNumberTypeCell
            params={params}
            table_name={table_name}
            target_field_name="inv_qnty"
          />
        );
      },
    },
    {
      field: "inv_name",
      headerName: "Inventory Name",
      data_type: "string",
      width: 150,
      editable: false,
      drop_down_list: inventoryColorList.map((c) => {
        const { name } = c;

        return { label: name, value: name };
      }),
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
      renderEditCell: (params) => {
        return (
          <CustomEditDropDownListCell params={params} table_name={table_name} />
        );
      },
    },
  ];
};

export const sales_lineitems_columns = (table_name, query_key) => {
  return [
    {
      field: "description",
      headerName: "Description",
      width: 250,
      data_type: "string",
      editable: true,
      filterable: false,
      renderCell: (params) => {
        return (
          <CustomLeadCell
            params={params}
            table_name={table_name}
            query_key={query_key}
          />
        );
      },
      renderEditCell: (params) => {
        return <CustomEditLeadCell params={params} table_name={table_name} />;
      },
    },
    {
      field: "type",
      headerName: "type",
      // width: 150,
      flex: 1,
      minWidth: 150,
      data_type: "",
      editable: true,
      renderCell: (params) => {
        return (
          <CustomNormalTextTypeCell
            params={params}
            blank_add_cell={
              <BlankAddCell right_icon={<CreateOutlinedIcon />} />
            }
          />
        );
      },
      renderEditCell: (params) => {
        return (
          <CustomEditNormalTextTypeCell
            params={params}
            table_name={table_name}
          />
        );
      },
    },
  ];
};

export const material_inventory_column = () => {
  return [
    {
      field: "mat_code",
      headerName: "Material Code",
      width: 150,
      data_type: "string",
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "mat_description",
      headerName: "Description",
      width: 550,
      data_type: "string",
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },

    {
      field: "mat_category",
      headerName: "Category",
      data_type: "string",
      width: 150,
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },

    {
      field: "mat_uom",
      headerName: "Unit",
      data_type: "string",
      width: 150,
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "total_order_qnty_request",
      headerName: "Request Qty",
      data_type: "string",
      type: "number",
      width: 150,
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNumberTypeCell params={params} />;
      },
    },
    {
      field: "total_order_qnty_order",
      headerName: "PO Qty",
      data_type: "number",
      type: "number",
      width: 150,

      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNumberTypeCell params={params} />;
      },
    },
    {
      field: "total_receive_qnty",
      headerName: "Receive Qty",
      data_type: "number",
      type: "number",
      width: 150,
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNumberTypeCell params={params} />;
      },
    },
    {
      field: "total_release_qnty",
      headerName: "Release Qty",
      data_type: "number",
      type: "number",
      width: 150,
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNumberTypeCell params={params} />;
      },
    },
    {
      field: "total_loss_qnty",
      headerName: "Loss Qty",
      data_type: "number",
      type: "number",
      width: 150,
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNumberTypeCell params={params} />;
      },
    },
    {
      field: "total_destroyed_qnty",
      headerName: "Destroyed Qty",
      data_type: "number",
      type: "number",
      width: 150,
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNumberTypeCell params={params} />;
      },
    },
    {
      field: "in_stock",
      headerName: "In Stock",
      data_type: "number",
      type: "number",
      width: 150,
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNumberTypeCell params={params} />;
      },
    },
  ];
};

export const product_export_column = (table_name, query_key, approval_user) => {
  return [
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      width: 40,
      renderCell: (params) => (
        <CustomDetailPanelToggle id={params.id} value={params.value} />
      ),
    },
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,

      width: 40,
      renderCell: (params) => {
        return <CustomDetailCheckRow params={params} />;
      },
    },
    {
      field: "data_consolidation_cell",
      headerName: "",
      width: 40,
      editable: false,
      resizable: false,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <DataConsolidationCell
            params={params}
            table_name={table_name}
            prev_table_name="material_release"
          />
        );
      },
    },
    {
      field: "material_approval_cell",
      headerName: "",
      width: 40,
      editable: false,
      resizable: false,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return <MaterialApprovalCell params={params} table_name={table_name} />;
      },
    },
    {
      field: "material_approval_request_cell",
      headerName: "",
      width: 40,
      editable: false,
      resizable: false,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <MaterialApprovalRequestCell
            params={params}
            table_name={table_name}
            required_fields={[
              { field: "weight_currency", headerName: "Weight Currency" },
              { field: "weight", headerName: "Weight" },
              { field: "unit", headerName: "Unit" },
              { field: "amount_currency", headerName: "Amount Currency" },
              { field: "amount", headerName: "Amount" },
              { field: "unit_qty", headerName: "Qty" },
              { field: "export_type", headerName: "Export Type" },
              {
                field: "destination_address",
                headerName: "Destination Address",
              },
            ]}
          />
        );
      },
    },
    {
      field: "pe_no",
      headerName: "PE Number",
      data_type: "string",
      width: 200,
      editable: false,
      renderCell: (params) => {
        return (
          <CustomAddLineCell
            params={params}
            table_name={table_name}
            query_key={query_key}
            type="upper"
            next_focus_cell_field="lead"
            add_text="Add PE"
          />
        );
      },
    },
    {
      field: "lead",
      headerName: "Project Name",
      flex: 1,
      minWidth: 150,
      data_type: "string",
      editable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
      renderEditCell: (params) => {
        return (
          <CustomEidtorSalesPickerTypeCell
            params={params}
            table_name={table_name}
            picker_table_name="sales"
            picker_column_list={["id", "lead", "customer"]}
            picker_main_column_field={["lead"]}
            picker_update_column_list={["lead", "customer"]}
            fk_key="sales_id"
          />
        );
      },
    },
    {
      field: "job_number",
      headerName: "Job Number",
      flex: 1,
      minWidth: 150,
      data_type: "string",
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "customer",
      headerName: "Customer",
      width: 150,
      data_type: "string",
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "destination_address",
      headerName: "Destination Address",
      width: 150,
      data_type: "string",
      editable: true,
      renderCell: (params) => {
        return (
          <CustomNormalTextTypeCell
            params={params}
            blank_add_cell={
              <BlankAddCell right_icon={<LocationOnOutlinedIcon />} />
            }
          />
        );
      },
      renderEditCell: (params) => {
        return (
          <CustomEditNormalTextTypeCell
            params={params}
            table_name={table_name}
          />
        );
      },
    },
    {
      field: "export_type",
      headerName: "Export Type",
      flex: 1,
      minWidth: 150,
      data_type: "string",
      editable: true,
      drop_down_list: [
        { label: "Exported abroad", value: "Exported abroad" },
        { label: "Paid tax", value: "Paid tax" },
        { label: "Transferred out", value: "Transferred out" },
        { label: "Destroyed/Donated", value: "Destroyed/Donated" },
      ],
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
      renderEditCell: (params) => {
        return (
          <CustomEditDropDownListCell params={params} table_name={table_name} />
        );
      },
    },

    {
      field: "unit",
      headerName: "Unit",
      width: 150,
      data_type: "",
      editable: true,
      renderCell: (params) => {
        return (
          <CustomNormalTextTypeCell
            params={params}
            blank_add_cell={
              <BlankAddCell right_icon={<CreateOutlinedIcon />} />
            }
          />
        );
      },
      renderEditCell: (params) => {
        return (
          <CustomEditNormalTextTypeCell
            params={params}
            table_name={table_name}
          />
        );
      },
    },
    {
      field: "unit_qty",
      headerName: "Qty",
      width: 150,
      data_type: "number",
      type: "number",
      editable: true,
      renderCell: (params) => {
        return <CustomNumberTypeCell params={params} />;
      },
      renderEditCell: (params) => {
        return (
          <CustomEditNumberTypeCell params={params} table_name={table_name} />
        );
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 150,
      data_type: "number",
      editable: true,
      renderCell: (params) => {
        return (
          <CustomAmountTypeCell
            params={params}
            blank_add_cell={<PaidOutlinedIcon />}
          />
        );
      },

      renderEditCell: (params) => {
        return (
          <CustomEditAmountTypeCell params={params} table_name={table_name} />
        );
      },
    },
    {
      field: "tax_percent",
      headerName: "Tax Pct(%)",
      width: 150,
      data_type: "number",
      type: "number",
      editable: true,
      renderCell: (params) => {
        return (
          <CustomNumberTypeCell
            params={params}
            blank_add_cell={<PaidOutlinedIcon />}
          />
        );
      },
      renderEditCell: (params) => {
        return (
          <CustomEditNumberTypeCell params={params} table_name={table_name} />
        );
      },
    },
    {
      field: "weight_currency",
      headerName: "Weight Currency",
      width: 150,
      data_type: "",
      editable: true,
      drop_down_list: [
        { label: "g", value: "g" },
        { label: "kg", value: "kg" },
        { label: "t", value: "t" },
      ],
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
      renderEditCell: (params) => {
        return (
          <CustomEditDropDownListCell params={params} table_name={table_name} />
        );
      },
    },
    {
      field: "weight",
      headerName: "Weight",
      width: 150,
      data_type: "",
      editable: true,
      renderCell: (params) => {
        return (
          <CustomNumberTypeCell
            params={params}
            blank_add_cell={<PaidOutlinedIcon />}
          />
        );
      },
      renderEditCell: (params) => {
        return (
          <CustomEditNumberTypeCell params={params} table_name={table_name} />
        );
      },
    },
    {
      field: "tax_payment_number",
      headerName: "Tax Payment Number",
      width: 150,
      data_type: "",
      editable: true,
      renderCell: (params) => {
        return (
          <CustomNormalTextTypeCell
            params={params}
            blank_add_cell={
              <BlankAddCell right_icon={<CreateOutlinedIcon />} />
            }
          />
        );
      },
      renderEditCell: (params) => {
        return (
          <CustomEditNormalTextTypeCell
            params={params}
            table_name={table_name}
          />
        );
      },
    },
    {
      field: "export_declaration_number",
      headerName: "Export Declaration Number",
      width: 150,
      data_type: "",
      editable: true,
      renderCell: (params) => {
        return (
          <CustomNormalTextTypeCell
            params={params}
            blank_add_cell={
              <BlankAddCell right_icon={<CreateOutlinedIcon />} />
            }
          />
        );
      },
      renderEditCell: (params) => {
        return (
          <CustomEditNormalTextTypeCell
            params={params}
            table_name={table_name}
          />
        );
      },
    },
    {
      field: "export_date",
      headerName: "Export Date",
      width: 150,
      data_type: "string",
      type: "date",
      editable: true,
      renderCell: (params) => {
        return <CustomDateTypeCell params={params} />;
      },
      valueGetter: (params) => {
        const dateValue = params;
        return dateValue ? new Date(params) : null;
      },
      renderEditCell: (params) => {
        return (
          <CustomEditDateTypeCell params={params} table_name={table_name} />
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      data_type: "string",
      width: 150,
      editable: false,
      renderCell: (params) => {
        return (
          <CustomStageTypeCell
            params={params}
            blank_add_cell={<BlankAddCell />}
            list={rfqSatusColorList}
          />
        );
      },
      // renderEditCell: (params) => {
      //   return (
      //     <CustomEditSelectCell
      //       params={params}
      //       table_name={table_name}
      //       required_fields={[
      //         { field: "weight_currency", headerName: "Weight Currency" },
      //         { field: "weight", headerName: "Weight" },
      //         { field: "unit", headerName: "Unit" },
      //         { field: "amount_currency", headerName: "Amount Currency" },
      //         { field: "amount", headerName: "Amount" },
      //         { field: "unit_qty", headerName: "Qty" },
      //         { field: "export_type", headerName: "Export Type" },
      //       ]}
      //       approval_list={rfqSatusColorList.filter((c) => {
      //         const { name } = c;

      //         if (name === "Approved") {
      //           return approval_user;
      //         }

      //         return true;
      //       })}
      //     />
      //   );
      // },
    },
  ];
};

export const product_export_lineitems_column = (table_name, query_key) => {
  return [
    {
      field: "delete_cell",
      headerName: "",
      width: 40,
      editable: false,
      filterable: false,
      renderCell: (params) => {
        return <LineDeleteBtnCell params={params} table_name={table_name} />;
      },
    },
    {
      field: "pe_itm_no",
      headerName: "Item Number",
      data_type: "number",
      width: 150,
      editable: false,
      renderCell: (params) => {
        return (
          <CustomAddLineCell
            params={params}
            table_name={table_name}
            query_key={query_key}
            type="lineitem"
            next_focus_cell_field="mat_code"
            add_text="Add Item"
          />
        );
      },
    },
    {
      field: "mat_code",
      headerName: "Material Code",
      flex: 1,
      minWidth: 150,
      data_type: "string",
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "mat_description",
      headerName: "Description",
      width: 550,
      data_type: "string",
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "mat_uom",
      headerName: "Unit",
      width: 150,
      data_type: "string",
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "release_qnty",
      headerName: "Release Qty",
      width: 150,
      data_type: "number",
      type: "number",
      editable: false,
      renderCell: (params) => {
        return <CustomNumberTypeCell params={params} />;
      },
    },
    {
      field: "prev_export_qnty",
      headerName: "Prev Export Qty",
      width: 150,
      data_type: "number",
      type: "number",
      editable: false,
      renderCell: (params) => {
        return <CustomNumberTypeCell params={params} />;
      },
    },
    {
      field: "available_export_qnty",
      headerName: "Available Export Qty",
      width: 150,
      data_type: "number",
      type: "number",
      editable: false,
      renderCell: (params) => {
        return <CustomNumberTypeCell params={params} />;
      },
    },
    {
      field: "export_qnty",
      headerName: "Export Qty",
      width: 150,
      data_type: "number",
      type: "number",
      editable: true,
      renderCell: (params) => {
        return <CustomNumberTypeCell params={params} />;
      },
      renderEditCell: (params) => {
        return (
          <CustomEditNumberTypeCell
            params={params}
            table_name={table_name}
            target_field_name="available_export_qnty"
          />
        );
      },
    },
  ];
};

export const inventory_quantity_control_column = (
  table_name,
  query_key,
  approval_user
) => {
  return [
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      width: 40,
      renderCell: (params) => (
        <CustomDetailPanelToggle id={params.id} value={params.value} />
      ),
    },
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      width: 40,
      renderCell: (params) => {
        return <CustomDetailCheckRow params={params} />;
      },
    },
    {
      field: "data_consolidation_cell",
      headerName: "",
      width: 40,
      editable: false,
      resizable: false,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <QtyControlDataConsolidationCell
            params={params}
            table_name={table_name}
          />
        );
      },
    },
    {
      field: "material_approval_cell",
      headerName: "",
      width: 40,
      editable: false,
      resizable: false,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return <MaterialApprovalCell params={params} table_name={table_name} />;
      },
    },
    {
      field: "material_approval_request_cell",
      headerName: "",
      width: 40,
      editable: false,
      resizable: false,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <MaterialApprovalRequestCell
            params={params}
            table_name={table_name}
            required_fields={[
              { field: "inv_name", headerName: "Inventory Name" },
              { field: "control_date", headerName: "Date" },
              { field: "name", headerName: "Name" },
            ]}
          />
        );
      },
    },
    {
      field: "iqc_no",
      headerName: "IQC Number",
      data_type: "string",
      width: 200,
      editable: false,
      renderCell: (params) => {
        return (
          <CustomAddLineCell
            params={params}
            table_name={table_name}
            query_key={query_key}
            type="upper"
            next_focus_cell_field="lead"
            add_text="Add IQC"
          />
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      width: 150,
      data_type: "string",
      editable: true,
      renderCell: (params) => {
        return (
          <CustomNormalTextTypeCell
            params={params}
            blank_add_cell={
              <BlankAddCell right_icon={<CreateOutlinedIcon />} />
            }
          />
        );
      },
      renderEditCell: (params) => {
        return (
          <CustomEditNormalTextTypeCell
            params={params}
            table_name={table_name}
          />
        );
      },
    },
    {
      field: "inv_name",
      headerName: "Inventory Name",
      data_type: "string",
      width: 150,
      editable: true,
      drop_down_list: inventoryColorList.map((c) => {
        const { name } = c;

        return { label: name, value: name };
      }),
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
      renderEditCell: (params) => {
        return (
          <CustomEditDropDownListCell params={params} table_name={table_name} />
        );
      },
    },
    {
      field: "control_date",
      headerName: "Date",
      width: 150,
      data_type: "string",
      type: "date",
      editable: true,
      renderCell: (params) => {
        return <CustomDateTypeCell params={params} />;
      },
      valueGetter: (params) => {
        const dateValue = params;
        return dateValue ? new Date(params) : null;
      },
      renderEditCell: (params) => {
        return (
          <CustomEditDateTypeCell params={params} table_name={table_name} />
        );
      },
    },
    {
      field: "loss_qnty",
      headerName: "Loss Qty",
      width: 150,
      data_type: "number",
      type: "number",
      editable: true,
      renderCell: (params) => {
        return <CustomNumberTypeCell params={params} />;
      },
    },
    {
      field: "destroyed_qnty",
      headerName: "Destroyed Qty",
      width: 150,
      data_type: "number",
      type: "number",
      editable: true,
      renderCell: (params) => {
        return <CustomNumberTypeCell params={params} />;
      },
    },
    {
      field: "status",
      headerName: "Status",
      data_type: "string",
      width: 150,
      editable: false,
      renderCell: (params) => {
        return (
          <CustomStageTypeCell
            params={params}
            blank_add_cell={<BlankAddCell />}
            list={invQtyCtrlSatusColorList}
          />
        );
      },
      // renderEditCell: (params) => {
      //   return (
      //     <CustomEditSelectCell
      //       params={params}
      //       table_name={table_name}
      //       required_fields={[
      //         { field: "inv_name", headerName: "Inventory Name" },
      //         { field: "control_date", headerName: "Date" },
      //         { field: "name", headerName: "Name" },
      //       ]}
      //       approval_list={invQtyCtrlSatusColorList.filter((c) => {
      //         const { name } = c;

      //         if (name === "Approved") {
      //           return approval_user;
      //         }

      //         return true;
      //       })}
      //     />
      //   );
      // },
    },
  ];
};

export const inventory_quantity_control_lineitems_column = (
  table_name,
  query_key
) => {
  return [
    {
      field: "delete_cell",
      headerName: "",
      width: 40,
      editable: false,
      filterable: false,
      renderCell: (params) => {
        return <LineDeleteBtnCell params={params} table_name={table_name} />;
      },
    },
    {
      field: "iqc_itm_no",
      headerName: "Item Number",
      data_type: "number",
      width: 150,
      editable: false,
      renderCell: (params) => {
        return (
          <CustomAddLineCell
            params={params}
            table_name={table_name}
            query_key={query_key}
            type="lineitem"
            next_focus_cell_field="mat_code"
            add_text="Add Item"
          />
        );
      },
    },
    {
      field: "mat_code",
      headerName: "Material Code",
      flex: 1,
      minWidth: 150,
      data_type: "string",
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "mat_description",
      headerName: "Description",
      width: 550,
      data_type: "string",
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "mat_uom",
      headerName: "Unit",
      width: 150,
      data_type: "string",
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "stock_qnty",
      headerName: "Stock Qty",
      width: 150,
      data_type: "number",
      type: "number",
      editable: true,
      renderCell: (params) => {
        return <CustomNumberTypeCell params={params} />;
      },
    },
    {
      field: "loss_qnty",
      headerName: "Loss Qty",
      width: 150,
      data_type: "number",
      type: "number",
      editable: true,
      renderCell: (params) => {
        return <CustomNumberTypeCell params={params} />;
      },
      renderEditCell: (params) => {
        return (
          <CustomEditNumberTypeCell
            params={params}
            table_name={table_name}
            target_field_name="stock_qnty"
          />
        );
      },
    },
    {
      field: "destroyed_qnty",
      headerName: "Destroyed Qty",
      width: 150,
      data_type: "number",
      type: "number",
      editable: true,
      renderCell: (params) => {
        return <CustomNumberTypeCell params={params} />;
      },
      renderEditCell: (params) => {
        return (
          <CustomEditNumberTypeCell
            params={params}
            table_name={table_name}
            target_field_name="stock_qnty"
          />
        );
      },
    },
  ];
};

const CustomDetailCheckRow = ({ params }) => {
  const { id, api } = params;

  if (id === Infinity) {
    return null;
  }

  return (
    <input
      type="checkbox"
      className="custom-check-box"
      checked={params.api.isRowSelected(params.id)}
      onChange={(event) => {
        if (event.target.checked) {
          api.selectRow(params.id, true);
        } else {
          api.selectRow(params.id, false);
        }
      }}
    />
  );
};

const amount_sybol_list = Object.keys(amount_currnecy_list).map((c) => {
  return { value: c, name: amount_currnecy_list[c] };
});

const MaterialApprovalRequestCell = ({
  params,
  table_name,
  required_fields,
}) => {
  const { id, row = {} } = params;
  const { status } = row;
  const setValue = useSetRecoilState(MaterialApprovalRequestModalStatus);
  const onClose = useResetRecoilState(MaterialApprovalRequestModalStatus);

  const openModal = () => {
    if (status === "Draft") {
      if (required_fields && required_fields.length > 0) {
        let result = {
          success: true,
          message: "These fields are required values.<br />",
        };

        required_fields.forEach((c, i) => {
          if (!row?.[c.field]) {
            result.success = false;
          }
        });

        if (!result.success) {
          result.message += ` (${required_fields
            .map((c) => c.headerName)
            .join(", ")})`;

          create_alert_fn("warning", result.message);

          return;
        } else {
          setValue((prev) => {
            return { ...prev, open: true, id, onClose, table_name };
          });
        }
      }
    }
  };

  if (id === Infinity) {
    return null;
  }

  return (
    <IconButton
      className="line-delete-cell-btn"
      onClick={openModal}
      disabled={status !== "Draft"}
    >
      <ApprovalRequestIcon disabled={status !== "Draft"} />
    </IconButton>
  );
};

const MaterialApprovalCell = ({ params, table_name }) => {
  const { id, row = {} } = params;
  const { status, approval_id } = row;
  const setValue = useSetRecoilState(MaterialApprovalModalStatus);
  const onClose = useResetRecoilState(MaterialApprovalModalStatus);

  const openModal = () => {
    if (
      status !== "Draft" &&
      approval_id !== null &&
      approval_id !== undefined
    ) {
      console.log("TEST");
      setValue((prev) => {
        return {
          ...prev,
          open: true,
          id: approval_id,
          onClose,
          table_name,
          modal_type: "carbon_copy",
        };
      });
    }
  };

  if (id === Infinity) {
    return null;
  }

  return (
    <IconButton
      className="line-delete-cell-btn"
      onClick={openModal}
      disabled={
        status === "Draft" || approval_id === null || approval_id === undefined
      }
    >
      <DocumentIcon
        disabled={
          status === "Draft" ||
          approval_id === null ||
          approval_id === undefined
        }
      />
    </IconButton>
  );
};

const DocumentIcon = ({ disabled = true }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M13 5.375V12.5C13 13.3284 12.2538 14 11.3333 14H4.66667C3.74619 14 3 13.3284 3 12.5V3.5C3 2.67157 3.74619 2 4.66667 2H9.25L13 5.375ZM10.5 5.375C9.80964 5.375 9.25 4.87132 9.25 4.25V2.75H4.66667C4.20643 2.75 3.83333 3.08579 3.83333 3.5V12.5C3.83333 12.9142 4.20643 13.25 4.66667 13.25H11.3333C11.7936 13.25 12.1667 12.9142 12.1667 12.5V5.375H10.5Z"
        fill={disabled ? "#D0D5DD" : "#344054"}
      />
      <path
        d="M5 6.9376C5 6.71668 5.17909 6.5376 5.4 6.5376H9.6C9.82091 6.5376 10 6.71668 10 6.9376V7.1376C10 7.35851 9.82091 7.5376 9.6 7.5376H5.4C5.17909 7.5376 5 7.35851 5 7.1376V6.9376Z"
        fill={disabled ? "#D0D5DD" : "#344054"}
      />
      <path
        d="M5 8.9376C5 8.71668 5.17909 8.5376 5.4 8.5376H10.6C10.8209 8.5376 11 8.71668 11 8.9376V9.1376C11 9.35851 10.8209 9.5376 10.6 9.5376H5.4C5.17909 9.5376 5 9.35851 5 9.1376V8.9376Z"
        fill={disabled ? "#D0D5DD" : "#344054"}
      />
      <path
        d="M5 10.9376C5 10.7167 5.17909 10.5376 5.4 10.5376H9.6C9.82091 10.5376 10 10.7167 10 10.9376V11.1376C10 11.3585 9.82091 11.5376 9.6 11.5376H5.4C5.17909 11.5376 5 11.3585 5 11.1376V10.9376Z"
        fill={disabled ? "#D0D5DD" : "#344054"}
      />
    </svg>
  );
};

const ApprovalRequestIcon = ({ disabled = true }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="23"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M4.31047 6.08588C4.07937 6.08588 3.89202 6.28661 3.89202 6.53422V6.75838C3.89202 7.00599 4.07937 7.20672 4.31047 7.20672H8.70413C8.93523 7.20672 9.12257 7.00599 9.12257 6.75838V6.53422C9.12257 6.28661 8.93523 6.08588 8.70413 6.08588H4.31047Z"
        fill={disabled ? "#D0D5DD" : "#344054"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2609 8.69232V4.78281L8.33799 1H3.54332C2.5804 1 1.7998 1.75272 1.7998 2.68125V12.7687C1.7998 13.6973 2.5804 14.45 3.54332 14.45H9.13257C9.65403 14.7975 10.2804 15 10.954 15C12.7703 15 14.2428 13.5276 14.2428 11.7112C14.2428 11.5599 14.2326 11.411 14.2128 11.2652C14.185 11.0605 13.9734 10.9454 13.7771 11.0097C13.6004 11.0676 13.5003 11.2512 13.5199 11.4361C13.5295 11.5265 13.5344 11.6183 13.5344 11.7112C13.5344 13.1363 12.3791 14.2916 10.954 14.2916C9.52886 14.2916 8.37356 13.1363 8.37356 11.7112C8.37356 10.2861 9.52886 9.13077 10.954 9.13077C11.3234 9.13077 11.6748 9.20841 11.9925 9.34826C12.1731 9.42774 12.3927 9.37673 12.4941 9.20752C12.5938 9.04128 12.5418 8.82386 12.3668 8.74049C12.3319 8.72383 12.2965 8.70777 12.2609 8.69232ZM11.3891 8.45094V4.78281H9.64563C8.92344 4.78281 8.33799 4.21827 8.33799 3.52187V1.84062H3.54332C3.06186 1.84062 2.67156 2.21698 2.67156 2.68125V12.7687C2.67156 13.233 3.06186 13.6094 3.54332 13.6094H8.26793C7.8883 13.0731 7.6652 12.4182 7.6652 11.7112C7.6652 11.7041 7.66522 11.6971 7.66527 11.69H4.31047C4.07937 11.69 3.89202 11.4893 3.89202 11.2417V11.0175C3.89202 10.7699 4.07937 10.5692 4.31047 10.5692H7.86889C8.02476 10.1483 8.26434 9.76791 8.5674 9.44838H4.31047C4.07937 9.44838 3.89202 9.24765 3.89202 9.00005V8.77588C3.89202 8.52827 4.07937 8.32755 4.31047 8.32755H9.75024C9.89808 8.32755 10.028 8.4097 10.1025 8.53372C10.3741 8.46112 10.6595 8.42241 10.954 8.42241C11.1015 8.42241 11.2468 8.43212 11.3891 8.45094Z"
        fill={disabled ? "#D0D5DD" : "#344054"}
      />
      <path
        d="M14.3245 9.14412C14.4704 9.29003 14.4704 9.52658 14.3245 9.67249L11.0866 12.9104C11.0089 12.9881 10.901 13.0277 10.7916 13.0186C10.6821 13.0095 10.5822 12.9528 10.5184 12.8634L9.27302 11.1199C9.15308 10.952 9.19197 10.7187 9.35988 10.5987C9.52778 10.4788 9.76112 10.5177 9.88105 10.6856L10.8701 12.0702L13.7962 9.14412C13.9421 8.99822 14.1786 8.99822 14.3245 9.14412Z"
        fill={disabled ? "#D0D5DD" : "#344054"}
      />
    </svg>
  );
};

// const UserIcon = () => {
//   return (
//     <svg
//       xmlns="http://www.w3.org/2000/svg"
//       width="19"
//       height="18"
//       viewBox="0 0 19 18"
//       fill="none"
//     >
//       <path
//         d="M4.0415 15.75C4.0415 15.75 2.9165 15.75 2.9165 14.625C2.9165 13.5 4.0415 10.125 9.6665 10.125C15.2915 10.125 16.4165 13.5 16.4165 14.625C16.4165 15.75 15.2915 15.75 15.2915 15.75H4.0415Z"
//         fill="#F9FAFB"
//       />
//       <path
//         d="M9.6665 9C11.5305 9 13.0415 7.48896 13.0415 5.625C13.0415 3.76104 11.5305 2.25 9.6665 2.25C7.80254 2.25 6.2915 3.76104 6.2915 5.625C6.2915 7.48896 7.80254 9 9.6665 9Z"
//         fill="#F9FAFB"
//       />
//     </svg>
//   );
// };
