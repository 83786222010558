import React from "react";
import { InputLabel, TextareaAutosize } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import { labelTextAreaTheme } from "@styles/muiThemes";
import { styled } from "@mui/material/styles";
import "@styles/components.scss";

const LabelTextArea = ({
  label,
  value,
  setValue,

  placeholder,
  error,
  disabled = false,
  readOnly = false,
}) => {
  return (
    <div className="label-input-box">
      <ThemeProvider theme={labelTextAreaTheme}>
        {label && label.length > 0 && (
          <InputLabel
            htmlFor={`label-input-${label}`}
            className="label-input-title"
          >
            {label}
          </InputLabel>
        )}
        <StyledTextarea
          id={`label-input-${label}`}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder={placeholder}
          error={error}
          disabled={disabled}
          readOnly={readOnly}
        />
      </ThemeProvider>
    </div>
  );
};

export default LabelTextArea;

const StyledTextarea = styled(TextareaAutosize)(({ readOnly }) => ({
  boxSizing: "border-box",
  width: "100%",
  padding: "10px",
  outline: "none",
  fontFamily: "Inter",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "24px",
  borderRadius: "8px",
  // backgroundColor: theme.palette.background.paper,
  border: "1px solid var(--Gray-300, #D0D5DD)",
  background: "var(--White, #FFF)",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  resize: readOnly ? "none" : "vertical",
  "&:focus": {
    outline: `1px solid var(--Brand-400, #3FC8FF)`,
    border: "1px solid var(--Brand-400, #3FC8FF)",
    background: "var(--White, #FFF)",
  },
  "&:hover": {
    outline: "1px solid var(--Gray-300, #D0D5DD)",
  },
}));
