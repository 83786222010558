import React, { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useRecoilValue } from "recoil";
import {
  HrWorkPolicyData,
  HrCalendarUsingModalStatus,
} from "@atoms/modalStatus";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import DayPicker from "@components/DatePicker";
import LabelSelect from "@components/LabelSelect";
import CustomTimePicker from "@components/CustomTimePicker";
import ApprovalLineBtn from "./ApprovalLineBtn";
import HorizontalSolidLine from "../HorizontalSolidLine";
import { userPermAxios } from "@utils/customAxios";
import { create_alert_fn } from "@utils/createAlert";

dayjs.extend(customParseFormat);

const WorkTypeMenu = ({
  workTypeData,
  setWorkTypeData,
  workTypePolicyId,
  setWorkTypePolicyId,
  approvalLineData,
  confirmApprovalLineData = () => {},
  resetApprovalLineData = () => {},
  approval_user_list = [],
  selectWorkTypePolicy,
  target_user_id = null,
}) => {
  const {
    day_off_weekday_list,
    working_time,
    attendance_time,
    break_time_start,
    break_time_end,
    work_type_policy_list,
  } = useRecoilValue(HrWorkPolicyData);

  const { startYear, startMonth, startDay, endYear, endMonth, endDay } =
    useRecoilValue(HrCalendarUsingModalStatus);

  const [selectWorkType, setSelectWorkType] = useState("");

  const [startDate, setStartDate] = useState(
    dayjs(`${startYear}-${startMonth}-${startDay}`)
  );
  const [endDate, setEndDate] = useState(
    dayjs(`${endYear}-${endMonth}-${endDay}`)
  );

  const query_work_type_sub_data = useQuery(
    [
      "annual_leave_sub_data",
      startDate.format("YYYY-MM-DD"),
      endDate.format("YYYY-MM-DD"),
      target_user_id,
    ],
    () => {
      return query_fetch_data(startDate, endDate, target_user_id);
    }
  );

  const { data: annualSubData = {}, isLoading } = query_work_type_sub_data;

  const { hoily_day_list = [] } = annualSubData;

  const { policySetting, policy = {} } = selectWorkTypePolicy;

  const { id, overtime_use, offday_count_use, approve_use } = policy;

  const getOffTime = useMemo(() => {
    const workingTimeDayjs = dayjs(working_time, "HH:mm");
    const attendanceTimeDayjs = dayjs(attendance_time, "HH:mm");
    const breakTimeStartDayjs = dayjs(break_time_start, "HH:mm");
    const breakTimeEndDayjs = dayjs(break_time_end, "HH:mm");

    const breakTime = breakTimeEndDayjs.diff(
      breakTimeStartDayjs,
      "hours",
      true
    );

    return attendanceTimeDayjs
      .add(workingTimeDayjs.hour(), "hour")
      .add(workingTimeDayjs.minute(), "minute")
      .add(breakTime, "hour")
      .format("HH:mm");
  }, [working_time, attendance_time, break_time_start, break_time_end]);

  useEffect(() => {
    if (policySetting) {
      const dateDiff = endDate.diff(startDate, "day");

      const workTypeDataArr = [];

      if (dateDiff >= 0) {
        for (let i = 0; i <= dateDiff; i++) {
          const targetDateDayjs = dayjs(startDate).add(i, "days");

          const targetDay = targetDateDayjs.day();
          const targetDate = targetDateDayjs.format("YYYY-MM-DD");

          if (offday_count_use) {
            const insertData = {
              id: i,
              date: targetDate,
              year: targetDateDayjs.format("YYYY"),
              month: targetDateDayjs.format("MM"),
              day: targetDateDayjs.format("DD"),
              startTime: changeDayjsFormat(attendance_time),
              endTime: getOffTime,
              workingTime: calculateWorkingHours(
                attendance_time,
                getOffTime,
                break_time_start,
                break_time_end
              ),
            };

            if (
              day_off_weekday_list.includes(targetDay) ||
              hoily_day_list.includes(targetDate)
            ) {
              insertData.offday_work = true;
            }
            workTypeDataArr.push({ ...insertData });
          } else {
            if (
              !day_off_weekday_list.includes(targetDay) &&
              !hoily_day_list.includes(targetDate)
            ) {
              workTypeDataArr.push({
                id: i,
                date: targetDate,
                year: targetDateDayjs.format("YYYY"),
                month: targetDateDayjs.format("MM"),
                day: targetDateDayjs.format("DD"),
                startTime: changeDayjsFormat(attendance_time),
                endTime: getOffTime,
                workingTime: calculateWorkingHours(
                  attendance_time,
                  getOffTime,
                  break_time_start,
                  break_time_end
                ),
              });
            }
          }
        }
      }

      setWorkTypeData((prev) => {
        return { ...prev, list: workTypeDataArr };
      });
    }

    // eslint-disable-next-line
  }, [
    startDate,
    endDate,
    day_off_weekday_list,
    hoily_day_list,
    selectWorkTypePolicy,
    getOffTime,
  ]);

  const handleStartDate = (e) => {
    if (e.diff(endDate, "days") > 0) {
      return create_alert_fn(
        "warning",
        "The start date cannot be greater than the end date."
      );
    }

    return setStartDate(e);
  };

  const handleEndDate = (e) => {
    if (e.diff(startDate, "days") < 0) {
      return create_alert_fn(
        "warning",
        "The start date cannot be greater than the end date."
      );
    }
    return setEndDate(e);
  };

  const handleWoringTime = (e, data, type) => {
    const value = e;
    const hourValue = e.format("HH:mm");
    const attendanceTimeDayjs = dayjs(attendance_time, "HH:mm");
    const getOffTimeDayjs = dayjs(getOffTime, "HH:mm");
    const { id: targetId } = data;

    const updatedWorkTypeData = workTypeData.list.map((c) => {
      const { id, startTime: prevStartTime, endTime: prevEndTime } = c;

      if (id === targetId) {
        const result = { ...c };

        if (offday_count_use) {
          if (type === "start") {
            result.startTime = hourValue;
            result.workingTime = calculateWorkingHours(
              hourValue,
              prevEndTime,
              break_time_start,
              break_time_end
            );
          }
          if (type === "end") {
            result.endTime = hourValue;
            result.workingTime = calculateWorkingHours(
              prevStartTime,
              hourValue,
              break_time_start,
              break_time_end
            );
          }
        }

        if (!offday_count_use) {
          if (type === "start") {
            if (attendanceTimeDayjs.isBefore(value)) {
              create_alert_fn(
                "warning",
                "The start time cannot be earlier than the attendance time."
              );
            } else {
              result.startTime = hourValue;
              result.workingTime = calculateWorkingHours(
                hourValue,
                prevEndTime,
                break_time_start,
                break_time_end
              );
            }
          }
          if (type === "end") {
            if (getOffTimeDayjs.isAfter(value)) {
              create_alert_fn(
                "warning",
                "The end time cannot be later than the get off time."
              );
            } else {
              result.endTime = hourValue;
              result.workingTime = calculateWorkingHours(
                prevStartTime,
                hourValue,
                break_time_start,
                break_time_end
              );
            }
          }
        }

        return result;
      }

      return c;
    });

    setWorkTypeData((prev) => {
      return { ...prev, list: updatedWorkTypeData };
    });
  };

  if (isLoading) {
    return <div className="using-menu-body" />;
  }

  return (
    <div className="using-menu-body">
      <div className="using-menu-column-box">
        <div className="using-menu-flex-box">
          <div className="using-menu-flex-1-box">
            <div className="using-menu-default-text">{`Working Time: ${changeDayjsFormat(
              working_time
            )}`}</div>
          </div>
          <div className="using-menu-flex-1-box">
            <div className="using-menu-default-text">{`Attendance Time: ${changeDayjsFormat(
              attendance_time
            )}`}</div>
          </div>
        </div>
        <div className="using-menu-flex-box">
          <div className="using-menu-flex-1-box">
            <div className="using-menu-default-text">{`Break Time: ${changeDayjsFormat(
              break_time_start
            )} ~ ${changeDayjsFormat(break_time_end)}`}</div>
          </div>
          <div className="using-menu-flex-1-box">
            <div className="using-menu-default-text">{`Get Off Time: ${changeDayjsFormat(
              getOffTime
            )}`}</div>
          </div>
        </div>
      </div>
      <div className="using-menu-flex-1-box">
        <LabelSelect
          label="Work Type"
          value={workTypePolicyId}
          setValue={setWorkTypePolicyId}
          list={[
            { name: "", value: "" },
            ...work_type_policy_list.map((c) => {
              const { work_type_name, id } = c;

              return {
                name: work_type_name,
                value: id,
              };
            }),
          ]}
        />
      </div>
      {policySetting && (
        <>
          <div className="using-menu-date-range-box">
            <div className="using-menu-date-range">
              <div style={{ width: 90 }} className="using-menu-default-text">
                Start Date:
              </div>
              <DayPicker
                value={startDate}
                onChange={handleStartDate}
                width="calc(100% - 90px)"
              />
            </div>
            <div className="using-menu-date-range">
              <div style={{ width: 90 }} className="using-menu-default-text">
                End Date:
              </div>
              <DayPicker
                value={endDate}
                onChange={handleEndDate}
                width="calc(100% - 90px)"
              />
            </div>
          </div>
          <div className="using-menu-column-box">
            <div className="using-menu-flex-1-box">
              <div className="using-menu-default-text">Working List</div>
            </div>

            {(workTypeData?.list ?? []).map((c, i) => {
              const {
                id,
                date,
                startTime,
                endTime,
                workingTime,
                year,
                month,
                day,
              } = c;

              const week = dayjs(`${year}-${month}-${day}`).format("ddd");

              return (
                <div key={id} className="using-menu-work-list-card">
                  <div className="using-menu-default-text">
                    {`${i + 1}. ${date} (${week}) (${workingTime})`}
                  </div>
                  <div className="using-menu-work-list-range-box">
                    <div>
                      <div className="using-menu-default-text">Start Time:</div>
                      <CustomTimePicker
                        value={dayjs(startTime, "HH:mm")}
                        width={130}
                        onChange={(e) => handleWoringTime(e, c, "start")}
                      />
                    </div>
                    <div>
                      <div className="using-menu-default-text">
                        Get Off Time:
                      </div>
                      <CustomTimePicker
                        value={dayjs(endTime, "HH:mm")}
                        width={130}
                        onChange={(e) => handleWoringTime(e, c, "end")}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {approve_use ? (
            <>
              <HorizontalSolidLine />
              <ApprovalLineBtn
                approvalLineData={approvalLineData}
                confirmApprovalLineData={confirmApprovalLineData}
                approval_user_list={approval_user_list}
              />
            </>
          ) : null}
        </>
      )}
    </div>
  );
};

export default WorkTypeMenu;

const query_fetch_data = async (startDate, endDate, target_user_id) => {
  let result = {
    remaining_annal_leave_count: 0,
    hoily_day_list: [],
  };

  try {
    const startYear = startDate.format("YYYY");
    const startMonth = startDate.format("MM");
    const startDay = startDate.format("DD");
    const endYear = endDate.format("YYYY");
    const endMonth = endDate.format("MM");
    const endDay = endDate.format("DD");

    const requestAnnualSubData = await userPermAxios.get(
      `/hr/get_annual_sub_data?startYear=${startYear}&startMonth=${startMonth}&startDay=${startDay}&endYear=${endYear}&endMonth=${endMonth}&endDay=${endDay}&target_user_id=${target_user_id}`
    );

    const { success, data } = requestAnnualSubData;

    if (success) {
      result = { ...data };
    }
  } catch (err) {
  } finally {
    return result;
  }
};

const calculateWorkingHours = (workStart, workEnd, breakStart, breakEnd) => {
  const workStartTime = dayjs(workStart, "HH:mm");
  const workEndTime = dayjs(workEnd, "HH:mm");
  const breakStartTime = dayjs(breakStart, "HH:mm");
  const breakEndTime = dayjs(breakEnd, "HH:mm");

  let totalWorkTime = workEndTime.diff(workStartTime, "hours", true);

  const effectiveBreakStart = breakStartTime.isBefore(workStartTime)
    ? workStartTime
    : breakStartTime;
  const effectiveBreakEnd = breakEndTime.isAfter(workEndTime)
    ? workEndTime
    : breakEndTime;

  if (
    effectiveBreakStart.isBefore(workEndTime) &&
    effectiveBreakEnd.isAfter(workStartTime)
  ) {
    const overlap = effectiveBreakEnd.diff(effectiveBreakStart, "hours", true);
    totalWorkTime -= overlap;
  }

  const hours = Math.floor(totalWorkTime);
  const minutes = Math.round((totalWorkTime - hours) * 60);

  const time = dayjs().hour(hours).minute(minutes);

  return time.format("HH:mm");
};

const changeDayjsFormat = (value) => {
  return dayjs(value, "HH:mm").format("HH:mm");
};
