import React, { useEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const StackColumnChart = ({
  chart_id = "dashboard_stack_column_chart",
  width = "100%",
  height = "100%",
  category_field = "date",
  chart_data = sampleData,
  column_list = sampleData2,
  unit = "",
  start_visible_length = 4,
  chart_stroke_color = "#666",
  info_text_color = "#666",
  column_text_color = "#666",
  use_slide_bar = false,
}) => {
  useEffect(() => {
    let root = am5.Root.new(chart_id);

    root.setThemes([am5themes_Animated.new(root)]);

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "panX",
        wheelY: "zoomX",
        layout: root.verticalLayout,
        maxTooltipDistance: -1,
      })
    );

    chart.zoomOutButton.set("forceHidden", true);

    let tooltip = am5.Tooltip.new(root, {});

    chart.plotContainer.set("tooltipPosition", "pointer");
    chart.plotContainer.set("tooltipText", "a");
    chart.plotContainer.set("tooltip", tooltip);

    tooltip.label.adapters.add("text", function (text, target) {
      text = "";
      let i = 0;
      chart.series.each(function (series) {
        let tooltipDataItem = series.get("tooltipDataItem");

        if (tooltipDataItem) {
          if (i === 0) {
            text += "[bold]" + tooltipDataItem.get("categoryX") + "\n";
          }

          if (i !== 0) {
            text += "\n";
          }

          if (series.get("name") !== "") {
            text +=
              "[" +
              series.get("fill") +
              "]●[/] [width:100px]" +
              series.get("name") +
              ":[/] " +
              Number(tooltipDataItem.get("valueY")).toLocaleString() +
              ` ${unit}`;
          } else {
            console.log(tooltipDataItem.get("valueY"));
            text +=
              "[" +
              series.get("fill") +
              "]●[/] [bold width:100px]" +
              "Total" +
              ":[/] " +
              Number(tooltipDataItem.get("valueYTotal")).toLocaleString() +
              ` ${unit}`;
          }
        }
        i++;
      });
      return text;
    });

    tooltip.get("background").setAll({
      stroke: am5.color(0x000000),
      strokeOpacity: 0.8,
      fill: am5.color(0xffffff),
      fillOpacity: 0.8,
    });

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    let cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "zoomX",
      })
    );

    cursor.lineX.setAll({
      stroke: chart_stroke_color,
      strokeWidth: 1,
      strokeDasharray: [5, 5],
    });

    cursor.lineY.setAll({
      stroke: chart_stroke_color,
      strokeWidth: 1,
      strokeDasharray: [5, 5],
    });

    // cursor.lineY.set("visible", false);

    let legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50,
        height: am5.percent(15),
        verticalScrollbar: am5.Scrollbar.new(root, {}),
      })
    );

    legend.markers.template.setAll({
      width: 10,
      height: 10,
    });

    legend.labels.template.setAll({
      fontSize: "14px",
      fontWeight: "400",
      fill: info_text_color,
    });

    let xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 100,
      cellStartLocation: 0.1,
      cellEndLocation: 0.9,
      strokeOpacity: 1,
      strokeWidth: 1,
      stroke: chart_stroke_color,
    });

    xRenderer.grid.template.setAll({
      location: 1,
    });

    xRenderer.labels.template.setAll({
      paddingTop: 10,
      fontSize: "14px",
      // scale: 0.8,
      fill: info_text_color,
    });

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: category_field,
        renderer: xRenderer,
      })
    );

    let yRenderer = am5xy.AxisRendererY.new(root, {
      strokeOpacity: 1,
      strokeWidth: 1,
      stroke: chart_stroke_color,
    });

    xAxis.data.setAll(chart_data);

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: yRenderer,
        calculateTotals: true,
        extraMax: 0.1,
        visible: true,
      })
    );

    yRenderer.grid.template.setAll({
      strokeOpacity: 0,
    });

    yRenderer.labels.template.setAll({
      paddingTop: 10,
      scale: 0.7,
      fill: info_text_color,
    });

    function makeSeries(name, fieldName, color, showTotal) {
      let series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: fieldName,
          valueYGrouped: "sum",
          categoryXField: category_field,
          stacked: true,
          maskBullets: false,
          fill: color,
          legendLabelText: name + " ({valueY.formatNumber('#,###.##')})",
          legendRangeLabelText: name,
        })
      );

      series.bullets.push(function () {
        let label = am5.Label.new(root, {
          text: "{valueY.formatNumber('#,###')}",
          fontSize: "14px",
          fontFamily: "Inter",
          fill: column_text_color,
          centerY: am5.p50,
          centerX: am5.p50,
          populateText: true,
          textAlign: "center",
          oversizedBehavior: "hide",
        });

        return am5.Bullet.new(root, {
          locationX: 0.5,
          locationY: 0.5,
          sprite: label,
        });
      });

      if (showTotal) {
        series.bullets.push(() => {
          const label = am5.Label.new(root, {
            text: "{valueYTotal.formatNumber('#,###')}",
            fontSize: "14px",
            fill: "#666",
            fontFamily: "Inter",
            centerY: am5.p100,
            centerX: am5.p50,
            populateText: true,
            oversizedBehavior: "wrap",
            textAlign: "center",
          });

          label.adapters.add("text", function (text, target) {
            if (target.dataItem && target.dataItem.get("valueYTotal") === 0) {
              return "";
            }

            return text;
          });

          return am5.Bullet.new(root, {
            locationY: 1,
            sprite: label,
          });
        });
      }

      series.data.setAll(chart_data);
      series.appear();

      if (start_visible_length) {
        series.events.once("datavalidated", function (ev, target) {
          xAxis.zoomToIndexes(
            chart_data.length - start_visible_length,
            chart_data.length
          );
        });
      }

      if (!showTotal) {
        legend.data.push(series);
      }
    }

    column_list.forEach((com) => {
      const { name, field, color } = com;

      makeSeries(name, field, color);
    });

    makeSeries("", "none", null, true);

    if (use_slide_bar) {
      let scrollbarX = am5.Scrollbar.new(root, {
        orientation: "horizontal",
      });

      chart.set("scrollbarX", scrollbarX);
    }

    chart.appear(1000, 100);

    return () => {
      root && root.dispose();
    };
  }, [
    chart_id,
    chart_data,
    column_list,
    category_field,
    unit,
    start_visible_length,
    chart_stroke_color,
    info_text_color,
    column_text_color,
    use_slide_bar,
  ]);

  if (!chart_id) {
    return null;
  }

  return <div id={chart_id} style={{ width, height }} />;
};

export default StackColumnChart;

const sampleData = [
  {
    date: "04/01",
    P0102: 10,
    P0103: 15,
    P0104: 20,
    P0105: 25,
    none: 0,
  },
  {
    date: "04/02",
    P0102: 10,
    P0103: 15,
    P0104: 20,
    P0105: 25,
    none: 0,
  },
  {
    date: "04/03",
    P0102: 10,
    P0103: 15,
    P0104: 20,
    P0105: 25,
    none: 0,
  },
  {
    date: "04/04",
    P0102: 10,
    P0103: 15,
    P0104: 20,
    P0105: 25,
    none: 0,
  },
];

const sampleData2 = [
  {
    name: "1번 프로젝트",
    field: "P0102",
    color: "#939597",
  },
  {
    name: "2번 프로젝트",
    field: "P0103",
    color: "#EDF1FF",
  },
  {
    name: "3번 프로젝트",
    field: "P0104",
    color: "#EFE1CE",
  },
  {
    name: "4번 프로젝트",
    field: "P0105",
    color: "#E0B589",
  },
];
