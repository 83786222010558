import React, { useState, useMemo, useEffect } from "react";
import { useQuery } from "react-query";
import { useRecoilState, useRecoilValue } from "recoil";
import { UserSettingModalStatus } from "@atoms/modalStatus";
import { UserSetting } from "@atoms/userInfo";
import { Modal, IconButton, Button, Tabs, Tab, Box } from "@mui/material";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import AccountSetting from "./AccountSetting";
import LocaleSetting from "./LocaleSetting";
import SecuritySetting from "./SecuritySetting";
import SignatureSetting from "./SignatureSetting";
import useRefreshReactQuery from "@/hooks/useRefreshReactQuery";
import { validatePassword, validateEmail } from "@utils/valueRegex";
import { userPermAxios } from "@utils/customAxios";
import { isLoginCheck } from "@utils/isLoginCheck";
import { create_alert_fn } from "@/utils/createAlert";
import "./userSettingModal.scss";

const UserSettingModal = () => {
  const userSetting = useRecoilValue(UserSetting);

  const query_user_initial_signature = useQuery(
    ["user_initial_signature", userSetting?.email ?? ""],
    () => {
      return query_fetch_data();
    }
  );

  const { data = {} } = query_user_initial_signature;

  const { initial_signature = null } = data;

  const [signatureRefresh] = useRefreshReactQuery([
    "user_initial_signature",
    userSetting?.email ?? "",
  ]);

  const [userSettingModalStatus, setUserSettingModalStatus] = useRecoilState(
    UserSettingModalStatus
  );

  const [tabValue, setTabValue] = useState(0);
  const [dubleClick, setDubleClick] = useState(true);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");

  const [language, setLanguage] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const [timeFormat, setTimeFormat] = useState("");

  const [currentPW, setCurrentPW] = useState("");
  const [newPW, setNewPW] = useState("");
  const [repeatNewPW, setRepeatNewPW] = useState("");
  const [checkValue, setCheckValue] = useState(false);

  const [signatureFile, setSigntureFile] = useState(null);

  useEffect(() => {
    setName(userSetting?.name ?? "");
    setEmail(userSetting?.email ?? "");
    setPhone(userSetting?.phone ?? "");
    setCountry(userSetting?.country ?? "");

    setLanguage(userSetting?.language ?? "");
    setTimeZone(userSetting?.timeZoneName ?? "");
    setTimeFormat(userSetting?.timeFormat ?? "");
  }, []);

  useEffect(() => {
    setName(userSetting?.name ?? "");
    setEmail(userSetting?.email ?? "");
    setPhone(userSetting?.phone ?? "");
    setCountry(userSetting?.country ?? "");

    setLanguage(userSetting?.language ?? "");
    setTimeZone(userSetting?.timeZoneName ?? "");
    setTimeFormat(userSetting?.timeFormat ?? "");

    setCurrentPW("");
    setNewPW("");
    setRepeatNewPW("");
    setCheckValue(false);
  }, [tabValue, userSetting]);

  const closeModal = () => {
    setUserSettingModalStatus((prev) => ({ ...prev, open: false }));

    setEmail("");
    setPhone("");
    setCountry("");

    setLanguage("");
    setTimeZone("");
    setTimeFormat("");

    setCurrentPW("");
    setNewPW("");
    setRepeatNewPW("");
    setCheckValue(false);
    setDubleClick(true);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const emailCheck = useMemo(() => {
    if (email.length === 0) {
      return null;
    }

    return validateEmail(email);
  }, [email]);

  const newPWCheck = useMemo(() => {
    if (newPW.length === 0) {
      return null;
    }

    if (currentPW === newPW) {
      return "The new password and the current password are the same.";
    }

    return validatePassword(newPW);
  }, [newPW, currentPW]);

  const repeatPwCheck = useMemo(() => {
    if (repeatNewPW.length === 0) {
      return null;
    }

    if (repeatNewPW === newPW) {
      return null;
    }

    return "The passwords do not match.";
  }, [repeatNewPW, newPW]);

  const button_disabled = useMemo(() => {
    if (tabValue === 0) {
      if (email.length > 0 && emailCheck === null) {
        return false;
      }
    }

    if (tabValue === 1) {
      return false;
    }

    if (tabValue === 2) {
      if (
        currentPW.length > 0 &&
        newPW.length > 0 &&
        repeatNewPW.length > 0 &&
        repeatPwCheck === null
        // &&
        // checkValue
      ) {
        return false;
      }
    }

    if (tabValue === 3) {
      if (signatureFile) {
        return false;
      }
    }

    return true;
  }, [
    tabValue,
    email,
    emailCheck,
    currentPW,
    newPW,
    repeatNewPW,
    repeatPwCheck,
    // checkValue,
    signatureFile,
  ]);

  const updateUserSetting = async () => {
    if (!dubleClick) return;
    setCheckValue(false);

    const alertData = {
      type: "",
      message: "",
      callback_fn: () => {},
    };

    try {
      let request_end_point;

      if (tabValue === 0 || tabValue === 1) {
        request_end_point = `/change-user-setting/${tabMenu[tabValue]}`;
      }

      if (tabValue === 2) {
        request_end_point = "/change-user-pw";
      }

      if (!request_end_point && tabValue < 3) {
        return;
      }

      if (tabValue < 3) {
        const requestUpdateUserSetting = await userPermAxios.post(
          request_end_point,
          {
            email,
            phone,
            country,
            language,
            timeZone,
            timeFormat,
            currentPW,
            newPW,
          }
        );

        const { success, message } = requestUpdateUserSetting;

        if (success) {
          await isLoginCheck();
          alertData.type = "Success";
          alertData.message = message;
        }
      }

      if (tabValue === 3) {
        const formData = new FormData();

        for (const file in signatureFile) {
          if (file !== "length" && file !== "item") {
            const targetFile = signatureFile[file];

            formData.append(`files`, targetFile);
          }
        }

        const requestUpdateUserSignture = await userPermAxios.post(
          `/update-user-signature`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        const { success, message } = requestUpdateUserSignture;

        if (success) {
          alertData.type = "Success";
          alertData.message = message;
          setSigntureFile(null);
          signatureRefresh();
        }
      }
    } catch (err) {
      alertData.type = "warning";
      if (err.response && err?.response?.data?.message) {
        alertData.message = err?.response?.data?.message;
      }
    } finally {
      setCheckValue(true);
      return create_alert_fn(
        alertData.type,
        alertData.message,
        alertData.callback_fn
      );
    }
  };

  return (
    <Modal
      open={userSettingModalStatus.open}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="user-setting-change-modal-body">
        <div className="user-setting-change-modal-header-box">
          <div className="user-setting-change-modal-header-title">Settings</div>
          <IconButton
            onClick={closeModal}
            className="user-setting-change-modal-close-btn"
          >
            <CloseOutlined fontSize="small" />
          </IconButton>
        </div>
        <UserSettingModalHorizontal />
        <div className="user-setting-change-modal-main-box">
          <Box>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="basic tabs example"
            >
              <Tab
                label="Account Setting"
                {...a11yProps(0)}
                sx={{ textTransform: "none" }}
              />
              <Tab
                label="Region Format"
                {...a11yProps(1)}
                sx={{ textTransform: "none" }}
              />
              <Tab
                label="Password"
                {...a11yProps(2)}
                sx={{ textTransform: "none" }}
              />
              <Tab
                label="Signature"
                {...a11yProps(3)}
                sx={{ textTransform: "none" }}
              />
            </Tabs>
          </Box>
          <CustomTabPanel value={tabValue} index={0}>
            <div className="user-setting-change-modal-main-tab-box">
              <AccountSetting
                name={name}
                email={email}
                setEmail={setEmail}
                phone={phone}
                setPhone={setPhone}
                country={country}
                setCountry={setCountry}
                emailCheck={emailCheck}
              />
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={1}>
            <div className="user-setting-change-modal-main-tab-box">
              <LocaleSetting
                country={country}
                setCountry={setCountry}
                language={language}
                setLanguage={setLanguage}
                timeZone={timeZone}
                setTimeZone={setTimeZone}
                timeFormat={timeFormat}
                setTimeFormat={setTimeFormat}
              />
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={2}>
            <div className="user-setting-change-modal-main-tab-box">
              <SecuritySetting
                currentPW={currentPW}
                setCurrentPW={setCurrentPW}
                newPW={newPW}
                setNewPW={setNewPW}
                repeatNewPW={repeatNewPW}
                setRepeatNewPW={setRepeatNewPW}
                checkValue={checkValue}
                setCheckValue={setCheckValue}
                newPWCheck={newPWCheck}
                repeatPwCheck={repeatPwCheck}
              />
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={3}>
            <div className="user-setting-change-modal-main-tab-box">
              <SignatureSetting
                initialSignature={initial_signature}
                signatureFile={signatureFile}
                setSigntureFile={setSigntureFile}
              />
            </div>
          </CustomTabPanel>
        </div>
        <UserSettingModalHorizontal />
        <div className="user-setting-change-modal-btn-box">
          <Button
            role={undefined}
            variant="outlined"
            tabIndex={-1}
            onClick={closeModal}
            className="user-setting-change-modal-cancel-btn"
          >
            Cancel
          </Button>
          <Button
            onClick={updateUserSetting}
            className="user-setting-change-modal-update-btn"
            disabled={button_disabled}
          >
            Update
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default UserSettingModal;

const query_fetch_data = async (startDate, endDate) => {
  let result = { initial_signature: null };

  try {
    const requestInitialSignature = await userPermAxios.get(
      `/get-user-signature`
    );

    const { success, data } = requestInitialSignature;

    if (success) {
      result = { ...data };
    }
  } catch (err) {
  } finally {
    return result;
  }
};

const UserSettingModalHorizontal = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="838"
      height="1"
      viewBox="0 0 840 1"
      fill="none"
    >
      <path d="M0 0H840V1H0V0Z" fill="#E4E7EC" />
    </svg>
  );
};

function a11yProps(index) {
  return {
    id: `user-setting-tab-${index}`,
    "aria-controls": `user-setting-tabpanel-${index}`,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`user-setting-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ height: "100%" }}
    >
      {value === index && children}
    </div>
  );
}

const tabMenu = {
  0: "account",
  1: "locale",
  2: "password",
};
